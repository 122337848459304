import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import CreateInvestee from '../../Shared/CreateAction/CreateInvestee';
import EditInvesteeCompany from '../../Shared/EditAction/EditInvesteeCompany';
import DeleteInvesteeCompany from '../../Shared/DeleteAction/DeleteInvesteeCompany';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import SortingArrows from '../../Shared/SortingArrows';
import { removePathEmptyParams } from '../../../services/helpers';
import { loadUser, loadPortfolios } from '../../../actions/auth';
import line from '../../../images/line.png';

function InvesteeCompanies(props) {
  const { user, portfolios } = props;
  const { loadUser, loadPortfolios } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  const navigate = useNavigate();
  const { t } = useTranslation();
  let itemsPerPage = 25;
  const [newVal, setNewVal] = useState({
    id: '',
    name: '',
    company_email: '',
    description: '',
    development_goals: '',
    investment_amount: '',
    owner: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [investees, setInvestees] = useState([]);
  const [sortParameters, setsortParameters] = useState({
    name: '',
    company_email: '',
    linkedPortofolio: '',
    search: '',
  });

  useEffect(() => {
    fetchInvestees();
  }, []);

  useEffect(() => {
    if (investees) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(investees.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(investees.length / itemsPerPage));
    }
  }, [investees, itemOffset, itemsPerPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(sortParameters).toString();
    API.get(`api/investees?${removePathEmptyParams(queryParams)}`, {
      headers,
      sortParameters,
    }).then((response) => {
      setInvestees(response.data);
    });
  }, [sortParameters]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % investees.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const changeFormInput = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInvestees = () => {
    API.get('api/investees/', { headers })
      .then((response) => {
        setInvestees(response.data);
      })
      .catch(function () {
        console.log('Error while loading report request');
      });
  };

  const submitUpdateInvestee = () => {
    const url = `api/investees/${newVal.id}/`;
    if (
      newVal.name &&
      newVal.company_email &&
      newVal.description.length <= 500 &&
      newVal.development_goals &&
      newVal.owner
    ) {
      API.put(
        url,
        {
          name: newVal.name,
          company_email: newVal.company_email,
          description: newVal.description,
          investment_amount: newVal.investment_amount === '' ? 0 : newVal.investment_amount,
          development_goals: newVal.development_goals,
          owner: newVal.owner,
        },
        { headers },
      )
        .then(() => {
          setShowMessage(true);
          fetchInvestees();
        })
        .catch(function () {
          console.log('Error while updating the company');
        });
    } else {
      setSubmitted(true);
    }
  };

  const submitCreateInvestee = () => {
    const url = 'api/investees/';
    if (
      newVal.name &&
      newVal.company_email &&
      newVal.description.length <= 500 &&
      newVal.development_goals &&
      newVal.owner 
    ) {
      API.post(
        url,
        {
          name: newVal.name,
          company_email: newVal.company_email,
          description: newVal.description,
          investment_amount: newVal.investment_amount === '' ? 0 : newVal.investment_amount,
          enterprise_type: '',
          development_goals: newVal.development_goals,
          owner: newVal.owner,
        },
        { headers },
      )
        .then(() => {
          setIsError(false);
          setShowModal(false);
          fetchInvestees();
        })
        .catch(function (response) {
          setIsError(true);
          setMessage(response.response.data);
        });
    } else {
      setSubmitted(true);
    }
  };

  const deleteInvestee = () => {
    const url = `api/investees/${newVal.id}`;
    API.delete(url, { headers })
      .then(() => {
        fetchInvestees();
      })
      .catch(function () {
        console.log('Error while deleting investees');
      });
  };

  const linkedPortfolio = (owner) => {
    let searchValue = portfolios.find((portfolio) => portfolio.id === owner);
    return searchValue.name;
  };

  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }

    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  const handleSeachInput = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  const displayInvesteeCompanies = (investees) => {
    return (
      <>
        <div className='search-container'>
          <Form.Control
            name='search'
            type='text'
            placeholder={t('search.placeholder')}
            value={sortParameters.search}
            onChange={(e) => {
              handleSeachInput(e);
            }}
          />
          <span className='pl-1'>
            <CreateInvestee
              newVal={newVal}
              isOwnerDefined={false}
              portfolios={portfolios}
              submitted={submitted}
              message={message}
              isError={isError}
              showModal={showModal}
              setNewVal={setNewVal}
              onChange={changeFormInput}
              onSubmit={submitCreateInvestee}
              setIsError={setIsError}
              setShowModal={setShowModal}
              setSubmitted={setSubmitted}
            />
          </span>
        </div>
        <table className='table table-link'>
          <thead>
            <tr>
              <th>
                {t('shared.enterprises')}
                <SortingArrows
                  sortParameters={sortParameters}
                  name='name'
                  parametarAsc='name'
                  parametarDesc='-name'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th>
                Email
                <SortingArrows
                  sortParameters={sortParameters}
                  name='company_email'
                  parametarAsc='company_email'
                  parametarDesc='-company_email'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th>
                Linked Portfolio
                <SortingArrows
                  sortParameters={sortParameters}
                  name='linkedPortofolio'
                  parametarAsc='asc'
                  parametarDesc='desc'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {investees &&
              investees.map((investee, index) => (
                <tr key={index} className='vertical-content hover main2'>
                  <td
                    onClick={() =>
                      navigate('/superadmin/investee-companies/company-details', { state: { investee: investee } })
                    }
                  >
                    {investee.name}
                  </td>
                  <td
                    className='primary'
                    onClick={() =>
                      navigate('/superadmin/investee-companies/company-details', { state: { investee: investee } })
                    }
                  >
                    {investee.company_email}
                  </td>
                  <td>
                    <div className='d-sm-flex align-items-center justify-content-between'>
                      <span
                        onClick={() =>
                          navigate('/superadmin/investee-companies/company-details', { state: { investee: investee } })
                        }
                      >
                        {linkedPortfolio(investee.owner)}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className='table-action-buttons-edit-delete'>
                      <EditInvesteeCompany
                        newVal={newVal}
                        investee={investee}
                        portfolios={portfolios}
                        submitted={submitted}
                        showMessage={showMessage}
                        setNewVal={setNewVal}
                        onChange={changeFormInput}
                        onSubmit={submitUpdateInvestee}
                        setShowMessage={setShowMessage}
                        setSubmitted={setSubmitted}
                      />
                      <DeleteInvesteeCompany
                        button={false}
                        investee={investee}
                        newVal={newVal}
                        setNewVal={setNewVal}
                        onSubmit={deleteInvestee}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {sortParameters.search !== '' && currentItems?.length === 0 && (
          <div className='alert alert-warning'>{t('errors.noEnterprise')}</div>
        )}
      </>
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!portfolios) {
    loadPortfolios();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    return <Navigate to='/terms-of-agreement' />;
  } else {
    return (
      <div>
        <Header />
        {showMessage && (
          <div className='alert alert-success mt-1 mb-1'>
            {t('shared.enterprise')} {newVal.name} {t('shared.enterprise_updated')}
          </div>
        )}
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row padding-top'>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>{t('shared.enterprises')}</span>
                  </span>
                </div>
              </div>
              <div className='padding-left'>
                {displayInvesteeCompanies(currentItems)}
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
                  {pageCount > 0 ? (
                    <p className='main2'>
                      Page {currentPage} of {pageCount}
                    </p>
                  ) : (
                    ''
                  )}
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel='Previous'
                    renderOnZeroPageCount={null}
                    className='pagination'
                    previousClassName='previous-pagination'
                    nextClassName='next-pagination'
                    activeClassName='active-pagination'
                    disabledClassName='disabled-pagination'
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  portfolios: state.auth.portfolios,
});

export default connect(mapStateToProps, { loadUser, loadPortfolios })(InvesteeCompanies);
