import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import line from '../../../images/line.png';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import SortingArrows from '../../Shared/SortingArrows';
import { loadUser, loadInvestors } from '../../../actions/auth';
import { removePathEmptyParams } from '../../../services/helpers';
import defaultImage from '../../../images/default-image.png';

function InvesteeAccounts(props) {
  const { user, loadUser } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  const [userDetails, setUserDetails] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  let itemsPerPage = 25;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [sortParameters, setsortParameters] = useState({
    name: '',
    search: '',
    status: '',
  });

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    fetchUserDetails();
  }, [user]);

  useEffect(() => {
    if (userDetails) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(userDetails.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(userDetails.length / itemsPerPage));
    }
  }, [userDetails, itemOffset, itemsPerPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(sortParameters).toString();
    API.get(`api/users/investeeMembers?${removePathEmptyParams(queryParams)}`, {
      headers,
      sortParameters,
    }).then((response) => {
      setUserDetails(response.data);
    });
  }, [sortParameters]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userDetails.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const fetchUserDetails = async () => {
    const response = await API.get(`api/users/investeeMembers/`, { headers });
    setUserDetails(response.data);
  };

  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }

    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  const handleSeachAndFilters = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  const displayInvesteeUsers = (users) => {
    return (
      <>
        <div className='search-container'>
          <Form.Select
            className='mr-1'
            name='status'
            onChange={(e) => {
              handleSeachAndFilters(e);
            }}
            defaultValue=''
          >
            <option value=''>{t('shared.sortStatus')}</option>
            <option value='Active'>{t('misc.active')}</option>
            <option value='Pending'>{t('misc.pending')}</option>
            <option value=''>{t('shared.na')}</option>
          </Form.Select>
          <Form.Control
            className='pl-1'
            name='search'
            type='text'
            placeholder={t('search.placeholder')}
            value={sortParameters.search}
            onChange={(e) => handleSeachAndFilters(e)}
          />
        </div>
        <table className='table table-link'>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>
                Name
                <SortingArrows
                  sortParameters={sortParameters}
                  name='name'
                  parametarAsc='name'
                  parametarDesc='-name'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th style={{ width: '40%' }}>Email</th>
              <th style={{ width: '40%' }}>Role</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    navigate('/superadmin/investee-accounts/users', {
                      state: { user: user },
                    })
                  }
                >
                  <td>
                    <span className='nav-profile'>
                      {user.profile_picture ? (
                        <img src={user.profile_picture} className='user-img' />
                      ) : (
                        <img src={defaultImage} className='user-img' />
                      )}
                      <div className='name-block table-name-margin'>
                        <span>{user.first_name.charAt(0).toUpperCase()}</span>
                      </div>
                    </span>
                    <span className='main2'>
                      {user.first_name} {user.last_name}
                    </span>
                  </td>
                  <td className='primary vertical-content'>{user.email}</td>
                  <td className='vertical-content'>{user.role ? user.role : '-'}</td>
                  <td>
                    {user.status === 'Active' ? (
                      <button className='active-button'>Active</button>
                    ) : user.status === 'Pending' ? (
                      <button className='pending-button'>Pending</button>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {sortParameters.search !== '' && currentItems?.length === 0 && (
          <div className='alert alert-warning'>{t('errors.noEnterpriseMember')}</div>
        )}
      </>
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    return <Navigate to='/terms-of-agreement' />;
  } else {
    return (
      <div>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row padding-top'>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>Investee members</span>
                  </span>
                </div>
              </div>
              <div className='padding-left'>
                {displayInvesteeUsers(currentItems)}
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
                  {pageCount > 0 ? (
                    <p className='main2'>
                      Page {currentPage} of {pageCount}
                    </p>
                  ) : (
                    ''
                  )}
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel='Previous'
                    renderOnZeroPageCount={null}
                    className='pagination'
                    previousClassName='previous-pagination'
                    nextClassName='next-pagination'
                    activeClassName='active-pagination'
                    disabledClassName='disabled-pagination'
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser, loadInvestors })(InvesteeAccounts);
