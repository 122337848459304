import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { getDevelopmentGoals } from '../../../services/getDevelopmentGoals';
import { useTranslation } from 'react-i18next';

function EditCompanyInfo(props) {
  const { t } = useTranslation();
  let { investee, newVal, companySize, naics, currency, investeeUsers, submitted } = props;
  let { setSubmitted, setNewVal, setIsEdit, setInvesteeUsers, onChange, onFileChange, onSubmit } = props;
  let options = getDevelopmentGoals();
  let [selectedOptions, setSelectedOptions] = useState([]);
  let members = JSON.parse(localStorage.getItem('members'));

  useEffect(() => {
    let devGoals = investee.development_goals;
    if (devGoals) {
      for (let i of devGoals) {
        setSelectedOptions((selectedOptions) => [...selectedOptions, options[i - 1]]);
      }
    }
    if (members) {
      setInvesteeUsers(members);
      setNewVal({
        ...newVal,
        users: members.map((user) => user.id),
      });
    }
  }, []);

  const handleOption = (options) => {
    setSelectedOptions(options);
    let goalValues = [];
    for (let i of options) {
      goalValues.push(i.value);
    }
    setNewVal({
      ...newVal,
      development_goals: goalValues.toString(),
    });
  };

  const cancelEditForm = () => {
    setIsEdit(false);
    setSubmitted(false);
    setNewVal({
      name: investee.name,
      company_email: investee.company_email,
      description: investee.description,
      company_website: investee.company_website,
      development_goals: investee.development_goals || '',
      company_size: investee.company_size || investee.company_size === 0 ? investee.company_size : '',
      naics_category_1: investee.naics_category_1 || '',
      naics_category_2: investee.naics_category_2 || '',
      fiscal_year_end: investee.fiscal_year_end || '',
      currency: investee.currency || '',
      annual_revenue: investee.annual_revenue || '',
      ebitda: investee.ebitda || '',
      net_income: investee.net_income || '',
      investee_logo: null,
      users: investee.users,
      main_contact: investee.main_contact || '',
    });
  };

  const validateDescription = (desc) => {
    if (desc.length > 500) return true;
    else return false;
  };

  const validateSize = (size) => {
    if (size || size === 0) return false;
    else return true;
  };

  return (
    <>
      <Form.Group>
        <Form.Label className='info-question'>{t('shared.companyName')}</Form.Label>
        <Form.Control
          name='name'
          type='text'
          value={newVal.name}
          onChange={(e) => onChange(e)}
          className='edit-info-form'
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>{t('shared.company_email')}</Form.Label>
        <Form.Control
          name='company_email'
          type='text'
          value={newVal.company_email}
          onChange={(e) => onChange(e)}
          className='edit-info-form'
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>{t('shared.companyWebsite')}</Form.Label>
        <Form.Control
          name='company_website'
          type='text'
          value={newVal.company_website}
          onChange={(e) => onChange(e)}
          className='edit-info-form'
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>{t('shared.describeCompany')}</Form.Label>
        <br />
        <textarea
          name='description'
          type='text'
          as='textarea'
          rows={5}
          value={newVal.description}
          onChange={(e) => onChange(e)}
          className='col-md-6'
        />
        {submitted && validateDescription(newVal.description) ? (
          <label className='text-danger ml-1 col-md-5'>{t('shared.descriptionMaxLength')}</label>
        ) : (
          ''
        )}
      </Form.Group>
      <Form.Group className='company-multiselect-style'>
        <Form.Label className='info-question mt-1pt375'>{t('shared.developmentGoals')}</Form.Label>
        <Select isMulti options={options} value={selectedOptions} onChange={(e) => handleOption(e)} />
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>
          {t('shared.company_size')} <span className='text-danger'>*</span>
        </Form.Label>
        <br />
        <select name='company_size' className='col-md-6' value={newVal.company_size} onChange={(e) => onChange(e)}>
          <option value=''>{t('shared.choose_option')}</option>
          {Object.values(companySize).map((cur, index) => (
            <option key={index} value={Object.keys(companySize)[index]}>
              {cur}
            </option>
          ))}
        </select>
        {submitted && validateSize(newVal.company_size) ? (
          <label className='text-danger ml-1 col-md-3'>{t('shared.companySizeRequired')}</label>
        ) : (
          ''
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>
          {t('shared.primaryActivity')} <span className='text-danger'>*</span>
        </Form.Label>
        <br />
        <select
          name='naics_category_1'
          className='col-md-6'
          value={newVal.naics_category_1}
          onChange={(e) => onChange(e)}
        >
          <option value=''>{t('shared.choose_option')}</option>
          {Object.values(naics).map((cur, index) => (
            <option key={index} value={Object.keys(naics)[index]}>
              {cur}
            </option>
          ))}
        </select>
        {submitted && !newVal.naics_category_1 ? (
          <label className='text-danger ml-1 col-md-3'>{t('shared.primaryActivityRequired')}</label>
        ) : (
          ''
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>
          {t('shared.secondaryActivity')}
          <span className='text-danger'>*</span>
        </Form.Label>
        <br />
        <select
          name='naics_category_2'
          className='col-md-6'
          value={newVal.naics_category_2}
          onChange={(e) => onChange(e)}
        >
          <option value=''>{t('shared.choose_option')}</option>
          {Object.values(naics).map((cur, index) => (
            <option key={index} value={Object.keys(naics)[index]}>
              {cur}
            </option>
          ))}
        </select>
        {submitted && !newVal.naics_category_2 ? (
          <label className='text-danger ml-1 col-md-3'>{t('shared.secondaryActivityRequired')}</label>
        ) : (
          ''
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>{t('shared.fiscalYearEnd')}</Form.Label>
        <br />
        <input
          name='fiscal_year_end'
          type='date'
          value={newVal.fiscal_year_end}
          onChange={(e) => onChange(e)}
          className='col-md-2'
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>
          {t('shared.mainCurrency')} <span className='text-danger'>*</span>
        </Form.Label>
        <br />
        <select name='currency' className='col-md-6' value={newVal.currency} onChange={(e) => onChange(e)}>
          <option value=''>{t('shared.choose_option')}</option>
          {Object.values(currency).map((cur, index) => (
            <option key={index} value={Object.keys(currency)[index]}>
              {cur}
            </option>
          ))}
        </select>
        {submitted && !newVal.currency ? (
          <label className='text-danger ml-1 col-md-3'>{t('shared.currencyRequired')}</label>
        ) : (
          ''
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>{t('shared.financialSituation')}</Form.Label>
        <br />
        <label className='col-md-3 mb-1'>
          {t('shared.annualRevenue')} <span className='text-danger'>*</span>
        </label>
        <input
          name='annual_revenue'
          type='number'
          value={newVal.annual_revenue}
          onChange={(e) => onChange(e)}
          className='col-md-4'
          required
        />
        {submitted && !newVal.annual_revenue ? (
          <label className='text-danger ml-1 col-md-3'>{t('shared.annualRevenueRequired')}</label>
        ) : (
          ''
        )}
        <br />
        <label className='col-md-3 mb-1'>
          {t('shared.ebitda')} <span className='text-danger'>*</span>
        </label>
        <input
          name='ebitda'
          type='number'
          value={newVal.ebitda}
          onChange={(e) => onChange(e)}
          className='col-md-4'
          required
        />
        {submitted && !newVal.ebitda ? (
          <label className='text-danger ml-1 col-md-3'>{t('shared.ebitdaRequired')}</label>
        ) : (
          ''
        )}
        <br />
        <label className='col-md-3 mb-1'>
          {t('shared.netIncome')} <span className='text-danger'>*</span>
        </label>
        <input
          name='net_income'
          type='number'
          value={newVal.net_income}
          onChange={(e) => onChange(e)}
          className='col-md-4'
          required
        />
        {submitted && !newVal.net_income ? (
          <label className='text-danger ml-1 col-md-3'>{t('shared.netIncomeRequired')}</label>
        ) : (
          ''
        )}
        <br />
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>{t('shared.logo')}</Form.Label>
        <br />
        {newVal.investee_logo ? (
          <small className='text-info'>{newVal.investee_logo.name}</small>
        ) : investee.investee_logo ? (
          <small className='text-info'>{investee.investee_logo.split('/media/').pop()}</small>
        ) : (
          ''
        )}
        <Form.Control type='file' onChange={(e) => onFileChange(e)} className='edit-info-form' />
      </Form.Group>
      <Form.Group>
        <Form.Label className='info-question mt-1pt375'>{t('shared.mainContact')}</Form.Label>
        <br />
        <select name='main_contact' className='col-md-6' value={newVal.main_contact} onChange={(e) => onChange(e)}>
          <option value=''>{t('shared.choose_option')}</option>
          {investeeUsers.map((user, index) => (
            <option key={index} value={user.id}>
              {user.first_name} {user.last_name} | {user.email}
            </option>
          ))}
        </select>
      </Form.Group>
      <div className='d-sm-flex align-items-center justify-content-between page-content mt-2 mb-2'>
        <button className='cancel-button' onClick={() => cancelEditForm()}>
          {t('misc.cancel')}
        </button>
        <button className='primary-button' onClick={() => onSubmit()}>
          {t('misc.save')}
        </button>
      </div>
    </>
  );
}

export default EditCompanyInfo;
