import React from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import logo from '../../images/oxia-initiative-logo.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SignUp() {
  const { t } = useTranslation();

  // eslint-disable-next-line no-undef
  document.body.style.backgroundColor = '#F8F8FF';

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col xl={4} lg={4} md={4}>
          <div className='authentication'>
            <img src={logo} className='authentication-logo mx-auto d-block' alt='Oxia Initiative Logo' />
            <Card className='authentication-card'>
              <Card.Body>
                <Card.Title>{t('shared.createAccount')}</Card.Title>
                <Form className='signup'>
                  <Form.Group controlId='formEmail'>
                    <Form.Label>{t('shared.email')}</Form.Label>
                    <Form.Control type='email' placeholder={t('shared.enterYourEmail')}></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='formPassword'>
                    <Form.Label>{t('shared.password')}</Form.Label>
                    <Form.Control type='password' placeholder={t('shared.enterYourPassword')}></Form.Control>
                  </Form.Group>
                  <Button>{t('shared.createAccount')}</Button>
                </Form>
                <div className='text-footer'>
                  {t('shared.haveAnAccount')} <Link to='/login'>{t('shared.login')}</Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUp;
