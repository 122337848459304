import React, { useState } from 'react';
import API from '../../../api';
import Sidebar from '../Shared/Sidebar';
import Header from '../../Shared/Header';
import defaultImage from '../../../images/default-image.png';
import line from '../../../images/line.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadUser } from '../../../actions/auth';
import DisplayEditForm from '../../Shared/UserProfile/DisplayEditForm';
import { Spinner } from 'react-bootstrap';

function EditProfile(props) {
  const location = useLocation();
  const navigate = useNavigate();
  let user = location.state.user;
  let [newVal, setNewVal] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    address_line1: user.address_line1,
    address_line2: user.address_line2,
    city: user.city,
    zip_code: user.zip_code,
    region: user.region,
    country: user.country,
    role: user.role,
    phone_number: user.phone_number,
    password: user.password,
  });
  let [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const headers = { Authorization: 'Token ' + localStorage.token };
    const url = `api/users/${user.id}/`;

    if (newVal.first_name && newVal.last_name && newVal.email && newVal.role) {
      API.put(
        url,
        {
          first_name: newVal.first_name,
          last_name: newVal.last_name,
          email: newVal.email,
          address_line1: newVal.address_line1,
          address_line2: newVal.address_line2,
          city: newVal.city,
          zip_code: newVal.zip_code,
          region: newVal.region,
          country: newVal.country,
          role: newVal.role,
          phone_number: newVal.phone_number,
          password: newVal.password,
          type: user.type,
          username: user.email,
        },
        { headers },
      )
        .then(() => {
          window.location.href = '/superadmin/investee-accounts';
        })
        .catch(function () {
          console.log('Error while updating');
        });
    } else {
      setSubmitted(true);
    }
  };

  if (!props.user) {
    props.loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between page-content padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>
                      {user.first_name} {user.last_name}
                    </span>
                    <br />
                    <button
                      className='investor-profile-back-button padding-left'
                      onClick={() => navigate('/superadmin/investee-accounts')}
                    >
                      <i className='fas fa-fw fa-arrow-left'></i>
                      <span>Back</span>
                    </button>
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='page-content text-center'>
                  <img src={defaultImage} className='investor-profile-img' />
                </div>
              </div>
              <DisplayEditForm newVal={newVal} submitted={submitted} onChange={handleChange} onSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(EditProfile);
