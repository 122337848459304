import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { ReactComponent as EditIcon } from '../../../images/svg-icons/edit.svg';
import { useTranslation } from 'react-i18next';

function Edit(props) {
  const { t } = useTranslation();
  let { newVal, portfolio, investors, submitted, button } = props;
  let { onChange, onFileChange, onSubmit, setShowMessage, setSubmitted, setNewVal } = props;
  const statuses = [
    { name: t('misc.pending'), value: 'Pending' },
    { name: t('misc.active'), value: 'Active' },
    { name: t('misc.closed'), value: 'Closed' },
  ];
  const [showModal, setShowModal] = useState(false);

  const displayModal = () => {
    setShowModal(true);
    setNewVal({
      id: portfolio.id,
      name: portfolio.name,
      description: portfolio.description,
      logo: null,
      status: portfolio.status,
      allowed_investee_limit: portfolio.allowed_investee_limit,
      owner: portfolio.owner,
    });
  };

  const validateDescription = (desc) => {
    if (desc.length > 500) return true;
    else return false;
  };

  const closeModal = () => {
    setShowModal(false);
    setSubmitted(false);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const validateReqFelds = () => {
    return !newVal.name || !newVal.status;
  };

  const onClick = () => {
    if (validateReqFelds()) {
      setSubmitted(true);
      return;
    } else onSubmit();
    setShowMessage(true);
    closeModal();
  };

  return (
    <>
      {button ? (
        <button className='primary-button-one-row mr-point5' onClick={displayModal}>
          <EditIcon className='svg-icon' />
          <span>{t('misc.edit')}</span>
        </button>
      ) : (
        <a onClick={displayModal}>
          <EditIcon className='svg-icon mr-1 main2' />
        </a>
      )}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('shared.editPortfolio')}</Modal.Title>

        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.portfolioName_maj')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='name'
              type='text'
              value={newVal.name}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.name ? (
              <p className='text-danger error-message'>{t('shared.portfolioNameRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.description')}</Form.Label>
            <Form.Control
              name='description'
              as='textarea'
              rows={3}
              value={newVal.description}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && validateDescription(newVal.description) ? (
              <p className='text-danger error-message'>{t('shared.descriptionValidation')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.logo_maj')}</Form.Label>

            {portfolio.logo ? (
              <>
                <br />
                <small className='text-info'>{portfolio.logo.split('/media/')}</small>
              </>
            ) : (
              ''
            )}
            <Form.Control
              type='file'
              id='fileUpload'
              onChange={(e) => onFileChange(e)}
              className='style-bottom'
              label='Custom Text'
            />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.status_maj')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='status' value={newVal.status} onChange={(e) => onChange(e)}>
              <option value=''>{t('shared.choose_option')}</option>
              {statuses.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.name}
                </option>
              ))}
            </Form.Select>
            {submitted && !newVal.status ? (
              <p className='text-danger error-message'>{t('shared.statusRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.owner')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='owner' value={newVal.owner} onChange={(e) => onChange(e)}>
              <option value=''>{t('shared.choose_option')}</option>
              {investors &&
                investors.map((investor, index) => (
                  <option key={index} value={investor.id}>
                    {investor.name}
                  </option>
                ))}
            </Form.Select>
            {submitted && !newVal.owner ? (
              <p className='text-danger error-message'>{t('investorEditCompany.errors.owner')} </p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.allowedEnterpriseLimitLabel')}</Form.Label>
            <Form.Control
              name='allowed_investee_limit'
              type='number'
              value={newVal.allowed_investee_limit}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button className='primary-button cancel-button' onClick={closeModal}>
              {t('misc.cancel')}
            </button>
            <button className='primary-button content-right' onClick={onClick}>
              {t('misc.save')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Edit;
