import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

class CreateAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  createAnswerField() {
    let { answerField, saveAnswerField, addAnswerField } = this.props;

    return (
      <>
        <button className='link-button' onClick={() => addAnswerField()}>
          <i className='fas fa-fw fa-add'></i>
          <span>Add New Field</span>
        </button>
        <br />
        {answerField &&
          answerField.map((item, index) => (
            <div key={index}>
              <div className='text-info fw-bold'>*** Field {index + 1} ***</div>
              <Form.Group className='mb-1'>
                <Form.Label className='bg-warning p-1'>data_type</Form.Label>
                <Form.Select name='dataType' value={item.dataType} onChange={(e) => saveAnswerField(e, index)}>
                  <option>Choose option</option>
                  <option value='Text'>Text</option>
                  <option value='Integer'>Integer</option>
                  <option value='Float'>Float</option>
                  <option value='DDL'>DDL</option>
                  <option value='Boolean'>Boolean</option>
                  <option value='Reference'>Reference</option>
                </Form.Select>
              </Form.Group>
              {item.dataType === 'Text' ? (
                <Form.Group className='mb-1'>
                  <Form.Label className='bg-warning p-1'>text_information</Form.Label>
                  <Form.Select name='textInfo' value={item.textInfo} onChange={(e) => saveAnswerField(e, index)}>
                    <option>Choose option</option>
                    <option value='Short Text'>Short Text</option>
                    <option value='Long Text'>Long Text</option>
                  </Form.Select>
                </Form.Group>
              ) : (
                ''
              )}
              {item.dataType === 'DDL' ? (
                <Form.Group>
                  <Form.Label className='bg-warning p-1'>ddl_information</Form.Label>
                  <Form.Control
                    name='ddlInfo'
                    type='number'
                    value={item.ddlInfo}
                    onChange={(e) => saveAnswerField(e, index)}
                  />
                </Form.Group>
              ) : (
                ''
              )}
              {item.dataType === 'Boolean' ? (
                <Form.Group className='mb-1'>
                  <Form.Label className='bg-warning p-1'>boolean_information</Form.Label>
                  <Form.Select name='booleanInfo' value={item.booleanInfo} onChange={(e) => saveAnswerField(e, index)}>
                    <option>Choose option</option>
                    <option value='Checkbox'>Checkbox</option>
                    <option value='Yes/No'>Yes/No</option>
                  </Form.Select>
                </Form.Group>
              ) : (
                ''
              )}
              {item.dataType === 'Reference' ? (
                <Form.Group className='mb-1'>
                  <Form.Label className='bg-warning p-1'>reference_question_id</Form.Label>
                  <Form.Control
                    name='refQueId'
                    type='text'
                    value={item.refQueId}
                    onChange={(e) => saveAnswerField(e, index)}
                  />
                </Form.Group>
              ) : (
                ''
              )}
              <Form.Group className='mb-1'>
                <Form.Label className='bg-warning p-1'>label_en</Form.Label>
                <Form.Control
                  name='labelEn'
                  type='text'
                  value={item.labelEn}
                  onChange={(e) => saveAnswerField(e, index)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className='bg-warning p-1'>answerfield_human_id</Form.Label>
                <Form.Control
                  name='ansFieldHumanID'
                  type='text'
                  value={item.ansFieldHumanID}
                  onChange={(e) => saveAnswerField(e, index)}
                />
              </Form.Group>
              {item.dataType === 'Integer' || item.dataType === 'Float' ? (
                <>
                  <Form.Group>
                    <Form.Label className='bg-warning p-1'>min_value</Form.Label>
                    <Form.Control
                      name='minValue'
                      type='number'
                      value={item.minValue}
                      onChange={(e) => saveAnswerField(e, index)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className='bg-warning p-1'>max_value</Form.Label>
                    <Form.Control
                      name='maxValue'
                      type='number'
                      value={item.maxValue}
                      onChange={(e) => saveAnswerField(e, index)}
                    />
                  </Form.Group>
                </>
              ) : (
                ''
              )}
              <Form.Group>
                <Form.Label className='bg-warning p-1'>help_text_en</Form.Label>
                <Form.Control
                  name='helpTextEn'
                  type='text'
                  value={item.helpTextEn}
                  onChange={(e) => saveAnswerField(e, index)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className='bg-warning p-1'>hide_question_if_no</Form.Label>
                <Form.Control
                  name='hideQueIfNo'
                  type='text'
                  value={item.hideQueIfNo}
                  onChange={(e) => saveAnswerField(e, index)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className='bg-warning p-1'>hide_tag_if_no</Form.Label>
                <Form.Control
                  name='hideTagIfNo'
                  type='text'
                  value={item.hideTagIfNo}
                  onChange={(e) => saveAnswerField(e, index)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className='bg-warning p-1'>hide_section_if_no</Form.Label>
                <Form.Control
                  name='hideSectionIfNo'
                  type='text'
                  value={item.hideSectionIfNo}
                  onChange={(e) => saveAnswerField(e, index)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className='bg-warning p-1'>comments</Form.Label>
                <Form.Control name='comments' type='text' value={item.comments} onChange={(e) => saveAnswerField(e)} />
              </Form.Group>
            </div>
          ))}
      </>
    );
  }

  render() {
    const { answers, onChange, onSubmit } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <button className='btn btn-info text-white ml-1' onClick={() => this.setState({ showModal: true })}>
          <i className='fas fa-fw fa-plus'></i>
          <span>Create Answer</span>
        </button>
        <Modal className='dashboard-modal' show={showModal} onHide={() => this.setState({ showModal: false })} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Title>Create Answer</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <Form.Label>question_human_id</Form.Label>
              <Form.Control name='queHumanId' type='text' value={answers.queHumanId} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group className='mb-1'>
              <Form.Label>label_is_header</Form.Label>
              <Form.Select name='labelIsHeader' value={answers.labelIsHeader} onChange={(e) => onChange(e)}>
                <option>Choose option</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Form.Select>
            </Form.Group>
            {this.createAnswerField()}
            <Form.Group className='modal-buttons'>
              <button onClick={() => this.setState({ showModal: false })} className='primary-button cancel-button'>
                Cancel
              </button>
              <button onClick={onSubmit} type='submit' className='primary-button content-right'>
                Create
              </button>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CreateAnswer;
