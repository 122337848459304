import React, { useEffect, useState } from 'react';
import API from '../../../api';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AnswerField(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const labelKey = `label_${currentLanguage}`;
  let { ansFieldList, repeatAnswerRows, newAnswerValue, onChange, handleCheckbox, onKeyPress, isQuestionLocked } =
    props;
  let queId = ansFieldList[0] ? ansFieldList[0].question : 100;
  const storedReportId = JSON.parse(localStorage.getItem('reportRequestId'));
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [ddlValues, setDdlValues] = useState({});

  const containsDdl = () => {
    let searchValue = ansFieldList.filter((answer) => answer.data_type === 'DDL');
    if (searchValue.length > 0) {
      return true;
    } else return false;
  };

  const fetchDDLValues = async () => {
    const ddlObject = {};
    let searchValue = ansFieldList.filter((answer) => answer.data_type === 'DDL');
    Object.entries(searchValue).forEach(([key, value]) => {
      key = value['id'];
      ddlObject[key] = '';
    });
    let newAnswerValueRows = {};

    // TODO: The logic is right, but this does not update with the state change.
    Object.entries(newAnswerValue).forEach(([key, value]) => {
      newAnswerValueRows[key] = value['answer_values'];
    });

    let answerValueRows = newAnswerValueRows;

    const response = await API.post(
      `api/get_ddl_per_answer_row/${storedReportId}?question=${queId}`,
      {
        answer_value_rows: answerValueRows,
      },
      { headers },
    );

    setDdlValues(response.data.ddls);
  };

  useEffect(() => {
    if (containsDdl()) {
      fetchDDLValues();
    }
  }, [ansFieldList]);

  const addDDLOptions = (itemId, row) => {
    let options = [];

    if (ddlValues[row] && ddlValues[row][itemId]) {
      options = Object.entries(ddlValues[row][itemId][currentLanguage])
        .map(([key, value]) => ({ key, value }))
        .sort((a, b) => a.value.localeCompare(b.value))
        .map((option) => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ));
    }
    return <>{options}</>;
  };

  const displayMultipleRows = () => {
    return [...Array(repeatAnswerRows)].map((e, i) => (
      <div key={i} className='mt-2'>
        {ansFieldList.map((item, index) => (
          <div className='mt-1 mb-1' key={index}>
            {item.data_type === 'Text' && item.text_information === 'Long Text' ? (
              <div className='row'>
                <label className='mb-1'>{item[labelKey]}</label>
                <textarea
                  disabled={isQuestionLocked}
                  type='text'
                  name={item.id}
                  rows='5'
                  value={newAnswerValue[i]['answer_values'][item.id] || ''}
                  onChange={(e) => onChange(e, i)}
                  className='col-md-12 questionnaire-input-longtext'
                />
              </div>
            ) : item.data_type === 'DDL' ? (
              <div className='row'>
                <label className='col-md-6 questionnaire-label'>{item[labelKey]}</label>
                <select
                  disabled={isQuestionLocked}
                  name={item.id}
                  className='col-md-6 questionnaire-input-select'
                  value={newAnswerValue[i]['answer_values'][item.id]}
                  onChange={(e) => onChange(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                >
                  <option>{t('shared.choose_option')}</option>
                  {addDDLOptions(item.id, i)}
                </select>
              </div>
            ) : item.data_type === 'Boolean' && item.boolean_information === 'Yes/No' ? (
              <div>
                <input
                  disabled={isQuestionLocked}
                  type='radio'
                  name={item.id}
                  value='True'
                  onChange={(e) => onChange(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                  checked={newAnswerValue[i]['answer_values'][item.id] === 'True'}
                />
                <span>{t('misc.yes')}</span>
                <br />
                <input
                  disabled={isQuestionLocked}
                  type='radio'
                  name={item.id}
                  value='False'
                  onChange={(e) => onChange(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                  checked={newAnswerValue[i]['answer_values'][item.id] === 'False'}
                />
                <span>{t('misc.no')}</span>
              </div>
            ) : item.data_type === 'Boolean' && item.boolean_information === 'Checkbox' ? (
              <Form.Group>
                <Form.Check
                  disabled={isQuestionLocked}
                  label={item[labelKey]}
                  type='checkbox'
                  name={item.id}
                  value={item[labelKey]}
                  onChange={(e) => handleCheckbox(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                  checked={newAnswerValue[i]['answer_values'][item.id] === 'True'}
                />
              </Form.Group>
            ) : item.data_type === 'Reference' ? (
              <div className='row'>
                <label className='col-md-6'>{item[labelKey]}</label>
                <input
                  disabled={isQuestionLocked}
                  type='text'
                  name={item.id}
                  value={newAnswerValue[i]['answer_values'][item.id] || ''}
                  onChange={(e) => onChange(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                  className='col-md-6 questionnaire-input-reference'
                />
              </div>
            ) : item.data_type === 'Float' || item.data_type === 'Integer' ? (
              <div className='row'>
                <label className='col-md-6 questionnaire-label'>{item[labelKey]}</label>
                <input
                  disabled={isQuestionLocked}
                  type='number'
                  name={item.id}
                  value={newAnswerValue[i]['answer_values'][item.id] || ''}
                  onChange={(e) => onChange(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                  className='col-md-6 questionnaire-input-float'
                />
              </div>
            ) : item[labelKey] === '' ? (
              <div className='row'>
                <input
                  disabled={isQuestionLocked}
                  type='text'
                  name={item.id}
                  value={newAnswerValue[i]['answer_values'][item.id] || ''}
                  onChange={(e) => onChange(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                  className='col-md-4 ml-1 questionnaire-input-nolabel'
                />
              </div>
            ) : (
              <div className='row'>
                <label className='col-md-6 questionnaire-label'>{item[labelKey]}</label>
                <input
                  disabled={isQuestionLocked}
                  type='text'
                  name={item.id}
                  value={newAnswerValue[i]['answer_values'][item.id] || ''}
                  onChange={(e) => onChange(e, i)}
                  onKeyPress={(e) => onKeyPress(e)}
                  className='col-md-6 questionnaire-input-withlabel'
                />
              </div>
            )}
          </div>
        ))}
      </div>
    ));
  };

  const displayMultipleColumns = () => {
    return (
      <table className='table table-bordered'>
        <thead>
          <tr>
            {ansFieldList.map((item, index) => (
              <th key={index}>{item[labelKey]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(repeatAnswerRows)].map((e, i) => (
            <tr key={i}>
              {ansFieldList.map((item, index) =>
                item.data_type === 'Text' && item.text_information === 'Long Text' ? (
                  <td key={index}>
                    <textarea
                      disabled={isQuestionLocked}
                      type='text'
                      name={item.id}
                      rows='5'
                      value={newAnswerValue[i]['answer_values'][item.id] || ''}
                      onChange={(e) => onChange(e, i)}
                      className='col-md-12'
                    />
                  </td>
                ) : item.data_type === 'DDL' ? (
                  <td key={index} className='ddl-width'>
                    <select
                      disabled={isQuestionLocked}
                      name={item.id}
                      className='col-md-12'
                      value={newAnswerValue[i]['answer_values'][item.id]}
                      onChange={(e) => onChange(e, i)}
                      onKeyPress={(e) => onKeyPress(e)}
                    >
                      <option>{t('shared.choose_option')}</option>
                      {addDDLOptions(item.id, i)}
                    </select>
                  </td>
                ) : item.data_type === 'Boolean' && item.boolean_information === 'Yes/No' ? (
                  <td key={index}>
                    <input
                      disabled={isQuestionLocked}
                      type='radio'
                      name={item.id}
                      value='True'
                      onChange={(e) => onChange(e, i)}
                      onKeyPress={(e) => onKeyPress(e)}
                      checked={newAnswerValue[i]['answer_values'][item.id] === 'True'}
                    />
                    <span>{t('misc.yes')}</span>
                    <br />
                    <input
                      disabled={isQuestionLocked}
                      type='radio'
                      name={item.id}
                      value='False'
                      onChange={(e) => onChange(e, i)}
                      onKeyPress={(e) => onKeyPress(e)}
                      checked={newAnswerValue[i]['answer_values'][item.id] === 'False'}
                    />
                    <span>{t('misc.no')}</span>
                  </td>
                ) : item.data_type === 'Boolean' && item.boolean_information === 'Checkbox' ? (
                  <td key={index}>
                    <Form.Check
                      disabled={isQuestionLocked}
                      label={item[labelKey]}
                      type='checkbox'
                      name={item.id}
                      value={item[labelKey]}
                      onChange={(e) => handleCheckbox(e, i)}
                      onKeyPress={(e) => onKeyPress(e)}
                      checked={newAnswerValue[i]['answer_values'][item.id] === 'True'}
                    />
                  </td>
                ) : item.data_type === 'Reference' ? (
                  <td key={index}>
                    <input
                      disabled={isQuestionLocked}
                      type='text'
                      name={item.id}
                      value={newAnswerValue[i]['answer_values'][item.id] || ''}
                      onChange={(e) => onChange(e, i)}
                      onKeyPress={(e) => onKeyPress(e)}
                      className='col-md-12'
                    />
                  </td>
                ) : item.data_type === 'Float' || item.data_type === 'Integer' ? (
                  <td key={index}>
                    <input
                      disabled={isQuestionLocked}
                      type='number'
                      name={item.id}
                      value={newAnswerValue[i]['answer_values'][item.id] || ''}
                      onChange={(e) => onChange(e, i)}
                      onKeyPress={(e) => onKeyPress(e)}
                      className='col-md-12'
                    />
                  </td>
                ) : (
                  <td key={index}>
                    <input
                      disabled={isQuestionLocked}
                      type='text'
                      name={item.id}
                      value={newAnswerValue[i]['answer_values'][item.id] || ''}
                      onChange={(e) => onChange(e, i)}
                      onKeyPress={(e) => onKeyPress(e)}
                      className='col-md-12'
                    />
                  </td>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const displayAnswerFields = () => {
    return <>{ansFieldList[0].label_is_header === true ? displayMultipleColumns() : displayMultipleRows()}</>;
  };

  return (
    <div className='questionnaire-answerfields-space'>
      {ansFieldList.length > 0 ? displayAnswerFields() : <p className='text-danger'> </p>}
    </div>
  );
}

export default AnswerField;
