import React, { useEffect, useState } from 'react';
import API from '../../../api';
import { connect } from 'react-redux';
import { loadUser } from '../../../actions/auth';
import { Spinner, Row, Col, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function TermsOfAgreementEN(props) {
  let { user } = props;
  let { loadUser } = props;
  let [agreementStatus, setAgreementStatus] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.languages[0];

    if (browserLanguage.startsWith('fr')) {
      navigate('/terms-of-agreement/fr');
    }
    if (!user) {
      loadUser();
      return <Spinner animation='grow' variant='info' className='loader' />;
    } else {
      if (user.status === 'Active') setAgreementStatus(true);
    }
  }, [user]);

  const handleCheckbox = () => {
    setAgreementStatus(!agreementStatus);
  };

  const updateUserStatus = async () => {
    const headers = { Authorization: 'Token ' + localStorage.token };
    const url = `api/users/${user.id}/`;
    await API.put(
      url,
      {
        status: agreementStatus ? 'Active' : 'Pending',
      },
      { headers },
    )
      .then(() => {
        window.location.href = '/';
      })
      .catch(function () {
        console.log('Error while adding the agreement page');
      });
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <div className='main-panel toc-main-panel'>
          <div className='content-wrapper'>
            <Row className='justify-content-center mt-1 padding-top padding-bottom'>
              <Col xl={8} lg={8} md={8}>
                <Card className='toa-card'>
                  <Card.Header>PRIVACY STATEMENT</Card.Header>
                  <Card.Body>
                    <Card.Text>Transparency into our data use:</Card.Text>
                    <ul>
                      <li>What we collect</li>
                      <li>Whom we share it with</li>
                      <li>How we use it</li>
                    </ul>
                    <Card.Text></Card.Text>
                    <Card.Title>1. Introduction</Card.Title>
                    <Card.Text>
                      OXIA Initiative was founded to help drive the financial industry&apos;s transition to a greener
                      economy. We believe that by working together we can achieve a better tomorrow and we cannot
                      achieve this without the complete confidence of our customers. Therefore, trust and integrity are
                      core values of our company. This applies ever more so to our data protection. We believe that
                      everyone has right to privacy, and we protect your personal information as so. We are fully
                      transparent with our collection, storage, and usage of personal information.
                    </Card.Text>
                    <Card.Title>2. Scope of this Privacy Statement and our role</Card.Title>
                    <Card.Text>
                      As an ESG consultant services in the financial investing world, we are providing this Privacy
                      Statement to explain how we collect, use, and share information when you interact with us and our
                      offerings, services, and experiences. It describes our privacy practices when we process.
                    </Card.Text>
                    <ol type='a'>
                      <li>
                        Personal information for the purposes of preparing the report as part of Carboscope preparation
                        services;
                      </li>
                      <li>
                        Personal information for the purposes of providing the benefits of the Carboscope platform,
                        which is collectively, all of Carboscope&apos;s services, sites, experiences and software by
                        Oxia Initiative Inc. and its affiliates and subsidiaries other than Carboscope Preparation
                        Information (the “Carboscope Platform”); and/or
                      </li>
                      <li>Personal information as necessary to manage, run and improve our business.</li>
                    </ol>
                    <Card.Text>
                      Oxia Initiative Inc. determines the purposes and means of the processing of personal information
                      (“Information Controller”). If you have any questions about the processing of your data, please
                      contact us in the section “How to Contact Us” below.
                    </Card.Text>
                    <Card.Text>
                      This Privacy Statement does not apply where Carboscope processes personal information as a service
                      provider on behalf of a customer or entity who acts as the Information Controller, for example,
                      the customers of our Carboscope calculation engine. When we act as a service provider, the privacy
                      statement of the relevant information Controller and our agreements with such business or entity
                      will govern our processing of personal information.
                    </Card.Text>
                    <Card.Text>
                      In certain circumstances, there may be more than one Information Controller processing your
                      information. For example, your investor or a financial partner may also act as an Information
                      Controller. In these situations, we act as an independent Information Controller over our
                      processing activities—meaning that we make determinations over how your personal information will
                      be processed independently from the other Information Controller. The other Information Controller
                      may have their own obligations under applicable information privacy law, and you may need to speak
                      with the other Information Controller directly for questions on how they process your personal
                      information.
                    </Card.Text>
                    <Card.Title>3. Carboscope platform</Card.Title>
                    <Card.Text className='fw-bold'>Carboscope platform and your information</Card.Text>
                    <Card.Text>
                      When we say “platform” we mean that when you choose to share data with us or bring over
                      information from third parties (like a bank or loan provider), we use that data together, not just
                      within the individual offering(s) you are using. This means your data all live together.
                    </Card.Text>
                    <Card.Text>
                      The personal information we use in this centralized way is all the information that Carboscope
                      knows about you. It includes your credentials; your name and contact details; payment information;
                      information about your activities; insights about your finances or your business; the content you
                      place in our Platform; and information we have collected about you from third-party sources.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Organizational Account Information</Card.Text>
                    <Card.Text>
                      Some experiences and services within the Carboscope Platform allow you to interact with an
                      organization (such as your investor). If you are granted access to an organization, the owner of
                      the organization or a designated administrator may control and administer details of your account,
                      for example, by deciding your access rights; making decisions about your personal information; or
                      requiring you to take certain actions. If your access rights are amended by the owner or
                      designated administrator, then you may lose access to the information that is in the control of
                      the organization.
                    </Card.Text>
                    <Card.Text>
                      Your use of the Carboscope Platform as part of an organization&apos;s account may be subject to
                      the organization&apos;s policies, which may be different from this statement. We are not
                      responsible for the privacy or security practices of other organizations, and you should consider
                      both the organization&apos;s policies and whether you are comfortable enabling the organization
                      with access to your information prior to connecting to their services.
                    </Card.Text>
                    <Card.Title>4. Information we collect</Card.Title>
                    <Card.Text className='fw-bold'>Information you provide to us</Card.Text>
                    <Card.Text>
                      The personal information that we receive about you depend on the context of your interactions with
                      Carboscope, how you configure your account and the choices that you make, including your privacy
                      settings. Personal information that you provide may also depend upon what services or experiences
                      you use, your location and applicable law. Learn more about the personal information we collect,
                      how we use it, and your rights.
                    </Card.Text>
                    <ul>
                      <li>
                        <span className='fw-bold'>Registration: </span>We collect information when you create an
                        account, interact with the Carboscope Platform, or activate a subscription. Personal information
                        may include your contact information (such as your name, address, phone number and email),
                        profile photo, logo, billing information (your payment information), usernames and credentials.
                      </li>
                      <li>
                        <span className='fw-bold'>Identification information: </span>We collect information to verify
                        your identity including your name, business number or NEQ, for example to verify your identity
                        in order to help you access to the platform.
                      </li>
                      <li>
                        <span className='fw-bold'>Customer support, product research, training, and feedback: </span>We
                        may collect personal information when you reach out to us for support, give us feedback,
                        participate in optional surveys, product research or training and you choose to share.
                      </li>
                      <li>
                        <span className='fw-bold'>Device information: </span>We may collect information about your
                        device such as Internet Protocol (“IP”) addresses, log information, error messages, device type,
                        and unique device identifiers. For example, we may collect IP addresses from you as part of our
                        sign in and security features.
                      </li>
                      <li>
                        <span className='fw-bold'>Content: </span>We may receive information about your business, your
                        finances, expenses, invoices, financial statements, details of your financial transactions,
                        payroll details, payment details, tax return details, details about your customers or vendors or
                        employees, income, and wage information, and/or investment information.
                      </li>
                      <li>
                        <span className='fw-bold'>Third-party service content: </span>We receive information about you
                        when you sign into a third-party service with your account or when you connect your account to a
                        third-party service. For example, you may choose to connect your account with survey accounts.
                      </li>
                      <li>
                        <span className='fw-bold'>Usage information: </span>We may collect usage information such as the
                        pages you viewed, the features you use, your browser type and details about any links with which
                        you interact.
                      </li>
                      <li>
                        <span className='fw-bold'>Expert advice: </span>The Carboscope Platform provides many
                        opportunities for you to connect with live experts, including, for example, environmental
                        experts. When you interact with these experts, we may receive information about the questions
                        you ask, the details of your accounts and the guidance provided to you.
                      </li>
                      <li>
                        <span className='fw-bold'> Information from cookies and other technologies: </span>Cookies are
                        data packets sent from the webserver of the website to your browser. They are stored on your
                        computer and can be retrieved by the webserver at a later visit. Cookies store information about
                        the online preferences of visitors to the website and enable us to improve the visitor
                        experience. Carboscope and our service providers may use commonly used tools such as cookies,
                        web beacons, pixels, local shared objects, and similar technologies (collectively “cookies”) to
                        collect information about you (“Cookie Information”) so we can provide the experiences you
                        request, recognize your visit, track your interactions, and improve your and other
                        customers&apos; experience. You have control over some of the information we collect from
                        cookies and how we use it.
                      </li>
                    </ul>
                    <Card.Text className='fw-bold'>Information provided by others</Card.Text>
                    <Card.Text>
                      Our platform is designed to help you connect with organizations. As a result of those connections,
                      others may be able to input information about you. For example, your investor may input
                      information about his investment and work activity in order to process their analyses. You may
                      also be able to input or process information about others, for example, if you are an account
                      administrator. If you input information about others into our platform, you must only do so if you
                      have first received the appropriate rights and permission to do so, including by getting advanced
                      written consent, if required by applicable law.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Other information sources</Card.Text>
                    <Card.Text>
                      We may also get information about you from others were permitted by applicable law. We protect and
                      process information obtained from those parties as described in this Privacy Statement, consistent
                      with any additional restrictions imposed by the source of the information. Our sources may vary
                      over time and depend upon how you use the Carboscope Platform. For example, we receive information
                      from:
                    </Card.Text>
                    <ul>
                      <li>
                        <span className='fw-bold'>Supplemental information and identity verification providers: </span>
                        Service providers who help us verify your identity, the specifics of your business and/or
                        supplement the information you have provided and ensure the accuracy of your information. For
                        example, we use third-party service providers to validate your mailing address, phone number or
                        provide additional details about your business. These providers may include, for example, your
                        financial institution, telecommunications provider, or email provider.
                      </li>
                      <li>
                        <span className='fw-bold'>Customer Support providers: </span>Service providers who provide us
                        with information about you or your interaction with the Carboscope Platform for troubleshooting
                        purposes. For example, we may obtain support information or technical issues you have raised
                        with these third parties.
                      </li>
                      <li>
                        <span className='fw-bold'>Other users: </span>As described above, we may get information about
                        you from other users, such as your accountant, bookkeeper, or your investor. We may also collect
                        such information through features like member referral programs.
                      </li>
                      <li>
                        <span className='fw-bold'>Risk management, cybersecurity and anti-fraud providers: </span>We may
                        receive information from service providers who help us assess risks associated with our
                        offerings, including to help combat fraud, illegal activity and to help protect your personal
                        information.
                      </li>
                      <li>
                        <span className='fw-bold'>Content providers: </span>We may receive information from software
                        providers that make user-generated content from their service available to others, such as local
                        business reviews or public social media posts.
                      </li>
                      <li>
                        <span className='fw-bold'>Communication providers & social networks: </span>If you give us
                        permission, we may collect information from email providers, communication providers and social
                        networks.
                      </li>
                      <li>
                        <span className='fw-bold'>Joint offering partners: </span>We may offer co-branded services or
                        experiences or engage in joint-marketing activities with others, including through our
                        conferences or live events.
                      </li>
                      <li>
                        <span className='fw-bold'>Publicly available sources</span>We collect information from publicly
                        available sources, such as open government databases.
                      </li>
                      <li>
                        <span className='fw-bold'>Required information: </span>Some services and experiences in the
                        Carboscope Platform require you to provide information for it to function. If you do not wish to
                        provide the required information, you may not be able to use certain features. Any other
                        particularities about our data collection will follow the Act respecting the protection of
                        personal information in the private sector.
                      </li>
                    </ul>
                    <Card.Title>5. How we use personal information</Card.Title>
                    <Card.Text>We collect and process personal information from you only where:</Card.Text>
                    <ul>
                      <li>We have your consent to do so;</li>
                      <li>
                        We need the personal information to perform a contract with you or provide a service to you;
                      </li>
                      <li>Provide you with the benefits of the Carboscope Platform and operate our business;</li>
                      <li>
                        The processing is in our legitimate business interests in those jurisdictions where legitimate
                        business interest is a legitimate basis for processing; or
                      </li>
                      <li>We need to comply with legal requirements, including applicable laws and regulations.</li>
                    </ul>
                    <Card.Text>
                      Personal information is used to operate our business for the following purposes that are required
                      to originate and maintain our relationship with you, including but not limited to:
                    </Card.Text>
                    <ul>
                      <li>Provide you with the Carboscope Platform and create your account;</li>
                      <li>Improve our products and services;</li>
                      <li>Run and manage our business, including resolving billing and financial disputes;</li>
                      <li>Provide features to you, such as your reports and scores;</li>
                      <li>Connect you with experts and other users;</li>
                      <li>
                        Communicate with you, such as sending you electronic notifications concerning your financial
                        privacy;
                      </li>
                      <li>Advertise and market our services and experiences;</li>
                      <li>
                        Personalize your experience and tailor recommendations and offers presented to you, including
                        through the development of insights about you and your needs;
                      </li>
                      <li>Provide you with support and resolve disputes;</li>
                      <li>Conduct research, including by partnering with academic institutions.</li>
                      <li>Comply with our legal and regulatory requirements;</li>
                      <li>Authenticate your identity;</li>
                      <li>
                        Protect the rights, property, safety or security of the Carboscope Platform, our customers,
                        employees or others and prevent fraudulent or illegal activity;
                      </li>
                      <li>
                        To exercise our rights in the course of judicial, administrative or arbitration proceedings;
                      </li>
                      <li>To enforce, remedy or apply our Terms of Service or other agreements and/or;</li>
                      <li>
                        For other purposes that are compatible with the disclosed purposes if and where this is
                        permitted by applicable law.
                      </li>
                    </ul>
                    <Card.Text className='fw-bold'>Automated Processing</Card.Text>
                    <Card.Text>
                      To provide you with valuable personalized advice, recommendations, and experiences, we may process
                      your personal information using automated and manual (human) methods. Our automated methods
                      include artificial intelligence (AI) and a range of technologies that help our services learn and
                      reason to improve our ability to personalize and enhance your experience in the Carboscope
                      Platform. Any other particularities about our usage of data will follow the Act respecting the
                      protection of personal information in the private sector.
                    </Card.Text>
                    <Card.Title>6. How we share your information</Card.Title>
                    <Card.Text>We may share your information in the following circumstances:</Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>With your consent</span>
                      <br />
                      In accordance with our Data Stewardship Principles, except for as outlined below, we only share
                      your information with third parties when you have directed us to do so.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>For research</span>
                      <br />
                      With appropriate controls, we may share information with third-parties, such as academic
                      institutions, government, and non-profit organizations, for research purposes or to publish
                      academic or policy-related materials.We only share information in a way that would not allow any
                      individual to be identified.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>For joint features, sales, promotions and events</span>
                      <br />
                      We may share your information with third-party companies who are jointly providing features with
                      us.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>With financial services providers</span>
                      <br />
                      We may share personal information with collection agencies, credit bureaus and loan service
                      providers, and payment card association members. We may also share your personal information with
                      other companies, lawyers, credit bureaus, agents, government agencies, and card associations in
                      connection with issues related to fraud, credit, defaults, or debt collection.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>When you publicly post the information</span>
                      <br />
                      We may provide opportunities for you to publicly post reviews, questions, comments, suggestions,
                      or other content, which may include personal information, such as your name or username. Anything
                      you share in a public forum is public, and you should think carefully before you decide to share.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>With service providers or agents</span>
                      <br />
                      We share personal information with our service providers or agents who provide services on our
                      behalf for the purposes described in this Privacy Statement. Service providers or agents are
                      required to implement reasonable privacy and information protection controls to maintain the
                      privacy and security of information provided to them consistent with the privacy practices
                      outlined in this statement. Service providers or agents may include companies that assist us with
                      our advertising, marketing and sales efforts, help us with our technology offerings (such as a
                      hosting, security or anti-fraud providers) and help us run our business.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>For mergers and acquisitions</span>
                      <br />
                      If we are involved with a merger, asset sale, financing, liquidation, bankruptcy, or the
                      acquisition of all or part of our business to another company, we may share your information with
                      that company and its advisors before and after the transaction date.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>No sale of personal information to third parties</span>
                      <br />
                      We do not and will not sell personal information to third parties. We do share personal
                      information with third parties for the business purposes described in this statement.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>
                        With our affiliates and subsidiaries and your right to limit information sharing
                      </span>
                      <br />
                      We may share your information with our affiliates and subsidiaries for everyday business purposes
                      as described in this statement, including for marketing purposes. Certain laws may provide you
                      with the right to limit our information sharing activities in certain circumstances.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Cookies and other tracking technologies</span>
                      <br />
                      You can find information on changing your browser settings to opt out of cookies in your browser
                      settings. In certain countries, you may also be able to make changes to your cookies settings by
                      using our cookie preferences tool. If you disable some or all of the cookies the service, or parts
                      of the service may not work.
                    </Card.Text>
                    <Card.Text>
                      The Carboscope Platform is not currently configured to respond to browsers&apos; “Do Not Track”
                      signals because at this time no formal “Do Not Track” standard has been adopted.
                    </Card.Text>
                    <Card.Text>
                      For legal reasons. We may share your information with third-parties for legal reasons without your
                      consent, and as permitted by law, including:
                    </Card.Text>
                    <ul>
                      <li>
                        When we reasonably believe disclosure is required in order to comply with a subpoena, court
                        order, or other applicable law, regulation or legal process
                      </li>
                      <li>
                        To protect the rights, property, or safety of Carboscope Platform, our customers or others
                      </li>
                      <li>To protect or defend against attacks</li>
                      <li>To enforce, remedy, or apply our Terms of Service or other agreements</li>
                      <li>To prevent fraud, cybersecurity attacks or illegal activity</li>
                      <li>For debt collection</li>
                      <li>
                        With regulatory agencies, including government tax agencies, as necessary to help detect and
                        combat fraud and/or protect our customers, users and/or the Carboscope Platform, or in required
                        institutional risk control programs.
                      </li>
                    </ul>
                    Any other particularities about our sharing of data will follow the Act respecting the protection of
                    personal information in the private sector.
                    <Card.Title>7. Your information rights and choices</Card.Title>
                    <Card.Text className='fw-bold'>Your rights</Card.Text>
                    <Card.Text>
                      At Oxia Initiative inc., we believe that you have rights to information that pertains to you
                      and/or your business. If another person has input or processed information in the Carboscope
                      Platform on behalf of you or your business (and we are processing such information as an
                      Information Controller), you may ask to receive a copy of your information, even if you do not
                      have an account with us.
                    </Card.Text>
                    <Card.Text>
                      Depending on where you live, you may have certain state- or nation-specific rights with respect to
                      your personal information that we collect and process.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Verification</Card.Text>
                    <Card.Text>
                      To help protect privacy and the security of your information, you may be asked to provide
                      additional information to verify your identity and/or ownership rights prior to us exercising your
                      data rights. If we are unable to verify your identity or ownership rights to the data, we may not
                      be able to provide you with data rights until you are able to provide us with proper documents.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Information Retention</Card.Text>
                    <Card.Text>
                      Unless you specifically ask us to delete your personal information, we retain your personal
                      information as long as it is necessary to comply with our data retention requirements and provide
                      you with services and the benefits of the Carboscope Platform. However, even if you request a
                      deletion, we may be required to maintain your information for as long as necessary to:
                    </Card.Text>
                    <ul>
                      <li>
                        Comply with our legal or regulatory compliance needs (e.g. maintaining records of transactions
                        you have made with us);
                      </li>
                      <li>To exercise, establish or defend legal claims; and/or</li>
                      <li>To protect against fraudulent or abusive activity on our service.</li>
                    </ul>
                    <Card.Text>
                      This means we may keep different information for different periods. If your account is canceled
                      because you have not used it in a long time, we may delete this information straight away.
                    </Card.Text>
                    <Card.Text>
                      There may be occasions where we are unable to fully delete, anonymize, or de-identify your
                      information due to technical, legal, regulatory compliance or other operational reasons. Where
                      this is the case, we will take reasonable measures to securely isolate your personal information
                      from any further processing until such time as we are able to delete, anonymize, or de-identify
                      it.
                    </Card.Text>
                    <Card.Text className='fw-bold'>International data transfers</Card.Text>
                    <Card.Text>
                      Unless you have provided us with consent to transfer your information, preparation information
                      shall be processed and stored in the United States in accordance with applicable law.
                    </Card.Text>
                    <Card.Text>
                      With the exceptions noted above, you agree and Carboscope reserves the right to store and process
                      your personal information in the United States and in any other country where Carboscope or its
                      affiliates, subsidiaries, or service providers operate facilities in accordance with and as
                      permitted by applicable laws and regulations. Some of these countries may have data protection
                      laws that are different from the laws of your country (and, in some cases, may not be as
                      protective).
                    </Card.Text>
                    <Card.Text>
                      When we transfer, store or process personal information outside of your jurisdiction, we take
                      appropriate safeguards to require that your personal information remain protected in accordance
                      with this Privacy Statement and applicable law. We may use contracts or the European Commission
                      approved Standard Contractual Clauses to help ensure your information is protected at a comparable
                      level.
                    </Card.Text>
                    <Card.Text>
                      Carboscope complies with applicable law regarding the collection, use, and retention of personal
                      information transferred from the European Union (EU), the United States and Canada.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Security of your personal information</Card.Text>
                    <Card.Text>
                      We use physical, technical and organizational safeguards designed to protect your information.
                      However, despite these controls, we cannot completely ensure or warrant the security of your
                      information. You can find out more about how we protect your information and the steps you can
                      take to help protect your information here.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Changes to Our Privacy Statements</Card.Text>
                    <Card.Text>
                      From time to time, we may change or update our Privacy Statement. We reserve the right to make
                      changes or updates at any time. If we make material changes to the way we process your personal
                      information, we will provide notice through our platform or by other means consistent with
                      applicable law.
                    </Card.Text>
                    <Card.Text>
                      You can see when this Privacy Statement was last updated by checking the “last updated” date
                      displayed at the top of this Privacy Statement. Please review this Privacy Statement periodically
                      to stay informed about how Carboscope protects your privacy. Any other particularities about your
                      right and choices will follow the Act respecting the protection of personal information in the
                      private sector.
                    </Card.Text>
                    <Card.Title>TERMS AND CONDITIONS OF USE</Card.Title>
                    <Card.Text>
                      These terms and conditions govern the use of the CARBOSCOPE platform, including software, data,
                      information, reports, and other services made available on, from or through CARBOSCOPE and its
                      respective subdomains (and any related components, features or downloads) This platform is owned
                      and operated by Oxia-Initiative Inc. By using this platform, you indicate that you have read and
                      understood the Terms of Use and agree to always abide by them.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Intellectual property</Card.Text>
                    <Card.Text>
                      All content published and made available on this platform is the property of Oxia Initiative inc.
                      and its creators. This includes, but is not limited to, images, texts, logos, documents,
                      downloadable files, and everything that contributes to the composition of this platform.
                    </Card.Text>
                    <Card.Text>
                      The minimum age to use this platform is 18 years old. Users agree that by using our platform, they
                      are over 18 years old. We assume no legal responsibility for misrepresentation of age.
                    </Card.Text>
                    <Card.Text>
                      As a user, you agree to use our platform legally and not to use this platform for any unlawful
                      purpose, namely:
                    </Card.Text>
                    <ul>
                      <li>Harass or abuse other users of the platform;</li>
                      <li>Violate the rights of other users of the platform;</li>
                      <li>
                        Violate the intellectual property rights of the platform owners or any third party to the
                        platform;
                      </li>
                      <li>Hack into the account of another user of the platform;</li>
                      <li>Act in any way that could be considered fraudulent;</li>
                      <li>Participate in any illegal activity on the platform;</li>
                      <li>Post any material that may be deemed inappropriate or offensive;</li>
                      <li>Reproduce, copy, modify, adapt, translate, create derivative works;</li>
                      <li>
                        Remove, alter, or obscure any proprietary notices, restrictive or otherwise, affixed to the
                        platform or any other product made available to you on the platform.
                      </li>
                    </ul>
                    <Card.Text>
                      As long as you agree to these terms and conditions, and only as long as Oxia-Initiative Inc.
                      authorizes you to use the CARBOSCOPE platform, you may use or view any portion of the platform to
                      which we grant you access under these terms and conditions of use for viewing purposes only. You
                      agree that you will not publicly display (in whole or in part) the CARBOSCOPE platform, including
                      any content, services, reports or other products and materials made available through or in
                      connection with the Platform. Subject to your agreement with the terms and conditions herein,
                      Oxia-Initiative Inc. hereby grants you a limited, non-exclusive, revocable, non-transferable,
                      non-sublicensable and non-assignable license to reproduce, use and distribute the Reports solely
                      for the purpose of using them in your internal valuation, investment, and management activities.
                    </Card.Text>
                    <Card.Text>
                      You are responsible for obtaining and paying for all hardware, telecommunications, and other
                      services necessary to use the platform.
                    </Card.Text>
                    <Card.Text className='fw-bold'>User contributions</Card.Text>
                    <Card.Text>Users can publish the following information on our platform:</Card.Text>
                    <ul>
                      <li>Answers to questionnaires given by Oxia-Initiative Inc.;</li>
                      <li>Information for the creation of an account.</li>
                    </ul>
                    <Card.Text>
                      By posting publicly on our platform, you agree not to act illegally or violate the acceptable use
                      conditions listed in this document.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Information</Card.Text>
                    <Card.Text>
                      By agreeing to these terms and conditions of use, you allow Oxia-Initiative Inc. to use any
                      information you share on the CARBOSCOPE platform to the legal extent permitted by our privacy
                      statement.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Accounts</Card.Text>
                    <Card.Text>
                      When you create an account on our platform, you agree to the following: 1. That you are solely
                      responsible for your account and the security and confidentiality of your account, including any
                      passwords or sensitive information attached to that account. 2. That all personal information you
                      provide to us through your account is current, accurate and truthful and that you will update your
                      personal information if it changes.
                    </Card.Text>
                    <Card.Text>
                      We reserve the right to suspend or terminate your account if you use our platform illegally or
                      violate the acceptable use policy.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Limitation of liability</Card.Text>
                    <Card.Text>
                      Oxia-Initiative Inc. and its employees will not be held responsible for any problems arising from
                      any improper use of the platform.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Indemnity</Card.Text>
                    <Card.Text>
                      As a user, you hereby indemnify Oxia-Initiative Inc. from and against all liability, costs, causes
                      of action, damages or expenses arising out of your use of this platform or your violation of any
                      of the provisions set forth herein.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Applicable Laws</Card.Text>
                    <Card.Text>
                      This document is subject to the applicable laws of Canada and is intended to comply with its
                      necessary rules and regulations. For EU residents, the GDPR (General Data Protection Regulation)
                      is the applicable law governing this document. In the event of any inconsistency between any
                      provision of this document and the GDPR, the rules of the GDPR shall prevail.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Severability</Card.Text>
                    <Card.Text>
                      If at any time, any of the provisions set forth in this document are found to be inconsistent or
                      invalid under applicable law, such provisions shall be deemed void and shall be removed from this
                      document. All other provisions shall not be affected by such laws and the remainder of this
                      document shall continue to be considered valid.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Changes</Card.Text>
                    <Card.Text>
                      These Terms of Use may be amended from time to time to maintain compliance with the law and to
                      reflect any changes to the way we operate our platform and the way we expect users to behave on
                      our platform. We recommend that our users check these terms and conditions from time to time to
                      ensure that they are aware of any updates. If necessary, we will notify users by email of changes
                      to these terms or post a notice on our platform.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Termination</Card.Text>
                    <Card.Text>
                      These terms and conditions are effective until terminated. Oxia Initiative Inc. may terminate or
                      suspend your use of the CARBOSCOPE platform at any time and without notice, for any reason or no
                      reason at all, including if Oxia-Initiative Inc. believes that you have violated or acted
                      inconsistently with the letter or spirit of these terms and conditions. In the event of
                      termination or suspension, your right to use the platform shall immediately cease and
                      Oxia-Initiative Inc. may, without liability to you or any third party, immediately deactivate or
                      delete your username, password and account and all related materials without any obligation to
                      provide new access to such materials.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Contact information</Card.Text>
                    <Card.Text>
                      If you have questions or comments about this Privacy Statement, our terms of use or our practices,
                      please contact us.
                    </Card.Text>
                    <Card.Text>
                      If you have an unresolved privacy or information use concern or complaint that we have not
                      addressed satisfactorily, please contact us.
                    </Card.Text>
                    <Card.Text>
                      If you have questions or concerns that have not been addressed by this Privacy Statement, you can
                      contact us. To contact us: <span className='primary'>contact@oxia-initiative.com</span>
                    </Card.Text>
                    <Form.Group className='checkbox'>
                      <Form.Check
                        label='I have read and agree to the terms.'
                        type='checkbox'
                        value={agreementStatus}
                        onChange={handleCheckbox}
                        checked={agreementStatus}
                      />
                    </Form.Group>
                    <button className='primary-button' onClick={updateUserStatus}>
                      Save and Continue
                    </button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(TermsOfAgreementEN);
