import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { connect } from 'react-redux';
import { setUserLanguage } from '../actions/auth';

const mapDispatchToProps = (dispatch) => ({
  updateUserLanguage: (language) => dispatch(setUserLanguage(language)),
});

function LanguageSwitcher({ userId, token, updateUserLanguage }) {
  const { i18n } = useTranslation();

  const handleChangeLanguage = async (language) => {
    i18n.changeLanguage(language);

    try {
      await axios.patch(
        `/api/users/${userId}/`,
        { language: language },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      );
      updateUserLanguage(language);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la langue côté serveur', error);
    }
  };

  return (
    <select
      className='form-select'
      value={i18n.language}
      onChange={(event) => handleChangeLanguage(event.target.value)}
    >
      <option value='en'>English</option>
      <option value='fr'>Français</option>
    </select>
  );
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  token: localStorage.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
