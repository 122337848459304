import React from 'react';

// eslint-disable-next-line react/display-name
export const ThreeDots = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className='threedots' />
  </a>
));
