import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import PrivateRoute from './PrivateRoute';
import { Navigate } from 'react-router';
import AppRouter from './AppRouter';

import Login from './components/Shared/UserAuthentication/Login';
import SignUp from './components/Shared/SignUp';
import ResetPassword from './components/Shared/UserAuthentication/ResetPassword';
import ForgotPassword from './components/Shared/UserAuthentication/ForgotPassword';
import QuestionTwo from './components/FormEngine/QuestionTwo';
import TermsOfAgreementEN from './components/Shared/UserActivation/TermsOfAgreementEN';
import TermsOfAgreementFR from './components/Shared/UserActivation/TermsOfAgreementFR';
import UserProfile from './components/Shared/UserProfile/UserProfile';
import EditUser from './components/Shared/UserProfile/EditUser';
import UserDetails from './components/Shared/UserDetails';

import CompanyName from './components/Investor/Dashboard/CompanyName';
import InvestorPortfolio from './components/Investor/Dashboard/Portfolio';
import InvestorKpis from './components/Investor/Dashboard/Kpis';
import InvesteeCompanies from './components/Investor/Dashboard/InvesteeCompanies';
import InvestorProfile from './components/Investor/CompanyName/InvestorProfile';
import EditProfile from './components/Investor/CompanyName/EditProfile';
import PortfolioName from './components/Investor/Portfolio/PortfolioName';
import PortfolioInvesteeDetails from './components/Investor/Portfolio/InvesteeDetails';
import InvesteeUserDetails from './components/Investor/Portfolio/InvesteeMemberProfile';
import InvesteeCompany from './components/Investor/InvesteeCompanies/InvesteeCompany';
import InvesteeMemberProfile from './components/Investor/InvesteeCompanies/InvesteeMemberProfile';

import InvesteeCompanyName from './components/Investee/Dashboard/CompanyName';
import InvesteeKpis from './components/Investee/Dashboard/Kpis';
import InvesteeProfile from './components/Investee/CompanyName/InvesteeProfile';
import EditInvesteeProfile from './components/Investee/CompanyName/EditInvesteeProfile';
import FormIntro from './components/Investee/Questionnaire/FormIntro';
// import ModuleIntro from './components/Investee/Questionnaire/ModuleIntro';
import QuestionContent from './components/Investee/Questionnaire/QuestionContent';
import SubmitQuestionnaire from './components/Investee/Questionnaire/SubmitQuestionnaire';

import SAInvestorsCompanies from './components/SuperAdmin/Dashboard/InvestorsCompanies';
import SAInvestorsAccounts from './components/SuperAdmin/Dashboard/InvestorsAccounts';
import SAInvestorCompanyDetails from './components/SuperAdmin/InvestorsCompanies/CompanyDetails';
import SAInvestorProfile from './components/SuperAdmin/Investors/InvestorProfile';
import SAEditProfile from './components/SuperAdmin/Investors/EditProfile';
import SAInvesteeCompanies from './components/SuperAdmin/Dashboard/InvesteeCompanies';
import SACompanyDetails from './components/SuperAdmin/InvesteeCompanies/CompanyDetails';
import SAInvesteeAccounts from './components/SuperAdmin/Dashboard/InvesteeAccounts';
import SAInvesteeProfile from './components/SuperAdmin/Investees/InvesteeProfile';
import SAInvesteeEditProfile from './components/SuperAdmin/Investees/EditProfile';
import SAPortfolio from './components/SuperAdmin/Dashboard/Portfolio';
import SAPortfolioName from './components/SuperAdmin/Portfolios/PortfolioName';
import SAPortfolioInvesteeDetails from './components/SuperAdmin/Portfolios/CompanyName';
import SAInvestorMemberProfile from './components/SuperAdmin/InvestorsCompanies/InvestorMemberProfile';
import SAInvesteeMemberProfile from './components/SuperAdmin/InvesteeCompanies/InvesteeMemberProfile';
import SAPortfolioMemberProfile from './components/SuperAdmin/Portfolios/PortfolioMemberProfile';

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path='*' element={<Navigate to='/' replace />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/signup' element={<SignUp />}></Route>
        <Route path='/reset-password/confirm' element={<ResetPassword />}></Route>
        <Route path='/forgot-password' element={<ForgotPassword />}></Route>
        <Route path='/question' element={<QuestionTwo />}></Route>
        <Route
          path='/terms-of-agreement/en'
          element={
            <PrivateRoute>
              <TermsOfAgreementEN />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/terms-of-agreement/fr'
          element={
            <PrivateRoute>
              <TermsOfAgreementFR />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <AppRouter />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/user-profile'
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path='/user-profile/edit'
          element={
            <PrivateRoute>
              <EditUser />
            </PrivateRoute>
          }
        />
        <Route
          path='/user-details/:name'
          element={
            <PrivateRoute>
              <UserDetails />
            </PrivateRoute>
          }
        />

        <Route
          path='/investor/company-name'
          element={
            <PrivateRoute>
              <CompanyName />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/company-name/users'
          element={
            <PrivateRoute>
              <InvestorProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/company-name/users/edit-profile'
          element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/portfolios'
          element={
            <PrivateRoute>
              <InvestorPortfolio />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/portfolios/portfolio-details'
          element={
            <PrivateRoute>
              <PortfolioName />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/portfolios/portfolio-details/investee'
          element={
            <PrivateRoute>
              <PortfolioInvesteeDetails />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/portfolios/portfolio-details/investee/member'
          element={
            <PrivateRoute>
              <InvesteeUserDetails />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/kpis'
          element={
            <PrivateRoute>
              <InvestorKpis />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/investee-companies'
          element={
            <PrivateRoute>
              <InvesteeCompanies />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/investee-companies/company-details'
          element={
            <PrivateRoute>
              <InvesteeCompany />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investor/investee-companies/company-details/member'
          element={
            <PrivateRoute>
              <InvesteeMemberProfile />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path='/investee/company-name'
          element={
            <PrivateRoute>
              <InvesteeCompanyName />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investee/company-name/users'
          element={
            <PrivateRoute>
              <InvesteeProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investee/company-name/users/edit-profile'
          element={
            <PrivateRoute>
              <EditInvesteeProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/investee/kpis'
          element={
            <PrivateRoute>
              <InvesteeKpis />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/questionnaire/:name/forms-intro'
          element={
            <PrivateRoute>
              <FormIntro />
            </PrivateRoute>
          }
        ></Route>
        {/* <Route
          path='/questionnaire/:name/form-intro/:name'
          element={
            <PrivateRoute>
              <ModuleIntro />
            </PrivateRoute>
          }
        ></Route> */}
        <Route
          path='/questionnaire/:name/form-intro/:name/:name'
          element={
            <PrivateRoute>
              <QuestionContent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/questionnaire/submit-questionnaire'
          element={
            <PrivateRoute>
              <SubmitQuestionnaire />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path='/superadmin/investor-companies'
          element={
            <PrivateRoute>
              <SAInvestorsCompanies />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investor-companies/company-details'
          element={
            <PrivateRoute>
              <SAInvestorCompanyDetails />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investor-accounts'
          element={
            <PrivateRoute>
              <SAInvestorsAccounts />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investor-accounts/users'
          element={
            <PrivateRoute>
              <SAInvestorProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investor-accounts/users/edit-profile'
          element={
            <PrivateRoute>
              <SAEditProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investee-companies'
          element={
            <PrivateRoute>
              <SAInvesteeCompanies />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investee-companies/company-details'
          element={
            <PrivateRoute>
              <SACompanyDetails />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investee-accounts'
          element={
            <PrivateRoute>
              <SAInvesteeAccounts />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investee-accounts/users'
          element={
            <PrivateRoute>
              <SAInvesteeProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investee-accounts/users/edit-profile'
          element={
            <PrivateRoute>
              <SAInvesteeEditProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/portfolios'
          element={
            <PrivateRoute>
              <SAPortfolio />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/portfolios/portfolio-details'
          element={
            <PrivateRoute>
              <SAPortfolioName />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/portfolios/portfolio-details/investee'
          element={
            <PrivateRoute>
              <SAPortfolioInvesteeDetails />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investor-companies/company-details/member'
          element={
            <PrivateRoute>
              <SAInvestorMemberProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/investee-companies/company-details/member'
          element={
            <PrivateRoute>
              <SAInvesteeMemberProfile />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/superadmin/portfolios/portfolio-details/investee/member'
          element={
            <PrivateRoute>
              <SAPortfolioMemberProfile />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </Provider>
  );
}

export default App;
