import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestorDataSummary } from '../../../actions/auth';
import { Spinner } from 'react-bootstrap';
import Notification from './Notification';
import Report from '../../Shared/ReportAction/Report';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';

function Dashboard(props) {
  const { t, i18n } = useTranslation();
  const current_language = i18n.language;
  let { user, investors, dataSummary } = props;
  let { loadUser, loadInvestors, loadInvestorDataSummary } = props;

  const formatInvestmentAmount = (amount) => {
    if (amount < 1e3) return amount;
    if (amount >= 1e3 && amount < 1e6) return +(amount / 1e3).toFixed(1) + 'K';
    if (amount >= 1e6 && amount < 1e9) return +(amount / 1e6).toFixed(1) + 'M';
    if (amount >= 1e9 && amount < 1e12) return +(amount / 1e9).toFixed(1) + 'B';
    if (amount >= 1e12) return +(amount / 1e12).toFixed(1) + 'T';
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!dataSummary) {
    loadInvestorDataSummary();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    let termsOfAgreementRoute = `/terms-of-agreement/${current_language}`;
    return <Navigate to={termsOfAgreementRoute} />;
  } else {
    localStorage.setItem('investor', JSON.stringify({ investor: investors[0] }));
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investor={props.investors[0]} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row padding-top'>
                <div className='col-12'>
                  <img src={line} className='heading-line' />
                  <span className='header-2 padding-left'>{t('misc.home')}</span>
                </div>
              </div>
              <div className='row mt-1 padding-left'>
                <div className='col-md-3 stretch-card'>
                  <div className='card card-secondary'>
                    <NavLink to='/investor/investee-companies'>
                      <div className='card-body'>
                        {user.type === 'Investor' ? (
                          <>
                            <h3>{dataSummary.investee_companies}</h3>
                            <p>{t('misc.investee')}</p>
                          </>
                        ) : (
                          <>
                            <h3>{dataSummary.investee_companies}</h3>
                            <p>{t('shared.enterprises')}</p>
                          </>
                        )}
                      </div>
                    </NavLink>
                  </div>
                </div>
                <div className='col-md-3 stretch-card'>
                  <div className='card card-primary'>
                    <NavLink to='/investor/portfolios'>
                      <div className='card-body'>
                        <h3>{dataSummary.portfolios}</h3>
                        <p>{t('misc.portfolios')}</p>
                      </div>
                    </NavLink>
                  </div>
                </div>
                <div className='col-md-3 stretch-card'>
                  <div className='card card-secondary'>
                    <div className='card-body'>
                      {user.type === 'Investor' ? (
                        <>
                          <h3>${formatInvestmentAmount(dataSummary.total_investment_amount)}</h3>
                          <p>{t('shared.investmentAmount')}</p>
                        </>
                      ) : (
                        <>
                          <h3>${formatInvestmentAmount(dataSummary.total_investment_amount)}</h3>
                          <p>{t('shared.mandate_amount')}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr className='divider' />
              <div className='row'>
                <Report isAdmin={false} isInvestor={true} />
                <Notification />
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  dataSummary: state.auth.investorDataSummary,
});

export default connect(mapStateToProps, { loadUser, loadInvestors, loadInvestorDataSummary })(Dashboard);
