import React from 'react';
import { Form, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { getDevelopmentGoals } from '../../../services/getDevelopmentGoals';
import { useTranslation } from 'react-i18next';

function CreateInvestee(props) {
  const { t } = useTranslation();
  let { newVal, isOwnerDefined, portfolios, submitted, isError, message, showModal } = props;
  let { onChange, onSubmit, setNewVal, setIsError, setShowModal, setSubmitted } = props;
  let options = getDevelopmentGoals();

  const handleOption = (options) => {
    let goalValues = [];
    for (let i of options) {
      goalValues.push(i.value);
    }
    setNewVal({
      ...newVal,
      development_goals: goalValues.toString(),
    });
  };

  const displayModal = () => {
    setShowModal(true);
    setNewVal({
      name: '',
      company_email: '',
      description: '',
      development_goals: '',
      investment_amount: '',
      enterprise_type: '',
      owner: isOwnerDefined ? portfolios.id : '',
    });
  };

  const validateDescription = (desc) => {
    if (desc.length > 500) return true;
    else return false;
  };

  const closeModal = () => {
    setShowModal(false);
    setIsError(false);
    setSubmitted(false);
  };

  return (
    <>
      <button className='primary-button' onClick={displayModal}>
        <i className='fas fa-fw fa-plus mr-point2'></i>
        <span>{t('investorDashboard.addEnterprise')}</span>
      </button>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('investorDashboard.addEnterprise')}</Modal.Title>
        {isError ? (
          <Alert variant='danger' className='mt-1 ml-1 mr-1 mb-auto'>
            {message}
          </Alert>
        ) : (
          ''
        )}
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.company_name')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control name='name' value={newVal.name} onChange={(e) => onChange(e)} className='style-bottom' />
            {submitted && !newVal.name ? (
              <p className='text-danger error-message'>{t('shared.company_name_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.company_email')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='company_email'
              value={newVal.company_email}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.company_email ? (
              <p className='text-danger error-message'>{t('shared.company_email_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.description')}</Form.Label>
            <Form.Control
              name='description'
              as='textarea'
              rows={3}
              value={newVal.description}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && validateDescription(newVal.description) ? (
              <label className='text-danger'>{t('shared.descriptionValidation')}</label>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.developmentGoals')} <span className='text-danger'>*</span>
            </Form.Label>
            <Select isMulti options={options} onChange={(e) => handleOption(e)} className='development-goal-style' />
            {submitted && !newVal.development_goals ? (
              <p className='text-danger error-message'>{t('shared.development_goal_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.owner')} <span className='text-danger'>*</span>
            </Form.Label>
            {isOwnerDefined ? (
              <Form.Select name='owner' value={newVal.owner} onChange={(e) => onChange(e)}>
                <option value={portfolios.id}>{portfolios.name}</option>
              </Form.Select>
            ) : (
              <Form.Select name='owner' value={newVal.owner} onChange={(e) => onChange(e)}>
                <option>{t('shared.choose_option')}</option>
                {portfolios &&
                  portfolios.map((portfolio, index) => (
                    <option key={index} value={portfolio.id}>
                      {portfolio.name}
                    </option>
                  ))}
              </Form.Select>
            )}
            {submitted && !newVal.owner ? (
              <p className='text-danger error-message'>{t('shared.owner_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.mandate_amount')}</Form.Label>
            <Form.Control
              name='investment_amount'
              type='number'
              value={newVal.investment_amount}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button onClick={onSubmit} className='primary-button content-right'>
              {t('misc.save')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateInvestee;
