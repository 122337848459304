import API from '../api';

import {
  USER_LOADED,
  INVESTORS_LOADED,
  INVESTEES_LOADED,
  PORTFOLIOS_LOADED,
  PRODUCTS_LOADED,
  REPORTING_PERIODS_LOADED,
  REPORT_STATUSES_LOADED,
  FORMS_LOADED,
  MODULES_LOADED,
  SECTIONS_LOADED,
  QUESTIONS_LOADED,
  ANSWER_FIELDS_LOADED,
  INVESTOR_DATA_SUMMARY_LOADED,
  OXIA_ADMIN_DATA_SUMMARY_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAIL,
  SET_LANGUAGE,
} from './types';
import { setAlert } from './alert';

export const loadUser = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/whoami`, { headers });
    dispatch({
      type: USER_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadInvestors = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/investors/`, { headers });
    dispatch({
      type: INVESTORS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadInvestees = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/investees/`, { headers });
    dispatch({
      type: INVESTEES_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadPortfolios = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/portfolios/`, { headers });
    dispatch({
      type: PORTFOLIOS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadProducts = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/products/`, { headers });
    dispatch({
      type: PRODUCTS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadReportingPeriods = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/product_reporting_periods/`, { headers });
    dispatch({
      type: REPORTING_PERIODS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadReportStatuses = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/report_statuses/`, { headers });
    dispatch({
      type: REPORT_STATUSES_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadForms = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/forms/`, { headers });
    dispatch({
      type: FORMS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadModules = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/modules/`, { headers });
    dispatch({
      type: MODULES_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadSections = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/sections/`, { headers });
    dispatch({
      type: SECTIONS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadQuestions = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/questions/`, { headers });
    dispatch({
      type: QUESTIONS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadAnswerFields = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/answer_fields/`, { headers });
    dispatch({
      type: ANSWER_FIELDS_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadInvestorDataSummary = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/investor_data_summary/`, { headers });
    dispatch({
      type: INVESTOR_DATA_SUMMARY_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loadOxiaAdminDataSummary = () => async (dispatch) => {
  try {
    const headers = {
      Authorization: 'Token ' + localStorage.token,
    };
    const response = await API.get(`api/oxia_admin_data_summary/`, { headers });
    dispatch({
      type: OXIA_ADMIN_DATA_SUMMARY_LOADED,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (username, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await API.post(`api/api-token-auth/`, { username: username, password: password }, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    // Load user
    dispatch(loadUser());
  } catch (err) {
    dispatch(setAlert('Authentication failed. Please try again.'));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const confirmChangePassword = (token, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await API.post('/api/password_reset/confirm/', { token: token, password: password }, config);
    dispatch({
      type: PASSWORD_CHANGE_SUCCESS,
      payload: response.data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: PASSWORD_CHANGE_FAIL,
    });

    if (err.message.includes('404')) {
      dispatch(
        setAlert('Your activation link has expired. <a href="/forgot-password" style="color: blue; text-decoration: underline;">Click here</a> to send a new one.'),
      );
      return 404;
    } else {
      dispatch(
        setAlert('This password is too simple or too similar to your email. Please enter a different password.'),
      );
      return 400;
    }
  }
};

export const setUserLanguage = (language) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
