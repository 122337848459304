import React, { useState, useEffect } from 'react';
import API from '../../../api';
import ReactPaginate from 'react-paginate';
import InviteMember from '../CreateAction/InviteMember';
import defaultImage from '../../../images/default-image.png';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortingArrows from '../../Shared/SortingArrows';
import { removePathEmptyParams } from '../../../services/helpers';

function DisplayInvestorUsers(props) {
  let { investor, url } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  const navigate = useNavigate();
  const { t } = useTranslation();

  let itemsPerPage = 10;
  let [currentItems, setCurrentItems] = useState(null);
  let [pageCount, setPageCount] = useState(0);
  let [itemOffset, setItemOffset] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [userDetails, setUserDetails] = useState([]);
  let updatedMemberList = JSON.parse(localStorage.getItem('members'));
  const [sortParameters, setsortParameters] = useState({
    idInvestor: investor.id,
    name: '',
    status: '',
    search: '',
  });

  useEffect(() => {
    if (userDetails) {
      helperFunctionForSetCurrentItems(userDetails);
    }
    localStorage.setItem('members', JSON.stringify(userDetails));
  }, [userDetails, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userDetails.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const fetchUserDetails = async (id, isAdd) => {
    const response = await API.get(`api/users/?idInvestee=${id}`, { headers });
    if (!isAdd) {
      setUserDetails(response.data);
    } else {
      setCurrentItems(response.data);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(sortParameters).toString();
    API.get(`api/users/investorMembersbyId/?${removePathEmptyParams(queryParams)}`, {
      headers,
      sortParameters,
    }).then((response) => {
      setUserDetails(response.data);
    });
  }, [sortParameters]);

  const helperFunctionForSetCurrentItems = (data) => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(userDetails.length / itemsPerPage));
  };

  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }

    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  const handleSeachAndFilters = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (updatedMemberList) {
      setUserDetails(updatedMemberList);
    } else fetchUserDetails(investor.id);
  }, []);

  const displayInvestorUsers = (users, investor) => {
    return (
      <table className='table table-link'>
        <thead>
          <tr>
            <th>
              {t('misc.name')}
              <SortingArrows
                sortParameters={sortParameters}
                name='name'
                parametarAsc='name'
                parametarDesc='-name'
                handleOnClick={changeSortParameters}
              />
            </th>
            <th>{t('misc.email')}</th>
            <th>{t('investorDashboard.role')}</th>
            <th>{t('shared.status')}</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user, index) => (
              <tr key={index} onClick={() => navigate(url, { state: { user: user, investor: investor } })}>
                <td>
                  <span className='nav-profile'>
                    <img src={defaultImage} className='user-img' />
                    <div className='name-block table-name-margin'>
                      <span>{user.first_name.charAt(0).toUpperCase()}</span>
                    </div>
                  </span>
                  <span className='main2'>
                    {user.first_name} {user.last_name}
                  </span>
                </td>
                <td className='primary vertical-content'>{user.email}</td>
                <td className='vertical-content'>{user.role ? user.role : '-'}</td>
                <td>
                  {user.status === 'Active' ? (
                    <button className='active-button'>{t('misc.active')}</button>
                  ) : (
                    <button className='pending-button'>{t('misc.pending')}</button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className='row padding-left padding-top'>
      <div className='d-sm-flex align-items-center justify-content-between page-content'>
        <h3>{t('shared.aggregatorMembers')}</h3>
        <div className='search-container'>
          <Form.Select className='mr-1' name='status' onChange={(e) => handleSeachAndFilters(e)} defaultValue=''>
            <option value=''>{t('shared.sortStatus')}</option>
            <option value='Active'>{t('misc.active')}</option>
            <option value='Pending'>{t('misc.pending')}</option>
            <option value=''>{t('shared.na')}</option>
          </Form.Select>
          <Form.Control
            name='search'
            type='text'
            placeholder={t('search.byNamePlaceholder')}
            value={sortParameters.search}
            onChange={(e) => handleSeachAndFilters(e)}
          />
        </div>
        <InviteMember id={investor.id} fetchUserDetails={fetchUserDetails} />
      </div>
      <div>
        {displayInvestorUsers(currentItems, investor)}
        <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
          {pageCount > 0 ? (
            <p className='main2'>
              {t('shared.pageInfo', {
                currentPage: currentPage,
                pageCount: pageCount,
              })}
            </p>
          ) : (
            ''
          )}
          <ReactPaginate
            breakLabel='...'
            nextLabel={t('shared.nextLabel')}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel={t('shared.previousLabel')}
            renderOnZeroPageCount={null}
            className='pagination'
            previousClassName='previous-pagination'
            nextClassName='next-pagination'
            activeClassName='active-pagination'
            disabledClassName='disabled-pagination'
          />
        </div>
      </div>
    </div>
  );
}

export default DisplayInvestorUsers;
