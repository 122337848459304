import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CreateReportRequest(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const nameKey = `name_${currentLanguage}`;
  let { products, investee, reportRequestValue, reportingPeriods, submitted, isInvestee, forAllInvestees } = props;
  let { setReportRequestValue, onChange, onSubmit } = props;
  let [showModal, setShowModal] = useState(false);
  let [filteredProducts, setFilteredProducts] = useState(null);
  let requestReportForAllInvestees = forAllInvestees ? true : false;

  const displayModal = () => {
    setShowModal(true);
    const productMap = new Map();
    products.forEach((product) => {
      const existingProduct = productMap.get(product.code);
      if (!existingProduct || product.id > existingProduct.id) {
        productMap.set(product.code, product);
      }
    });
    setFilteredProducts(Array.from(productMap.values()));
    setReportRequestValue({
      id: '',
      start_date: '',
      end_date: '',
      product: '',
      owner: investee.id,
      reporting_period: '',
      report_status: 1,
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setReportRequestValue({
      id: '',
      start_date: '',
      end_date: '',
      product: '',
      owner: '',
      reporting_period: '',
      report_status: '',
    });
  };

  const submitCreateRequest = () => {
    onSubmit();
    closeModal();
  };

  const checkInputValidation = (value, message) => {
    if (submitted && !value) return <p className='text-danger error-message'>{t('shared.isRequired', { message })}</p>;
    else return <p className='error-message'>&nbsp;</p>;
  };

  return (
    <>
      <button className='primary-button ml-point5 request-new-report-button' onClick={displayModal}>
        {requestReportForAllInvestees ? t('shared.requestReportFromAll') : t('shared.requestNewReport')}
      </button>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('shared.create_report')}</Modal.Title>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.product').toUpperCase()}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='product' value={reportRequestValue.product} onChange={(e) => onChange(e)}>
              <option value=''>{t('shared.selectProduct')}</option>
              {filteredProducts &&
                filteredProducts.map((product, index) => (
                  <option key={index} value={product.id}>
                    {product[nameKey]}
                  </option>
                ))}
            </Form.Select>
            {checkInputValidation(reportRequestValue.product, t('shared.product'))}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.startDate').toUpperCase()}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='start_date'
              type='date'
              value={reportRequestValue.start_date}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {checkInputValidation(reportRequestValue.start_date, t('shared.startDate').toUpperCase())}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.endDate').toUpperCase()}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='end_date'
              type='date'
              value={reportRequestValue.end_date}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {checkInputValidation(reportRequestValue.end_date, t('shared.endDate').toUpperCase())}
          </Form.Group>
          {isInvestee || investee === '' ? (
            ''
          ) : (
            <Form.Group>
              <Form.Label>
                {t('misc.investee').toUpperCase()}
                <span className='text-danger'>*</span>
              </Form.Label>
              <Form.Select name='owner' value={reportRequestValue.owner} onChange={(e) => onChange(e)}>
                <option value={investee.id}>{investee.name}</option>
              </Form.Select>
              {checkInputValidation(reportRequestValue.owner, 'Owner')}
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>
              {t('shared.reportingPeriod').toUpperCase()}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select
              name='reporting_period'
              value={reportRequestValue.reporting_period}
              onChange={(e) => onChange(e)}
            >
              <option value=''>{t('shared.selectReportingPeriod')}</option>
              {reportingPeriods &&
                reportingPeriods
                  .filter((period) => period.name_en.length === 4)
                  .map((period, index) => (
                    <option key={index} value={period.id}>
                      {period[nameKey]}
                    </option>
                  ))}
            </Form.Select>
            {checkInputValidation(reportRequestValue.reporting_period, t('shared.reportingPeriod'))}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.reportStatus').toUpperCase()}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='report_status' value={reportRequestValue.report_status} onChange={(e) => onChange(e)}>
              <option value='1'>{t('shared.ongoing')}</option>
            </Form.Select>
            {checkInputValidation(reportRequestValue.report_status, t('shared.reportStatus').toUpperCase())}
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button onClick={submitCreateRequest} className='primary-button content-right'>
              {t('shared.create')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateReportRequest;
