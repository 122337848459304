import React, { useEffect, useState } from 'react';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from './Sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadUser, loadForms, loadModules } from '../../../actions/auth';
import { Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function FormIntro(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const toolkitKey = `toolkit_path_${currentLanguage}`;
  const embeddedVideoKey = `embedded_video_url_${currentLanguage}`;
  const introKey = `intro_text_${currentLanguage}`;
  const titleKey = `title_${currentLanguage}`;
  let { user, forms, modules } = props;
  let { loadUser, loadForms, loadModules } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const storedReportId = JSON.parse(localStorage.getItem('reportRequestId'));
  const storedProductId = JSON.parse(localStorage.getItem('reportProductId'));
  let reportRequestId = location.state == null ? storedReportId : location.state.reportRequestId;
  let reportProductId = location.state == null ? storedProductId : location.state.product;
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [questionsInfo, setQuestionsInfo] = useState([]);

  const fetchQuestionsInfo = async () => {
    const response = await API.get(`api/get_questions_info/${reportRequestId}`, { headers });
    let dataArray = Object.keys(response.data).map(function (obj) {
      return response.data[obj];
    });
    setQuestionsInfo(dataArray);
  };

  useEffect(() => {
    fetchQuestionsInfo();
  }, []);

  const findForm = () => {
    let searchValue = forms.find((form) => form.product === reportProductId);
    localStorage.setItem('formId', searchValue.id);
    return searchValue;
  };

  const findModules = () => {
    let firstModule = {};
    let searchValue = modules.filter((module) => module.form === findForm().id);
    if (searchValue.length > 0) firstModule = searchValue[0];
    return firstModule;
  };

  const openFirstModuleQuestion = async (moduleId) => {
    let response = await API.get(`/api/questions/getFirstModuleQuestion/${moduleId}`, { headers });
    let question = response.data;
    while (!question.id) {
      moduleId += 1;
      response = await API.get(`/api/questions/getFirstModuleQuestion/${moduleId}`, { headers });
      question = response.data;
    }
    navigate(`/questionnaire/report-request-${reportRequestId}/form-intro/module-${moduleId}/question-${question.id}`, {
      state: { question: question, openModule: '0' },
    });
  };

  const displayFormIntroContent = () => {
    let moduleId = findModules().id;

    return (
      <>
        <h2 className='padding-bottom text-center'>{t('questionnaire.intro')}</h2>
        {findForm()[embeddedVideoKey] != '' ? (
          <p align='center'>
            <iframe
              width='800'
              height='450'
              src={findForm()[embeddedVideoKey]}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </p>
        ) : (
          ''
        )}
        {findForm()[toolkitKey] != '' ? (
          <p align='center'>
            <a
              href={process.env.PUBLIC_URL + '/client_toolkits/' + findForm()[toolkitKey]}
              target='_blank'
              rel='noreferrer'
            >
              Download toolkit
            </a>
          </p>
        ) : (
          ''
        )}
        <div>
          <h1 className='form-intro-product-name'>{findForm()[titleKey]}</h1>
          <div className='form-intro-text'>{findForm()[introKey]}</div>
        </div>
        <button
          className='primary-button ml-0'
          onClick={() => openFirstModuleQuestion(moduleId)}
        >
          {t('questionnaire.start')}
        </button>
      </>
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!forms) {
    loadForms();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!modules) {
    loadModules();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    localStorage.setItem('reportRequestId', reportRequestId);
    localStorage.setItem('reportProductId', reportProductId);
    return (
      <div className='container-scroller'>
        <Header />
        {questionsInfo.length == 0 ? (
          <Spinner animation='grow' variant='info' className='loader' />
        ) : (
          <div className='container-fluid page-body-wrapper'>
            <Sidebar reportRequestId={reportRequestId} questionsInfo={questionsInfo} />
            <div className='main-panel'>
              <div className='content-wrapper'>
                <div className='row padding-top justify-content-center'>
                  <div className='col-8'>
                    {forms.length === 0 || !findModules() ? (
                      <Alert variant='warning'>{t('questionnaire.errors.no_module')}</Alert>
                    ) : (
                      displayFormIntroContent()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  forms: state.auth.forms,
  modules: state.auth.modules,
});

export default connect(mapStateToProps, { loadUser, loadForms, loadModules })(FormIntro);
