import React, { useState, useEffect } from 'react';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { Spinner, Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestees, loadPortfolios } from '../../../actions/auth';
import DevelopmentGoals from '../../Shared/DevelopmentGoals';
import CompaniesReport from '../../SuperAdmin/Shared/CompaniesReport';
import DisplayInvesteeUsers from '../../Shared/ShowAction/DisplayInvesteeUsers';
import DisplayInvesteeKpis from '../../Shared/ShowAction/DisplayInvesteeKpis';
import DisplayCompanyInfo from '../../Shared/ShowAction/DisplayCompanyInfo';
import EditCompanyInfo from '../../Shared/EditAction/EditCompanyInfo';
import { useTranslation } from 'react-i18next';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';

function InvesteeCompany(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let { user, investors, investees, portfolios } = props;
  let { loadUser, loadInvestors, loadInvestees, loadPortfolios } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [storedValue, setStoredValue] = useState(location.state.investee);
  let [submitted, setSubmitted] = useState(false);
  let [showReports, setShowReports] = useState(true);
  let [showInvesteeUsers, setShowInvesteeUsers] = useState(false);
  let [showKpis, setShowKpis] = useState(false);
  let [showInfo, setShowInfo] = useState(false);
  let [newVal, setNewVal] = useState({
    name: storedValue ? storedValue.name : '',
    company_email: storedValue ? storedValue.company_email : '',
    company_website: storedValue ? storedValue.company_website : '',
    investment_amount: storedValue ? storedValue.investment_amount : '',
    description: storedValue ? storedValue.description : '',
    development_goals: storedValue ? storedValue.development_goals : '',
    company_size: storedValue ? storedValue.company_size : '',
    naics_category_1: storedValue ? storedValue.naics_category_1 : '',
    naics_category_2: storedValue ? storedValue.naics_category_2 : '',
    fiscal_year_end: storedValue ? storedValue.fiscal_year_end : '',
    currency: storedValue ? storedValue.currency : '',
    annual_revenue: storedValue ? storedValue.annual_revenue : '',
    ebitda: storedValue ? storedValue.ebitda : '',
    net_income: storedValue ? storedValue.net_income : '',
    investee_logo: null,
    users: storedValue ? storedValue.users : [],
    main_contact: storedValue ? storedValue.main_contact : '',
  });
  let [isEdit, setIsEdit] = useState(false);
  let [companySize, setCompanySize] = useState({});
  let [naics, setNaics] = useState({});
  let [currency, setCurrency] = useState({});
  let [investeeUsers, setInvesteeUsers] = useState([]);
  let [mainContact, setMainContact] = useState('');

  useEffect(() => {
    return () => {
      localStorage.removeItem('members');
    };
  }, []);

  useEffect(() => {
    const sizeUrl = 'api/constant_ddls/?name=size';
    const naicsUrl = 'api/constant_ddls/?name=naics';
    const currencyUrl = 'api/constant_ddls/?name=currency';
    API.get(sizeUrl, { headers }).then((response) => {
      setCompanySize(response.data);
    });
    API.get(naicsUrl, { headers }).then((response) => {
      setNaics(response.data);
    });
    API.get(currencyUrl, { headers }).then((response) => {
      setCurrency(response.data);
    });
    fetchInvesteeUsers();
  }, []);

  useEffect(() => {
    fetchMainContact();
  }, [storedValue]);

  const fetchMainContact = async () => {
    let id = storedValue.main_contact;
    if (id) {
      const response = await API.get(`api/users/${id}`, { headers });
      setMainContact(response.data);
    }
  };

  const fetchInvesteeUsers = async () => {
    if (storedValue.users) {
      const response = await API.get(`api/users/?idInvestee=${storedValue.id}`, { headers });
      setInvesteeUsers((users) => [...users, response.data]);
    }
  };

  const handleFormChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      investee_logo: event.target.files[0],
    });
  };

  const handleSubmit = () => {
    let users = newVal.users;
    const url = `api/investees/${storedValue.id}/`;
    const formData = new FormData();
    formData.append('name', newVal.name);
    formData.append('company_email', newVal.company_email);
    formData.append('company_website', newVal.company_website);
    formData.append('description', newVal.description);
    formData.append('development_goals', newVal.development_goals);
    formData.append('company_size', newVal.company_size);
    formData.append('naics_category_1', newVal.naics_category_1);
    formData.append('naics_category_2', newVal.naics_category_2);
    formData.append('fiscal_year_end', newVal.fiscal_year_end);
    formData.append('currency', newVal.currency);
    formData.append('annual_revenue', newVal.annual_revenue);
    formData.append('ebitda', newVal.ebitda);
    formData.append('net_income', newVal.net_income);
    newVal.investee_logo ? formData.append('investee_logo', newVal.investee_logo) : '';

    for (var i = 0; i < users.length; i++) {
      formData.append('users', users[i]);
    }
    formData.append('main_contact', newVal.main_contact);

    if (
      (newVal.company_size || newVal.company_size === 0) &&
      newVal.description.length <= 500 &&
      newVal.naics_category_1 &&
      newVal.naics_category_2 &&
      newVal.currency &&
      newVal.annual_revenue &&
      newVal.ebitda &&
      newVal.net_income
    ) {
      API.put(url, formData, { headers })
        .then((response) => {
          setStoredValue(response.data);
          setMainContact(response.data.main_contact);
          setIsEdit(false);
        })
        .catch(function () {
          console.log('Error while updating the company');
        });
    } else {
      setSubmitted(true);
    }
  };

  const displayReport = () => {
    setShowReports(true);
    setShowInvesteeUsers(false);
    setShowKpis(false);
    setShowInfo(false);
  };

  const displayInvestee = () => {
    setShowReports(false);
    setShowInvesteeUsers(true);
    setShowKpis(false);
    setShowInfo(false);
  };

  const displayKpis = () => {
    setShowReports(false);
    setShowInvesteeUsers(false);
    setShowKpis(true);
    setShowInfo(false);
  };

  const displayInfo = () => {
    setShowReports(false);
    setShowInvesteeUsers(false);
    setShowKpis(false);
    setShowInfo(true);
  };

  const selectSpecificTab = () => {
    return (
      <div className='padding-left padding-top tabs'>
        {showReports ? (
          <button className='active-tab'>{t('misc.reports')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayReport}>
            {t('misc.reports')}
          </button>
        )}
        {showInvesteeUsers ? (
          <button className='active-tab'>{t('investorDashboard.members')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayInvestee}>
            {t('investorDashboard.members')}
          </button>
        )}
        {showKpis ? (
          <button className='active-tab'>{t('misc.dashboard')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayKpis}>
            {t('misc.dashboard')}
          </button>
        )}
        {showInfo ? (
          <button className='active-tab'>{t('misc.information')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayInfo}>
            {t('misc.information')}
          </button>
        )}
      </div>
    );
  };

  const displaySelectedTabDetails = (investor) => {
    return showInvesteeUsers ? (
      <DisplayInvesteeUsers
        user={user}
        investor={investor}
        investee={storedValue}
        idInvestee={storedValue.id}
        url='/investor/investee-companies/company-details/member'
      />
    ) : showKpis ? (
      <DisplayInvesteeKpis investee={storedValue} />
    ) : showReports ? (
      <CompaniesReport investees={investees} isAdmin={false} investee={storedValue} isInvestor={true} />
    ) : (
      displayCompanyInfoTab(storedValue)
    );
  };

  const displayCompanyInfoTab = (investee) => {
    return (
      <div className='row padding-left padding-top padding-bottom'>
        {isEdit ? (
          <EditCompanyInfo
            investee={investee}
            newVal={newVal}
            companySize={companySize}
            naics={naics}
            currency={currency}
            investeeUsers={investeeUsers}
            submitted={submitted}
            setSubmitted={setSubmitted}
            setNewVal={setNewVal}
            setIsEdit={setIsEdit}
            setInvesteeUsers={setInvesteeUsers}
            onChange={handleFormChange}
            onFileChange={handleFileChange}
            onSubmit={handleSubmit}
          />
        ) : (
          <DisplayCompanyInfo
            investee={investee}
            companySize={companySize}
            naics={naics}
            currencies={currency}
            mainContact={mainContact}
            setIsEdit={setIsEdit}
          />
        )}
      </div>
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!portfolios) {
    loadPortfolios();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    let investor = investors[0];

    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investor={investor} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between page-content padding-right padding-top'>
                  <span>
                    <img src={line} className='heading-line' />
                    <button
                      className='investor-profile-back-button padding-left'
                      onClick={() => navigate('/investor/investee-companies')}
                    >
                      <i className='fas fa-fw fa-arrow-left'></i>
                      <span>{t('misc.back')}</span>
                    </button>
                  </span>
                </div>
                <div>
                  <span className='header-2 padding-left'>{storedValue.name}</span>
                  <p className='primary padding-left' style={{ marginBottom: '0px' }}>
                    {storedValue.company_email}
                  </p>
                  <p className='primary padding-left' style={{ marginBottom: '0px' }}>
                    {storedValue.company_website}
                  </p>
                </div>
                <div className='d-sm-flex align-items-center justify-content-between padding-right company-content'>
                  <div className='padding-right padding-left' style={{ width: '80%' }}>
                    <h5>{t('shared.description')}</h5>
                    <p>{storedValue.description}</p>
                  </div>
                  <Card style={{ width: '40%' }}>
                    <Card.Body>
                      <Card.Title className='fs-16 black'>
                        {user.type === 'Consultant' ? t('shared.mandateAmount') : t('shared.investmentAmount')}
                      </Card.Title>
                      <Card.Title className='primary fs-2'>$ {storedValue.investment_amount}</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <DevelopmentGoals tags={storedValue.development_goals} />
              </div>
              {/* Working with Tabs */}
              {selectSpecificTab()}
              {displaySelectedTabDetails(investor)}
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
  portfolios: state.auth.portfolios,
});

export default connect(mapStateToProps, {
  loadUser,
  loadInvestors,
  loadInvestees,
  loadPortfolios,
})(InvesteeCompany);
