import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as LogoutIcon } from '../../../images/svg-icons/sign-out-alt.svg';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import { useTranslation } from 'react-i18next';

function Logout(props) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleLogout = () => {
    props.logout();
    setShowModal(false);
    window.location.href = '/login';
  };

  return (
    <>
      <button onClick={() => setShowModal(true)}>
        <LogoutIcon className='svg-icon' />
        <span className='ml-point5'>{t('shared.logout')}</span>
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title className='padding-left padding-right'>{t('shared.are_you_sure_logout')}</Modal.Title>
        <Modal.Body className='no-padding-top'>
          <div className='modal-buttons'>
            <button onClick={() => setShowModal(false)} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button onClick={handleLogout} className='primary-button content-right'>
              {t('shared.logout')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  token: state.auth.token,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Logout);
