import React from 'react';
import Sidebar from '../Shared/Sidebar';
import Header from '../../Shared/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser } from '../../../actions/auth';
import { Spinner } from 'react-bootstrap';
import DisplayUserDetails from '../../Shared/ShowAction/DisplayUserDetails';
import { useTranslation } from 'react-i18next';

function InvesteeMemberProfile(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let { user, investor, investee } = location.state;

  if (!props.user) {
    props.loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investor={investor} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between page-content padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <button
                      className='investor-profile-back-button padding-left'
                      onClick={() =>
                        navigate('/investor/investee-companies/company-details', {
                          state: { user: user, investor: investor, investee: investee },
                        })
                      }
                    >
                      <i className='fas fa-fw fa-arrow-left'></i>
                      <span>{t('misc.back')}</span>
                    </button>
                  </span>
                </div>
                <div>
                  <span className='header-2 padding-left'>
                    {user.first_name} {user.last_name}
                  </span>
                </div>
              </div>
              {/* Displaying the user details */}
              <DisplayUserDetails userDetails={user} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(InvesteeMemberProfile);
