import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import API from '../../../api';
import { removePathEmptyParams } from '../../../services/helpers';

function FilterReport(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const nameKey = `name_${currentLanguage}`;
  const { headers, reportingPeriods, products, reportStatuses, owner, state, investorId, investee, user } = props;
  const { fetchItems, setItems } = props;
  const [reportStatusId, setReportStatusId] = useState(1);
  const [productId, setProductId] = useState('');
  const [reportingPeriodId, setReportingPeriodId] = useState('');
  const [filteredRP, setFilteredRP] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(null);

  useEffect(() => {
    const productMap = new Map();
    products.forEach((product) => {
      const existingProduct = productMap.get(product.code);
      if (!existingProduct || product.id > existingProduct.id) {
        productMap.set(product.code, product);
      }
    });
    setFilteredProducts(Array.from(productMap.values()));
  }, []);

  const setParameters = (url) => {
    setProductId('');
    setReportingPeriodId('');
    displayReportRequests(url);
  };

  const filterReportRequest = (event) => {
    const { name, value } = event.target;
    let query = '';
    let url = '';
    if (name === 'status' && value !== '') {
      query = `owner=${owner}&&product=${productId}&&reporting_period=${reportingPeriodId}&&report_status=${value}&&investor_id=${investorId}`;
      url = `api/report_requests/?${removePathEmptyParams(query)}`;
      setReportStatusId(value);
      displayReportRequests(url);
    } else if (name === 'product' && value !== '') {
      query = `owner=${owner}&&product=${value}&&reporting_period=${reportingPeriodId}&&report_status=${reportStatusId}&&investor_id=${investorId}`;
      url = `api/report_requests/?${removePathEmptyParams(query)}`;
      setProductId(value);
      setFilteredRP(reportingPeriods);
      displayReportRequests(url);
    } else if (name === 'product' && value === '') {
      query = `owner=${owner}&&product=${value}&&reporting_period=${reportingPeriodId}&&report_status=${reportStatusId}&&investor_id=${investorId}`;
      url = `api/report_requests/?${removePathEmptyParams(query)}`;
      setParameters(url);
    } else if (name === 'reports_order' && value === 'alphabetical_order') {
      query = `owner=${owner}&&product=${productId}&&reporting_period=${reportingPeriodId}&&report_status=${reportStatusId}&&investor_id=${investorId}`;
      url = `api/report_requests/alphabetical_order/?${removePathEmptyParams(query)}`;
      setParameters(url);
    } else if (name === 'reports_order' && value === 'alphabetical_order_desc') {
      query = `owner=${owner}&&product=${productId}&&reporting_period=${reportingPeriodId}&&report_status=${reportStatusId}&&investor_id=${investorId}`;
      url = `api/report_requests/alphabetical_order_desc/?${removePathEmptyParams(query)}`;
      setParameters(url);
    } else if (name === 'reporting_period') {
      query = `owner=${owner}&&product=${productId}&&reporting_period=${value}&&report_status=${reportStatusId}&&investor_id=${investorId}`;
      url = `api/report_requests/?${removePathEmptyParams(query)}`;
      setReportingPeriodId(value);
      displayReportRequests(url);
    } else {
      fetchItems();
      setFilteredRP([]);
    }
  };

  const displayReportRequests = (url) => {
    API.get(url, { headers })
      .then((response) => {
        setItems(response.data);
      })
      .catch(function () {
        console.log('Error while loading report requests');
      });
  };

  return (
    <>
      {state == false ? (
        <>
          <Form.Select name='status' className='ml-1-status' onChange={(e) => filterReportRequest(e)}>
            <option value=''>{t('shared.selectStatus')}</option>
            {reportStatuses &&
              reportStatuses.map((status, index) => (
                <option key={index} value={status.id}>
                  {status[nameKey]}
                </option>
              ))}
          </Form.Select>
          <Form.Select name='product' className='ml-1-product' onChange={(e) => filterReportRequest(e)}>
            <option value=''>{t('shared.selectProduct')}</option>
            {filteredProducts &&
              filteredProducts.map((product, index) => (
                <option key={index} value={product.id}>
                  {product[nameKey]}
                </option>
              ))}
          </Form.Select>
          <Form.Select className='dropdown-ml-1' name='reporting_period' onChange={(e) => filterReportRequest(e)}>
            <option value=''>{t('shared.selectReportingPeriod')}</option>
            {filteredRP &&
              filteredRP.map((period, index) => (
                <option key={index} value={period.id}>
                  {period.name_en}
                </option>
              ))}
          </Form.Select>
          {investee || user.type == 'Enterprise' ? (
            <></>
          ) : (
            <Form.Select className='dropdown-ml-1' name='reports_order' onChange={(e) => filterReportRequest(e)}>
              <option value=''>{t('shared.sortAlphabetical')}</option>
              <option value='alphabetical_order'>{t('shared.sortReportRequestAZ')}</option>
              <option value='alphabetical_order_desc'>{t('shared.sortReportRequestZA')}</option>
            </Form.Select>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  investees: state.auth.investees,
});

export default connect(mapStateToProps)(FilterReport);
