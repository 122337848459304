import React from 'react';

export function AuthenticatedLink({ url, filename, children }) {
  const authHeaders = { Authorization: 'Token ' + localStorage.token };

  const handleAction = async () => {
    const result = await fetch(url, {
      headers: { ...authHeaders },
    });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);

    let ext_re = /(?:\.([^.]+))?$/;
    let ext = ext_re.exec(filename);
    let dlName = ext == ',' ? filename + '.pdf' : filename;

    const link = document.createElement('a');
    link.href = href;
    link.download = dlName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <a type='button' onClick={handleAction}>
        {children}
      </a>
    </>
  );
}
