import React from 'react';
import GraphView from '../../Shared/GraphAction/GraphView';

function DisplayInvesteeKpis(props) {
  let { investee } = props;

  return (
    <div className='row padding-left padding-top'>
      <GraphView businessEntity={investee} />
    </div>
  );
}

export default DisplayInvesteeKpis;
