import React, { useState } from 'react';
import API from '../../../api';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

function InviteMember(props) {
  let { id, fetchUserDetails } = props;
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    businessEntity: id,
  });
  const { first_name, last_name, email, businessEntity } = formData;
  const { t } = useTranslation();

  const handleChange = (event) => {
    setForm({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const headers = { Authorization: 'Token ' + localStorage.token };
    const url = 'api/invite_user';
    const reset_url = 'api/password_reset/';
    API.post(
      url,
      { first_name: first_name, last_name: last_name, email: email, business_entity: businessEntity },
      { headers },
    )
      .then((response) => {
        console.log(response.data);
        setMessage(t('shared.memberAddedSuccessfully'));
        setShowMessage(true);
        fetchUserDetails(id, true);
        API.post(reset_url, { email: email });
      })
      .catch(function () {
        setMessage(t('shared.userAlreadyAdded'));
        setShowMessage(true);
      });
  };

  const closeModal = () => {
    setShowModal(false);
    setForm({ email: '', businessEntity: props.id });
    setMessage('');
    setShowMessage(false);
  };

  return (
    <>
      <button className='primary-button' onClick={() => setShowModal(true)}>
        <i className='fas fa-fw fa-plus mr-point2'></i>
        <span>{t('shared.inviteMember')}</span>
      </button>
      <Modal className='authentication' show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        {showMessage ? <div className='alert alert-success ml-1 mr-1'>{message}</div> : ''}
        <Modal.Title>{t('shared.inviteMember')}</Modal.Title>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              name='first_name'
              onChange={(event) => handleChange(event)}
              type='text'
              value={first_name}
              placeholder={t('shared.first_name').toUpperCase()}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              name='last_name'
              onChange={(event) => handleChange(event)}
              type='text'
              value={last_name}
              placeholder={t('shared.last_name').toUpperCase()}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              name='email'
              onChange={(event) => handleChange(event)}
              type='email'
              value={email}
              placeholder={t('shared.email')}
            />
          </Form.Group>
          <Form.Group>
            <button onClick={onSubmit} className='primary-button full-width'>
              {t('shared.inviteButton')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InviteMember;
