import React from 'react';
import defaultImage from '../../../images/default-image.png';
import { useTranslation } from 'react-i18next';

function DisplayUserDetails(props) {
  let { userDetails } = props;
  const { t } = useTranslation();

  return (
    <div className='row justify-content-md-center padding-left padding-bottom'>
      <div className='page-content text-center'>
        <img src={defaultImage} className='investor-profile-img' />
        <p className='text-muted'>{userDetails.role}</p>
        <h3>
          {userDetails.first_name} {userDetails.last_name}
        </h3>
        <p className='primary'>{userDetails.email}</p>
      </div>
      <div className='col-md-8 col-md-2-offset'>
        <table className='table main2'>
          <tbody>
            <tr className='table-line'>
              <td className='fw-bold vertical-content'>{t('shared.addressLine1')}</td>
              <td className='vertical-content'>{userDetails.address_line1}</td>
            </tr>
            <tr>
              <td className='fw-bold vertical-content'>{t('shared.addressLine2')}</td>
              <td className='vertical-content'>{userDetails.address_line2}</td>
            </tr>
            <tr>
              <td className='fw-bold vertical-content'>{t('shared.city').toUpperCase()}</td>
              <td className='vertical-content'>{userDetails.city}</td>
            </tr>
            <tr>
              <td className='fw-bold vertical-content'>{t('shared.zip')}</td>
              <td className='vertical-content'>{userDetails.zip_code}</td>
            </tr>
            <tr>
              <td className='fw-bold vertical-content'>{t('shared.region').toUpperCase()}</td>
              <td className='vertical-content'>{userDetails.region}</td>
            </tr>
            <tr>
              <td className='fw-bold vertical-content'>{t('shared.country').toUpperCase()}</td>
              <td className='vertical-content'>{userDetails.country}</td>
            </tr>
            <tr>
              <td className='fw-bold vertical-content'>{t('shared.phone').toUpperCase()}</td>
              <td className='vertical-content'>{userDetails.phone_number}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DisplayUserDetails;
