import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CancelEditForm() {
  const { t } = useTranslation();
  let [showModal, setShowModal] = useState(false);

  return (
    <>
      <button className='cancel-button' onClick={() => setShowModal(true)}>
        {t('misc.cancel')}
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title className='padding-left padding-right'>{t('shared.confirmationTitle')}</Modal.Title>
        <Modal.Body className='no-padding-top'>
          <Form.Group className='modal-buttons'>
            <button className='primary-button' onClick={() => setShowModal(false)}>
              {t('misc.yes')}
            </button>
            <button className='cancel-button primary-button content-right' onClick={() => setShowModal(false)}>
              {t('misc.no')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CancelEditForm;
