import { useTranslation } from 'react-i18next';

export const getDevelopmentGoals = () => {
  const { t } = useTranslation();

  return [
    { value: '1', label: t('developmentGoal.label1') },
    { value: '2', label: t('developmentGoal.label2') },
    { value: '3', label: t('developmentGoal.label3') },
    { value: '4', label: t('developmentGoal.label4') },
    { value: '5', label: t('developmentGoal.label5') },
    { value: '6', label: t('developmentGoal.label6') },
    { value: '7', label: t('developmentGoal.label7') },
    { value: '8', label: t('developmentGoal.label8') },
    { value: '9', label: t('developmentGoal.label9') },
    { value: '10', label: t('developmentGoal.label10') },
    { value: '11', label: t('developmentGoal.label11') },
    { value: '12', label: t('developmentGoal.label12') },
    { value: '13', label: t('developmentGoal.label13') },
    { value: '14', label: t('developmentGoal.label14') },
    { value: '15', label: t('developmentGoal.label15') },
    { value: '16', label: t('developmentGoal.label16') },
    { value: '17', label: t('developmentGoal.label17') },
  ];
};
