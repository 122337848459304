import React, { useState, useEffect } from 'react';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { Card, Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestees, loadProducts, loadReportingPeriods } from '../../../actions/auth';
import DevelopmentGoals from '../../Shared/DevelopmentGoals';
import DisplayInvesteeUsers from '../../Shared/ShowAction/DisplayInvesteeUsers';
import DisplayCompanyInfo from '../../Shared/ShowAction/DisplayCompanyInfo';
import EditCompanyInfo from '../../Shared/EditAction/EditCompanyInfo';
import { useTranslation } from 'react-i18next';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';

function CompanyName(props) {
  const { t, i18n } = useTranslation();
  const current_language = i18n.language;
  let { user, investees, products, reportingPeriods } = props;
  let { loadUser, loadInvestees, loadProducts, loadReportingPeriods } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  const storedValue = JSON.parse(localStorage.getItem('investee'));

  let [newVal, setNewVal] = useState({
    name: storedValue ? storedValue.investee.name : '',
    company_email: storedValue ? storedValue.investee.company_email : '',
    company_website: storedValue ? storedValue.investee.company_website : '',
    investment_amount: storedValue ? storedValue.investee.investment_amount : '',
    description: storedValue ? storedValue.investee.description : '',
    development_goals: storedValue ? storedValue.investee.development_goals : '',
    company_size: storedValue ? storedValue.investee.company_size : '',
    naics_category_1: storedValue ? storedValue.investee.naics_category_1 : '',
    naics_category_2: storedValue ? storedValue.investee.naics_category_2 : '',
    fiscal_year_end: storedValue ? storedValue.investee.fiscal_year_end : '',
    currency: storedValue ? storedValue.investee.currency : '',
    annual_revenue: storedValue ? storedValue.investee.annual_revenue : '',
    ebitda: storedValue ? storedValue.investee.ebitda : '',
    net_income: storedValue ? storedValue.investee.net_income : '',
    logo: null,
    users: storedValue ? storedValue.investee.users : [],
    main_contact: storedValue.investee.main_contact ? storedValue.investee.main_contact : '',
  });

  let [showInvesteeUsers, setShowInvesteeUsers] = useState(true);
  let [showInfo, setShowInfo] = useState(false);
  let [isEdit, setIsEdit] = useState(false);
  let [companySize, setCompanySize] = useState({});
  let [naics, setNaics] = useState({});
  let [currency, setCurrency] = useState({});
  let [submitted, setSubmitted] = useState(false);
  let [investeeUsers, setInvesteeUsers] = useState([]);
  let [mainContact, setMainContact] = useState('');

  useEffect(() => {
    return () => {
      localStorage.removeItem('members');
    };
  }, []);

  useEffect(() => {
    fetchInvesteeUsers();
    const sizeUrl = 'api/constant_ddls/?name=size';
    const naicsUrl = 'api/constant_ddls/?name=naics';
    const currencyUrl = 'api/constant_ddls/?name=currency';
    API.get(sizeUrl, { headers }).then((response) => {
      setCompanySize(response.data);
    });
    API.get(naicsUrl, { headers }).then((response) => {
      setNaics(response.data);
    });
    API.get(currencyUrl, { headers }).then((response) => {
      setCurrency(response.data);
    });
  }, []);

  useEffect(() => {
    fetchMainContact();
  }, [isEdit]);

  const fetchMainContact = async () => {
    let id = newVal.main_contact;
    if (id) {
      const response = await API.get(`api/users/${id}`, { headers });
      setMainContact(response.data);
    }
  };

  const fetchInvesteeUsers = async () => {
    if (newVal.users) {
      const response = await API.get(`api/users/?idInvestee=${storedValue.investee.id}`, { headers });
      setInvesteeUsers(response.data);
    }
  };

  const handleFormChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      logo: event.target.files[0],
    });
  };

  const handleSubmit = () => {
    let users = newVal.users;
    const url = `api/investees/${storedValue.investee.id}/`;
    const formData = new FormData();
    formData.append('name', newVal.name);
    formData.append('company_email', newVal.company_email);
    formData.append('company_website', newVal.company_website);
    formData.append('description', newVal.description);
    formData.append('development_goals', newVal.development_goals);
    formData.append('company_size', newVal.company_size);
    formData.append('naics_category_1', newVal.naics_category_1);
    formData.append('naics_category_2', newVal.naics_category_2);
    formData.append('fiscal_year_end', newVal.fiscal_year_end);
    formData.append('currency', newVal.currency);
    formData.append('annual_revenue', newVal.annual_revenue);
    formData.append('ebitda', newVal.ebitda);
    formData.append('net_income', newVal.net_income);
    newVal.logo ? formData.append('investee_logo', newVal.logo) : '';

    for (var i = 0; i < users.length; i++) {
      formData.append('users', users[i]);
    }
    formData.append('main_contact', newVal.main_contact);

    if (
      (newVal.company_size || newVal.company_size === 0) &&
      newVal.description.length <= 500 &&
      newVal.naics_category_1 &&
      newVal.naics_category_2 &&
      newVal.currency &&
      newVal.annual_revenue &&
      newVal.ebitda &&
      newVal.net_income
    ) {
      API.put(url, formData, { headers })
        .then((response) => {
          localStorage.setItem('investee', JSON.stringify({ investee: response.data }));
          loadInvestees();
          setMainContact(response.data.main_contact);
          setIsEdit(false);
        })
        .catch(function () {
          console.log('Error while updating the company');
        });
    } else {
      setSubmitted(true);
    }
  };


  const displayInvestee = () => {
    setShowInvesteeUsers(true);
    setShowInfo(false);
  };

  const displayInfo = () => {
    setShowInvesteeUsers(false);
    setShowInfo(true);
  };

  const selectSpecificTab = () => {
    return (
      <div className='padding-left padding-top tabs'>
        {showInvesteeUsers ? (
          <button className='active-tab'>{t('investorDashboard.members')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayInvestee}>
            {t('investorDashboard.members')}
          </button>
        )}
        {showInfo ? (
          <button className='active-tab'>{t('misc.information')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayInfo}>
            {t('misc.information')}
          </button>
        )}
      </div>
    );
  };

  const displaySelectedTabDetails = (investee) => {
    return showInvesteeUsers ? (
      <DisplayInvesteeUsers
        investee={investee}
        idInvestee={storedValue.investee.id}
        url='/investee/company-name/users'
      />
    ) : (
      displayCompanyInfoTab(investee)
    );
  };

  const displayCompanyInfoTab = (investee) => {
    return (
      <>
        <div className='row padding-left padding-top padding-bottom'>
          {isEdit ? (
            <EditCompanyInfo
              investee={investee}
              newVal={newVal}
              companySize={companySize}
              naics={naics}
              currency={currency}
              investeeUsers={investeeUsers}
              submitted={submitted}
              setSubmitted={setSubmitted}
              setNewVal={setNewVal}
              setIsEdit={setIsEdit}
              setInvesteeUsers={setInvesteeUsers}
              onChange={handleFormChange}
              onFileChange={handleFileChange}
              onSubmit={handleSubmit}
            />
          ) : (
            <DisplayCompanyInfo
              investee={investee}
              companySize={companySize}
              naics={naics}
              currencies={currency}
              mainContact={mainContact}
              setIsEdit={setIsEdit}
            />
          )}
        </div>
      </>
    );
  };



  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!products) {
    loadProducts();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!reportingPeriods) {
    loadReportingPeriods();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    let termsOfAgreementRoute = `/terms-of-agreement/${current_language}`;
    return <Navigate to={termsOfAgreementRoute} />;
  } else {
    let investee = storedValue.investee;

    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investee={investee} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between page-content padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>{investee.name}</span>
                  </span>
                </div>
                <div>
                  <p className='primary padding-left' style={{ marginBottom: '0px' }}>
                    {investee.company_email}
                  </p>
                  <p className='primary padding-left' style={{ marginBottom: '0px' }}>
                    {investee.company_website}
                  </p>
                </div>
                <div className='d-sm-flex align-items-center justify-content-between padding-right company-content'>
                  <div className='padding-left padding-right' style={{ width: '80%' }}>
                    <h5>DESCRIPTION</h5>
                    <p>{investee.description}</p>
                  </div>
                  <Card style={{ width: '40%' }}>
                    <Card.Body>
                      <Card.Title className='fs-16 black'>
                        {user.type === 'Enterprise' ? t('shared.mandate_amount') : t('shared.investmentAmount')}
                      </Card.Title>
                      <Card.Title className='primary fs-2'>$ {investee.investment_amount}</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <DevelopmentGoals tags={investee.development_goals} />
              </div>
              {/* Working with Tabs */}
              {selectSpecificTab()}
              {displaySelectedTabDetails(investee)}

              {/* <div className="alert alert-warning">
                We couldn`t find any Investee company
              </div> */}
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investees: state.auth.investees,
  products: state.auth.products,
  reportingPeriods: state.auth.reportingPeriods,
});

export default connect(mapStateToProps, {
  loadUser,
  loadInvestees,
  loadProducts,
  loadReportingPeriods,
})(CompanyName);
