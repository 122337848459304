import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { ReactComponent as EditIcon } from '../../../images/svg-icons/edit.svg';
import Select from 'react-select';
import { getDevelopmentGoals } from '../../../services/getDevelopmentGoals';
import { useTranslation } from 'react-i18next';

function EditInvesteeCompany(props) {
  const { t } = useTranslation();
  let { newVal, investee, portfolios, submitted, button } = props;
  let { onChange, onSubmit, setNewVal, setShowMessage, setSubmitted } = props;
  let [showModal, setShowModal] = useState(false);
  let options = getDevelopmentGoals();
  let [selectedOptions, setSelectedOptions] = useState([]);

  const handleOption = (options) => {
    setSelectedOptions(options);
    let goalValues = [];
    for (let i of options) {
      goalValues.push(i.value);
    }
    setNewVal({
      ...newVal,
      development_goals: goalValues.toString(),
    });
  };

  const displayModal = () => {
    let devGoalValue = investee.development_goals[0] === '[]' ? '' : investee.development_goals;
    setShowModal(true);
    setNewVal({
      id: investee.id,
      name: investee.name,
      company_email: investee.company_email,
      description: investee.description,
      development_goals: devGoalValue,
      investment_amount: investee.investment_amount,
      owner: investee.owner,
    });

    let selectedDevGoals = devGoalValue && devGoalValue.map((goal) => ({ value: goal }));

    selectedDevGoals &&
      selectedDevGoals.map((goal, index) => {
        if (selectedDevGoals[index].value === '1') {
          goal.label = t('developmentGoal.label1');
        } else if (selectedDevGoals[index].value === '2') {
          goal.label = t('developmentGoal.label2');
        } else if (selectedDevGoals[index].value === '3') {
          goal.label = t('developmentGoal.label3');
        } else if (selectedDevGoals[index].value === '4') {
          goal.label = t('developmentGoal.label4');
        } else if (selectedDevGoals[index].value === '5') {
          goal.label = t('developmentGoal.label5');
        } else if (selectedDevGoals[index].value === '6') {
          goal.label = t('developmentGoal.label6');
        } else if (selectedDevGoals[index].value === '7') {
          goal.label = t('developmentGoal.label7');
        } else if (selectedDevGoals[index].value === '8') {
          goal.label = t('developmentGoal.label8');
        } else if (selectedDevGoals[index].value === '9') {
          goal.label = t('developmentGoal.label9');
        } else if (selectedDevGoals[index].value === '10') {
          goal.label = t('developmentGoal.label10');
        } else if (selectedDevGoals[index].value === '11') {
          goal.label = t('developmentGoal.label11');
        } else if (selectedDevGoals[index].value === '12') {
          goal.label = t('developmentGoal.label12');
        } else if (selectedDevGoals[index].value === '13') {
          goal.label = t('developmentGoal.label13');
        } else if (selectedDevGoals[index].value === '14') {
          goal.label = t('developmentGoal.label14');
        } else if (selectedDevGoals[index].value === '15') {
          goal.label = t('developmentGoal.label15');
        } else if (selectedDevGoals[index].value === '16') {
          goal.label = t('developmentGoal.label16');
        } else goal.label = t('developmentGoal.label17');
      });
    setSelectedOptions(selectedDevGoals);
  };

  const validateDescription = (desc) => {
    if (desc.length > 500) return true;
    else return false;
  };

  const closeModal = () => {
    setShowModal(false);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
    setSubmitted(false);
    setSelectedOptions([]);
  };

  const onSave = () => {
    if (
      newVal.name &&
      newVal.company_email &&
      newVal.description.length <= 500 &&
      newVal.development_goals &&
      newVal.owner
    ) {
      onSubmit();
      setShowMessage(true);
      closeModal();
    } else {
      setSubmitted(true);
    }
  };

  return (
    <>
      {button === true ? (
        <button className='primary-button-outlined investee-edit-button' onClick={displayModal}>
          <EditIcon className='svg-icon' />
          <span>{t('misc.edit')}</span>
        </button>
      ) : (
        <a onClick={displayModal}>
          <EditIcon className='svg-icon mr-1 main2' />
        </a>
      )}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('investeeEditCompany.modalTitle')}</Modal.Title>

        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.company_name')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='name'
              type='text'
              value={newVal.name}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.name ? (
              <p className='text-danger error-message'>{t('investeeEditCompany.errors.requiredName')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.company_email')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='company_email'
              type='email'
              value={newVal.company_email}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.company_email ? (
              <p className='text-danger error-message'>{t('investeeEditCompany.errors.requiredEmail')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.description')}</Form.Label>
            <Form.Control
              name='description'
              type='text'
              as='textarea'
              rows={3}
              value={newVal.description}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && validateDescription(newVal.description) ? (
              <p className='text-danger error-message'>{t('investeeEditCompany.errors.lenghtMsg')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.developmentGoals')} <span className='text-danger'>*</span>
            </Form.Label>
            <Select
              isMulti
              options={options}
              value={selectedOptions}
              onChange={(e) => handleOption(e)}
              className='development-goal-style'
            />
            {submitted && !newVal.development_goals ? (
              <p className='text-danger error-message'>{t('investeeEditCompany.errors.requiredDevelopmentGoals')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.owner')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='owner' value={newVal.owner} onChange={(e) => onChange(e)}>
              <option>{t('shared.choose_option')}</option>
              {portfolios &&
                portfolios.map((portfolio, index) => (
                  <option key={index} value={portfolio.id}>
                    {portfolio.name}
                  </option>
                ))}
            </Form.Select>
            {submitted && !newVal.owner ? (
              <p className='text-danger error-message'>{t('investorEditCompany.errors.owner')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.investmentAmount')}</Form.Label>
            <Form.Control
              name='investment_amount'
              type='number'
              value={newVal.investment_amount}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button onClick={onSave} className='primary-button content-right'>
              {t('misc.save')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditInvesteeCompany;
