import React from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';

function FileUpload(props) {
  let { newVal, report, submitted, buttonDisable, showModal } = props;
  let { setNewVal, onFileChange, onChange, onSubmit, setButtonDisable, setShowModal, setSubmitted } = props;

  const displayModal = () => {
    setShowModal(true);
    setButtonDisable(false);
    setSubmitted(false);
    setNewVal({
      uploaded_file: null,
      description: '',
      report_request: report.id,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Dropdown.Item onClick={displayModal}>Upload File</Dropdown.Item>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title className='padding-left padding-right'>Uploading a file</Modal.Title>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              File Upload <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control type='file' onChange={(e) => onFileChange(e)} className='style-bottom' />
            {submitted && !newVal.uploaded_file ? (
              <p className='text-danger error-message'>File upload is required</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>File name</Form.Label>
            <Form.Control
              name='description'
              as='input'
              rows={3}
              value={newVal.description}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            The file name must include the extension (.pdf, .docx, etc.), otherwise .pdf will be assumed.
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              Cancel
            </button>
            <button onClick={onSubmit} className='primary-button content-right' disabled={buttonDisable}>
              Submit
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FileUpload;
