import React, { useState } from 'react';
import API from '../../../api';
import { Link } from 'react-router-dom';
import logo from '../../../images/oxia-initiative-logo.png';
import { Container, Row, Col, Card, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
  // eslint-disable-next-line no-undef
  document.body.style.backgroundColor = '#F8F8FF';
  const { t } = useTranslation();

  let [formData, setFormData] = useState({
    email: '',
  });
  let [message, setMessage] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    const url = '/api/password_reset/';
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await API.post(
      url,
      {
        email: formData.email,
      },
      config,
    );
    console.log(response.data);
    setMessage(true);
  };

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col xl={4} lg={4} md={4}>
          <div className='authentication'>
            <a href='/'>
              <img src={logo} className='authentication-logo mx-auto d-block' />
            </a>
            <Card className='authentication-card'>
              <Card.Body>
                <Card.Title>{t('shared.password_reset')}</Card.Title>
                {message ? (
                  <Alert variant='light'>
                    <p>{t('shared.thank_you_message')}</p>
                    <p>{t('shared.junk_folder_message')}</p>
                  </Alert>
                ) : (
                  <>
                    <Form.Group>
                      <Form.Label className='password-recovery-style'>
                        {t('shared.enter_email_instructions')}
                      </Form.Label>
                      <Form.Control
                        name='email'
                        type='text'
                        value={formData.email}
                        placeholder={t('shared.email_placeholder')}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <button className='primary-button mt-1' onClick={handleSubmit}>
                      {t('shared.send_email')}
                    </button>
                  </>
                )}
                <div className='text-footer'>
                  {t('shared.back_to_login')} <Link to='/login'>Login</Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
