import React, { useState } from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from '../../../images/svg-icons/delete.svg';
import { useTranslation } from 'react-i18next';

function DeleteReport(props) {
  const { t } = useTranslation();
  let { product, owner, type } = props;
  let { onSubmit } = props;
  const [showModal, setShowModal] = useState(false);

  const displayModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const submitDeleteRequest = () => {
    onSubmit();
    closeModal();
  };

  return (
    <>
      {type === t('shared.deleteType') && (
        <a onClick={displayModal}>
          <DeleteIcon className='svg-icon delete-modal' />
        </a>
      )}

      {type === t('shared.deleteReport') && (
        <Dropdown.Item onClick={displayModal}>{t('shared.deleteReport')}</Dropdown.Item>
      )}

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='no-padding-top text-center'>
          {t('shared.confirmDeleteMessage')}{' '}
          <span className='primary'>
            {product} {t('shared.from')} {owner}
          </span>{' '}
          ?
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.no')}
            </button>
            <button onClick={submitDeleteRequest} className='primary-button content-right'>
              {t('misc.yes')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteReport;
