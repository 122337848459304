import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import store from '../store';

const userLanguage = store.getState().auth.language || 'en';

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: userLanguage,
    backend: {
      loadPath: '/translation_files/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
  });

store.subscribe(() => {
  const currentLanguage = store.getState().auth.language;
  if (i18next.language !== currentLanguage) {
    i18next.changeLanguage(currentLanguage);
  }
});

export default i18next;
