import React from 'react';
import GraphView from '../../Shared/GraphAction/GraphView';

function DisplayInvestorKpis(props) {
  let { investor } = props;

  return (
    <div className='row padding-left padding-top'>
      <GraphView businessEntity={investor} />
    </div>
  );
}

export default DisplayInvestorKpis;
