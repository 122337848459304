import React, { useState } from 'react';
import API from '../../../api';
import { useTranslation } from 'react-i18next';
import download from '../../../images/download.png';
import { AuthenticatedLink } from '../ReportAction/AuthenticatedLink';
import Form from 'react-bootstrap/Form';
function FilterGraphView(props) {
  const { t, i18n } = useTranslation();

  let { headers, products, businessEntity, setIsSelected, setEmptyGraph } = props;
  let { setGraphList } = props;
  let [productId, setProductId] = useState('');
  let [filteredRP, setFilteredRP] = useState([]);
  let [hasKpi, setHasKpi] = useState(false);
  const [reportingPeriodValue, setReportingPeriodValue] = useState('');
  const URL = `${process.env.REACT_APP_API_SERVER}/api/downloadKPI/${businessEntity.id}?reporting_period=${reportingPeriodValue}`;
  const fileName = `datasummary_rrKPI.ppt`;
  const currentLanguage = i18n.language;

  const handleButtonClick = () => {
    filterOnReportingPeriod(reportingPeriodValue, businessEntity);
  };

  const setPropsVar = () => {
    setGraphList([]);
    setIsSelected(true);
    setEmptyGraph(false);
  };

  const fetchReportingPeriod = (value) => {
    API.get(`api/kpi_reporting_period/${businessEntity.id}/?product=${value}`, { headers, currentLanguage })
      .then((response) => {
        setFilteredRP(response.data);
      })
      .catch(function () {
        console.log('Error while loading graphs.');
      });
  };

  const filterGraphs = (event) => {
    const { name, value } = event.target;

    if (name === 'product' && value !== '') {
      setPropsVar();
      filterOnProduct(value, businessEntity);
    } else if (name === 'reporting_period' && value !== '') {
      setPropsVar();
      setHasKpi(true);
      setReportingPeriodValue(value);
    } else if (name === 'reporting_period' && value === '') {
      filterOnProduct(productId, businessEntity);
    } else {
      setGraphList([]);
      setFilteredRP([]);
    }
  };

  const filterOnProduct = (value, businessEntity) => {
    const businessEntityId = businessEntity.id;

    API.get(`api/kpi_graph_view/${businessEntityId}?product=${value}`, { headers, currentLanguage })
      .then(() => {
        setProductId(value);
        fetchReportingPeriod(value);
      })
      .catch(function () {
        console.log('Error while loading graphs.');
      });
  };

  const filterOnReportingPeriod = (value) => {
    const businessEntityId = businessEntity.id;

    API.get(`api/kpi_graph_view/${businessEntityId}?product=${productId}&reporting_period=${value}`, {
      headers,
      currentLanguage,
    })
      .then((response) => {
        if (response.data.length == 0) {
          setEmptyGraph(true);
        }
        setGraphList(response.data);
      })
      .catch(function () {
        console.log('Error while loading graphs.');
      });
  };

  return (
    <div className='mk-3'>
      <Form.Select className='ma-1' name='product' onChange={(e) => filterGraphs(e)}>
        <option value=''>{t('shared.selectProduct')}</option>
        {products &&
          products.map((product, index) => (
            <option key={index} value={product.id}>
              {product.name_en}
            </option>
          ))}
      </Form.Select>
      <Form.Select className='ma-1' name='reporting_period' onChange={(e) => filterGraphs(e)}>
        <option value=''>{t('shared.selectReportingPeriod')}</option>
        {filteredRP &&
          filteredRP.map((period, index) => (
            <option key={index} value={period.id}>
              {period.name_en}
            </option>
          ))}
      </Form.Select>
      {hasKpi ? (
        <>
          {' '}
          <button className='download-kpi-button' onClick={handleButtonClick}>
            {t('shared.generateKPI')}
          </button>
          <AuthenticatedLink url={URL} filename={fileName}>
            <button className='download-kpi-button'>
              <img className='download-kpi' src={download} />
            </button>
          </AuthenticatedLink>
        </>
      ) : null}
    </div>
  );
}

export default FilterGraphView;
