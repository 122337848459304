import { t } from 'i18next';
import React from 'react';
import { Modal, Form, Alert } from 'react-bootstrap';

function CreatePortfolio(props) {
  let { newVal, investors, submitted, message, showModal, isError } = props;
  let { onChange, onFileChange, onSubmit, setShowModal, setIsError, setNewVal, setSubmitted, setShowCreateMessage } =
    props;

  const displayModal = () => {
    setShowModal(true);
    setNewVal({
      id: '',
      name: '',
      description: '',
      logo: null,
      status: '',
      allowed_investee_limit: '',
      owner: '',
    });
  };

  const closeModal = () => {
    setTimeout(() => {
      setShowCreateMessage(false);
    }, 3000);
    setShowModal(false);
    setIsError(false);
    setSubmitted(false);
  };

  const validateReqFelds = () => {
    return !newVal.name || !newVal.status;
  };

  const onSave = () => {
    if (validateReqFelds()) {
      setSubmitted(true);
      return;
    } else onSubmit();
    setShowCreateMessage(true);
    closeModal();
  };

  return (
    <>
      <button className='primary-button' onClick={displayModal}>
        <i className='fas fa-fw fa-plus mr-point2'></i>
        <span>{t('shared.create_portfolio')}</span>
      </button>
      <Modal className='dashboard-modal' show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('shared.create_portfolio')}</Modal.Title>
        {isError ? (
          <Alert variant='danger' className='mt-1 ml-1 mr-1 mb-auto'>
            {message}
          </Alert>
        ) : (
          ''
        )}
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.portfolioName_maj')}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='name'
              type='text'
              value={newVal.name}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.name ? (
              <p className='text-danger error-message'>{t('shared.portfolioNameRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.description')}</Form.Label>
            <Form.Control
              name='description'
              as='textarea'
              rows={3}
              value={newVal.description}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.logo_maj')}</Form.Label>
            <Form.Control type='file' onChange={(e) => onFileChange(e)} className='style-bottom' />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.status_maj')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='status' value={newVal.status} onChange={(e) => onChange(e)}>
              <option>{t('shared.choose_option')}</option>
              {['Pending', 'Active', 'Closed'].map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </Form.Select>
            {submitted && !newVal.status ? (
              <p className='text-danger error-message'>{t('shared.statusRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.owner')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='owner' value={newVal.owner} onChange={(e) => onChange(e)}>
              <option>{t('shared.choose_option')}</option>
              {investors &&
                investors.map((investor, index) => (
                  <option key={index} value={investor.id}>
                    {investor.name}
                  </option>
                ))}
            </Form.Select>
            {submitted && !newVal.owner ? (
              <p className='text-danger error-message'>{t('investorEditCompany.errors.owner')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.allowedEnterpriseLimitLabel')}</Form.Label>
            <Form.Control
              name='allowed_investee_limit'
              type='number'
              value={newVal.allowed_investee_limit}
              onChange={(e) => onChange(e)}
            />
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button className='primary-button cancel-button' onClick={closeModal}>
              {t('misc.cancel')}
            </button>
            <button className='primary-button content-right' onClick={onSave}>
              {t('misc.save')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreatePortfolio;
