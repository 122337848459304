import React from 'react';
import { useTranslation } from 'react-i18next';

function DisplayCompanyInfo(props) {
  const { t } = useTranslation();
  let { investee, companySize, naics, currencies, mainContact, setIsEdit } = props;

  const displayCompanySize = (size) => {
    if (size || size === 0) return companySize[size];
    else return t('shared.not_selected');
  };

  const displayNaics = (val) => {
    if (val) return naics[val];
    else return t('shared.not_selected');
  };

  const displayCurrency = (cur) => {
    if (cur) return currencies[cur];
    else return t('shared.not_selected');
  };

  const displayLogo = (logo) => {
    if (logo) return <img src={investee.investee_logo} width='150px' height='100px' />;
    else return t('shared.logo_not_added');
  };

  const displayContact = (user) => {
    if (user) {
      return (
        <table className='main-contact-style' border='1'>
          <thead>
            <tr>
              <th>{t('shared.first_name')}</th>
              <th>{t('shared.last_name')}</th>
              <th>{t('shared.email')}</th>
              <th>{t('shared.phone_number')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.email}</td>
              <td>{user.phone_number}</td>
            </tr>
          </tbody>
        </table>
      );
    } else return t('shared.not_defined');
  };

  return (
    <>
      <div className='d-sm-flex align-items-center justify-content-between page-content'>
        <div className='info-question'>{t('shared.company_size')}</div>
        <button className='primary-button' onClick={() => setIsEdit(true)}>
          <i className='fas fa-fw fa-edit mr-point2'></i>
          <span>{t('misc.edit')}</span>
        </button>
      </div>
      <div className='info-answer'>{displayCompanySize(investee.company_size)}</div>
      <div className='info-question'>{t('shared.primaryActivity')}</div>
      <div className='info-answer'>{displayNaics(investee.naics_category_1)}</div>
      <div className='info-question'>{t('shared.secondaryActivity')}</div>
      <div className='info-answer'>{displayNaics(investee.naics_category_2)}</div>
      <div className='info-question'>{t('shared.fiscal_year_end_date')}</div>
      <div className='info-answer'>{investee.fiscal_year_end ? investee.fiscal_year_end : t('shared.not_given')}</div>
      <div className='info-question'>{t('shared.mainCurrency')}</div>
      <div className='info-answer'>{displayCurrency(investee.currency)}</div>
      <div className='info-question'>{t('shared.financialSituation')}</div>
      <div>
        {t('shared.annual_revenue')}: {investee.annual_revenue}
      </div>
      <div>
        {t('shared.ebitda')}: {investee.ebitda}
      </div>
      <div className='info-answer'>
        {t('shared.netIncome')}: {investee.net_income}
      </div>
      <div className='info-question'>{t('shared.logo')}</div>
      <div className='info-answer'>{displayLogo(investee.investee_logo)}</div>
      <div className='info-question'>{t('shared.mainContact')}</div>
      <div className='info-answer'>{displayContact(mainContact)}</div>
      <div></div>
    </>
  );
}

export default DisplayCompanyInfo;
