import React from 'react';
import Goal1 from '../../images/dev-goals/goal1.png';
import Goal2 from '../../images/dev-goals/goal2.png';
import Goal3 from '../../images/dev-goals/goal3.png';
import Goal4 from '../../images/dev-goals/goal4.png';
import Goal5 from '../../images/dev-goals/goal5.png';
import Goal6 from '../../images/dev-goals/goal6.png';
import Goal7 from '../../images/dev-goals/goal7.png';
import Goal8 from '../../images/dev-goals/goal8.png';
import Goal9 from '../../images/dev-goals/goal9.png';
import Goal10 from '../../images/dev-goals/goal10.png';
import Goal11 from '../../images/dev-goals/goal11.png';
import Goal12 from '../../images/dev-goals/goal12.png';
import Goal13 from '../../images/dev-goals/goal13.png';
import Goal14 from '../../images/dev-goals/goal14.png';
import Goal15 from '../../images/dev-goals/goal15.png';
import Goal16 from '../../images/dev-goals/goal16.png';
import Goal17 from '../../images/dev-goals/goal17.png';
import { useTranslation } from 'react-i18next';

function DevelopmentGoals(props) {
  const { t } = useTranslation();
  let tags = props.tags;

  function displayTags() {
    return (
      tags &&
      tags.map((tag, index) => {
        switch (tag) {
          case '1':
            return <img key={index} src={Goal1} className='dev-goals-img' />;
          case '2':
            return <img key={index} src={Goal2} className='dev-goals-img' />;
          case '3':
            return <img key={index} src={Goal3} className='dev-goals-img' />;
          case '4':
            return <img key={index} src={Goal4} className='dev-goals-img' />;
          case '5':
            return <img key={index} src={Goal5} className='dev-goals-img' />;
          case '6':
            return <img key={index} src={Goal6} className='dev-goals-img' />;
          case '7':
            return <img key={index} src={Goal7} className='dev-goals-img' />;
          case '8':
            return <img key={index} src={Goal8} className='dev-goals-img' />;
          case '9':
            return <img key={index} src={Goal9} className='dev-goals-img' />;
          case '10':
            return <img key={index} src={Goal10} className='dev-goals-img' />;
          case '11':
            return <img key={index} src={Goal11} className='dev-goals-img' />;
          case '12':
            return <img key={index} src={Goal12} className='dev-goals-img' />;
          case '13':
            return <img key={index} src={Goal13} className='dev-goals-img' />;
          case '14':
            return <img key={index} src={Goal14} className='dev-goals-img' />;
          case '15':
            return <img key={index} src={Goal15} className='dev-goals-img' />;
          case '16':
            return <img key={index} src={Goal16} className='dev-goals-img' />;
          case '17':
            return <img key={index} src={Goal17} className='dev-goals-img' />;
          default:
            return t('shared.noGoals');
        }
      })
    );
  }

  return (
    <div className='d-sm-flex align-items-center justify-content-between company-content'>
      <div className='padding-left padding-right' style={{ width: '80%' }}>
        <h5>{t('shared.developmentGoals')}</h5>
        <p>{displayTags()}</p>
      </div>
      <div style={{ width: '40%' }}></div>
    </div>
  );
}

export default DevelopmentGoals;
