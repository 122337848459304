import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from '../../../images/svg-icons/delete.svg';
import { useTranslation } from 'react-i18next';

function DeleteInvestorCompany(props) {
  const { t } = useTranslation();
  let { button, investor, newVal } = props;
  let { setNewVal, onSubmit } = props;
  const [showModal, setShowModal] = useState(false);

  const displayModal = () => {
    setShowModal(true);
    setNewVal({
      id: investor.id,
      name: investor.name,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const submitDeleteRequest = () => {
    onSubmit();
    closeModal();
    setNewVal({
      id: '',
      name: '',
    });
  };

  return (
    <>
      {button ? (
        <button className='primary-button-outlined mr-point5' onClick={displayModal}>
          <DeleteIcon className='svg-icon fill-black' />
          <span>{t('shared.delete')}</span>
        </button>
      ) : (
        <a onClick={displayModal}>
          <DeleteIcon className='svg-icon delete-modal' />
        </a>
      )}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='no-padding-top text-center'>
          {t('shared.confirmDeleteMessage')} <span className='primary'>{newVal.name}</span> ?
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.no')}
            </button>
            <button onClick={submitDeleteRequest} className='primary-button content-right'>
              {t('misc.yes')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteInvestorCompany;
