import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import Report from '../../Shared/ReportAction/Report';
import Notification from '../Shared/Notification';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestees, loadOxiaAdminDataSummary } from '../../../actions/auth';
import { Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import { useTranslation } from 'react-i18next';

function Dashboard(props) {
  const { t } = useTranslation();
  let { user, investors, investees, dataSummary } = props;
  let { loadUser, loadInvestors, loadInvestees, loadOxiaAdminDataSummary } = props;

  useEffect(() => {
    localStorage.removeItem('reportRequestId');
    localStorage.removeItem('questionsInfo');
    localStorage.removeItem('reportProductId');
    localStorage.removeItem('formId');
  }, []);

  // const formatInvestmentAmount = (amount) => {
  //   if (amount < 1e3) return amount
  //   if (amount >= 1e3 && amount < 1e6) return +(amount / 1e3).toFixed(1) + "K"
  //   if (amount >= 1e6 && amount < 1e9) return +(amount / 1e6).toFixed(1) + "M"
  //   if (amount >= 1e9 && amount < 1e12) return +(amount / 1e9).toFixed(1) + "B"
  //   if (amount >= 1e12) return +(amount / 1e12).toFixed(1) + "T"
  // }

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!dataSummary) {
    loadOxiaAdminDataSummary();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    return <Navigate to='/terms-of-agreement' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row padding-top'>
                <div className='col-12'>
                  <img src={line} className='heading-line' />
                  <span className='header-2 padding-left'>Home</span>
                </div>
              </div>
              <div className='row mt-1 padding-left'>
                <div className='col-md-3 stretch-card'>
                  <div className='card card-primary'>
                    <NavLink to='/superadmin/investor-companies'>
                      <div className='card-body'>
                        <h3>{dataSummary.investors}</h3>
                        <p>{t('shared.aggregators')}</p>
                      </div>
                    </NavLink>
                  </div>
                </div>
                <div className='col-md-3 stretch-card'>
                  <div className='card card-secondary'>
                    <NavLink to='/superadmin/investee-companies'>
                      <div className='card-body'>
                        <h3>{dataSummary.investees}</h3>
                        <p>{t('shared.enterprises')}</p>
                      </div>
                    </NavLink>
                  </div>
                </div>
                <div className='col-md-3 stretch-card'>
                  <div className='card card-primary'>
                    <NavLink
                      className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                      to='/superadmin/portfolios'
                    >
                      <div className='card-body'>
                        <h3>{dataSummary.portfolios}</h3>
                        <p>Portfolios</p>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <hr className='divider' />
              <div className='row'>
                <Report isAdmin={true} />
                <Notification />
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
  dataSummary: state.auth.oxiaAdminDataSummary,
});

export default connect(mapStateToProps, { loadUser, loadInvestors, loadInvestees, loadOxiaAdminDataSummary })(
  Dashboard,
);
