import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/oxia-initiative-logo.png';
import { Container, Row, Col, Card, Form, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { confirmChangePassword } from '../../../actions/auth';
import { setAlert, removeAlert } from '../../../actions/alert';
import { useTranslation } from 'react-i18next';

function ResetPassword(props) {
  // eslint-disable-next-line no-undef
  document.body.style.backgroundColor = '#F8F8FF';
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    password: '',
    retypePassword: '',
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    setShowErrorMessage(false);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (props.errors && props.errors.length) {
      setErrorMessage(props.errors[0].msg);
    }
  });

  const handleSubmit = async (event) => {
    if (formData.password === formData.retypePassword) {
      if (formData.password === 'testing123') {
        setShowErrorMessage(true);
        setErrorMessage(t('shared.password_common'));
      } else if (formData.password.length < 8) {
        setShowErrorMessage(true);
        setErrorMessage(t('shared.password_short'));
      } else {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        event.preventDefault();

        props
          .confirmChangePassword(token, formData.password)
          .then((val) => {
            setShowSuccessMessage(val === true);
            setShowErrorMessage(val !== true);
          })
          .catch((err) => console.log(err));
      }
    } else {
      setShowErrorMessage(true);
      setErrorMessage(t('shared.password_mismatch'));
    }
  };

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col xl={4} lg={4} md={4}>
          <div className='authentication'>
            <a href='/'>
              <img src={logo} className='authentication-logo mx-auto d-block' />
            </a>
            <Card className='authentication-card'>
              <Card.Body>
                <Card.Title>{t('shared.new_password')}</Card.Title>
                {showErrorMessage ? (
                  <div className='text-danger text-center' dangerouslySetInnerHTML={{ __html: errorMessage }} />
                ) : (
                  ''
                )}
                {showSuccessMessage ? (
                  <Alert variant='light'>{t('shared.successfully_changed_password')}</Alert>
                ) : (
                  <>
                    <Alert variant='secondary'>
                      {t('shared.protect_account')}
                      <ul>
                        <li>{t('shared.at_least_8_characters')}</li>
                        <li>{t('shared.not_common_passwords')}</li>
                      </ul>
                    </Alert>
                    <Form.Group>
                      <Form.Label>{t('shared.new_password')}</Form.Label>
                      <Form.Control
                        name='password'
                        type='password'
                        value={formData.password}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('shared.retype_new_password')}</Form.Label>
                      <Form.Control
                        name='retypePassword'
                        type='password'
                        value={formData.retypePassword}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <button className='primary-button mt-1' onClick={handleSubmit}>
                      {t('shared.confirm')}
                    </button>
                  </>
                )}
                <div className='text-footer'>
                  {t('shared.haveAnAccount')} <Link to='/login'>{t('shared.log_in')}</Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
  errors: state.alert.errors,
});

export default connect(mapStateToProps, { confirmChangePassword, removeAlert, setAlert })(ResetPassword);
