import React from 'react';
import { NavLink } from 'react-router-dom';
// import facebook from "../../../images/facebook.png";
// import linkedin from "../../../images/linkedin.png";
import { ReactComponent as Dashboard } from '../../../images/svg-icons/dashboard.svg';
import { ReactComponent as Bag } from '../../../images/svg-icons/bag.svg';
import { ReactComponent as FolderOpen } from '../../../images/svg-icons/folder-open.svg';
import { ReactComponent as FileAlt } from '../../../images/svg-icons/file-alt.svg';
import { ReactComponent as Building } from '../../../images/svg-icons/building.svg';
import { useTranslation } from 'react-i18next';

function Sidebar(props) {
  const { t } = useTranslation();
  return (
    <nav className='sidebar' id='sidebar'>
      <ul className='nav dashboard-sidebar'>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/'>
            <Dashboard className='svg-icon mr-1' />
            <span>{t('misc.home')}</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/investor/company-name'>
            <Bag className='svg-icon mr-1' />
            {props.investor && props.investor.name.length > 12 ? (
              <div>
                <span>
                  {props.investor.name.slice(
                    0,
                    props.investor.name.lastIndexOf(' ', Math.ceil(props.investor.name.length)),
                  )}
                </span>
                <br />
                <span>
                  {props.investor.name.slice(
                    props.investor.name.lastIndexOf(' ', Math.ceil(props.investor.name.length)) + 1,
                  )}
                </span>
              </div>
            ) : (
              <span>{props.investor ? props.investor.name : 'Company Name'}</span>
            )}
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/investor/portfolios'>
            <FolderOpen className='svg-icon mr-1' />
            <span>{t('misc.portfolios')}</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className='nav-link' to='/investor/kpis'>
            <FileAlt className='svg-icon mr-1' />
            <span>{t('misc.dashboard')}</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className='nav-link' to='/investor/investee-companies'>
            <Building className='svg-icon mr-1' />
            <span>
              {props.investor.aggregator_type === 'Consultant' ? t('shared.enterprises') : t('misc.investee')}
            </span>
          </NavLink>
        </li>
      </ul>
      {/* <ul className="nav sidebar-footer">
        <li className="nav-item">
          <img src={facebook} className="social-icon" />
          <span>Facebook</span>
        </li>
        <li className="nav-item">
          <img src={linkedin} className="social-icon" />
          <span>Linkedin</span>
        </li>
      </ul> */}
    </nav>
  );
}

export default Sidebar;
