import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, InputGroup, FormControl, Button } from 'react-bootstrap';
import logo from '../../../images/oxia-initiative-logo.png';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, loadUser } from '../../../actions/auth';
import { setAlert, removeAlert } from '../../../actions/alert';
import { ReactComponent as UserIcon } from '../../../images/svg-icons/user.svg';
import { ReactComponent as Unlock } from '../../../images/svg-icons/unlock.svg';
import { useTranslation } from 'react-i18next';

function Login(props) {
  // eslint-disable-next-line no-undef
  document.body.style.backgroundColor = '#F8F8FF';

  const { t, i18n } = useTranslation();
  const current_language = i18n.language;

  const [message, setMessage] = useState('');
  const [formData, setForm] = useState({
    username: '',
    password: '',
  });
  const { username, password } = formData;

  useEffect(() => {
    if (props.errors && props.errors.length) {
      setMessage(props.errors[0].msg);
    }
  });

  useEffect(() => {
    if (localStorage.token) {
      localStorage.removeItem('token');
      localStorage.removeItem('userType');
      localStorage.removeItem('investor');
      localStorage.removeItem('consultant');
      localStorage.removeItem('enterprise');
      localStorage.removeItem('investee');
      localStorage.removeItem('reportRequestId');
      localStorage.removeItem('questionsInfo');
    }
  }, []);

  const handleChange = (event) => {
    setForm({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.login(username, password);
  };

  if (props.isAuthenticated === true && props.user != null) {
    // eslint-disable-next-line no-undef
    document.body.style.backgroundColor = '#FFFFFF';
    document.body.classList.add(('user-type-' + props.user.type).toLowerCase().replaceAll(' ', '-'));

    if (props.user.status === 'Pending') {
      let termsOfAgreementRoute = `/terms-of-agreement/${current_language}`;
      return <Navigate to={termsOfAgreementRoute} />;
    } else return <Navigate to='/' />;
  }

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col xl={4} lg={4} md={4}>
          <div className='authentication'>
            <a href='/'>
              <img src={logo} className='authentication-logo mx-auto d-block' />
            </a>
            <Card className='authentication-card'>
              <Card.Body>
                <Card.Title>{t('shared.log_in')}</Card.Title>
                <p className='text-danger'>{message}</p>
                <form className='login' onSubmit={handleSubmit}>
                  <InputGroup>
                    <InputGroup.Text id='username'>
                      <UserIcon />
                    </InputGroup.Text>
                    <FormControl
                      name='username'
                      onChange={handleChange}
                      type='text'
                      value={username}
                      placeholder={t('shared.email_placeholder')}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroup.Text id='password'>
                      <Unlock />
                    </InputGroup.Text>
                    <FormControl
                      name='password'
                      onChange={handleChange}
                      type='password'
                      value={password}
                      placeholder={t('shared.password_placeholder')}
                    />
                  </InputGroup>
                  <Button type='submit'>{t('shared.log_in')}</Button>
                  <div className='text-footer'>
                    <Link to='/forgot-password'>{t('shared.forgot_password')}</Link>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
  errors: state.alert.errors,
});

export default connect(mapStateToProps, { login, loadUser, removeAlert, setAlert })(Login);
