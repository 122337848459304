import React from 'react';
import { Form, Accordion } from 'react-bootstrap';

class PreviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      repeatField: 1,
    };
    this.addAnswerField = this.addAnswerField.bind(this);
  }

  displayMultipleRows() {
    let { answerList } = this.props;
    let { repeatField } = this.state;

    return [...Array(repeatField)].map((e, i) => (
      <div key={i} className='mt-1 mb-1'>
        {answerList.answerField.map((item, index) => (
          <div className='mt-1 mb-1' key={index}>
            {item.dataType === 'Text' && item.textInfo === 'Long Text' ? (
              <div className='row'>
                <label className='col-md-4'>{item.labelEn}</label>
                <textarea className='col-md-8' name='name' type='text' rows='3' />
              </div>
            ) : item.dataType === 'DDL' ? (
              <div className='row'>
                <label className='col-md-6'>{item.labelEn}</label>
                <select name='name' className='col-md-6'>
                  <option>Choose option</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                </select>
              </div>
            ) : item.dataType === 'Boolean' && item.booleanInfo === 'Yes/No' ? (
              <Form.Group>
                <Form.Check type='radio' label='Yes' />
                <Form.Check type='radio' label='No' />
              </Form.Group>
            ) : item.dataType === 'Boolean' && item.booleanInfo === 'Checkbox' ? (
              <Form.Group>
                <Form.Check type='checkbox' label={item.labelEn} />
              </Form.Group>
            ) : item.dataType === 'Reference' ? (
              <div className='row'>
                <label className='col-md-6'>Reference</label>
                <input type='text' name='name' className='col-md-6' />
              </div>
            ) : (
              <div className='row'>
                <label className='col-md-6'>{item.labelEn}</label>
                <input type='text' name='name' className='col-md-6' />
              </div>
            )}
          </div>
        ))}
      </div>
    ));
  }

  displayMultipleColumns() {
    let { answerList } = this.props;
    let { repeatField } = this.state;

    return (
      <table className='table table-bordered'>
        <thead>
          <tr>
            {answerList.answerField.map((item, index) =>
              item.dataType === 'Reference' ? <th key={index}>Reference</th> : <th key={index}>{item.labelEn}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          {[...Array(repeatField)].map((e, i) => (
            <tr key={i}>
              {answerList.answerField.map((item, index) =>
                item.dataType === 'Text' && item.textInfo === 'Long Text' ? (
                  <td key={index}>
                    <textarea name='name' type='text' rows='3' />
                  </td>
                ) : item.dataType === 'DDL' ? (
                  <td key={index}>
                    <Form.Select name='name'>
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </Form.Select>
                  </td>
                ) : item.dataType === 'Boolean' && item.booleanInfo === 'Yes/No' ? (
                  <td key={index}>
                    <Form.Group>
                      <Form.Check type='radio' label='Yes' />
                      <Form.Check type='radio' label='No' />
                    </Form.Group>
                  </td>
                ) : item.dataType === 'Boolean' && item.booleanInfo === 'Checkbox' ? (
                  <td key={index}>
                    <Form.Group>
                      <Form.Check type='checkbox' label={item.labelEn} />
                    </Form.Group>
                  </td>
                ) : (
                  <td key={index}>
                    <input type='text' id='name' name='name'></input>
                  </td>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  addAnswerField() {
    let { repeatField } = this.state;
    repeatField++;
    this.setState({
      repeatField: repeatField,
    });
  }

  render() {
    let { questionList, answerList } = this.props;
    return (
      <div className='row justify-content-md-center padding-top padding-bottom'>
        <div className='col-8'>
          <div className='d-sm-flex align-items-center justify-content-between pt-2'>
            <p className='text-default'>
              {Object.entries(questionList).length ? questionList.title_en : '<< title_en >>'}
            </p>
          </div>
          <div className='padding-top'>
            <p className='header-2'>{Object.entries(questionList).length ? questionList.stem_en : '<< stem_en >>'}</p>
          </div>
          <div className='mt-1'>
            {answerList.labelIsHeader === 'Yes' ? (
              <>
                {this.displayMultipleColumns()}
                <button className='btn btn-info text-white' onClick={this.addAnswerField}>
                  Add Answer Row
                </button>
              </>
            ) : (
              ''
            )}
            {answerList.labelIsHeader === 'No' ? (
              <>
                {this.displayMultipleRows()}
                <button className='btn btn-info text-white' onClick={this.addAnswerField}>
                  Add Answer Row
                </button>
              </>
            ) : (
              ''
            )}
          </div>
          <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
            <button className='cancel-button'>
              <i className='fas fa-fw fa-arrow-left mr-point2'></i>
              <span>Prev</span>
            </button>
            <span>
              <button className='primary-button-outlined mr-point5'>Save and exit</button>
              <button className='primary-button'>Save and continue</button>
            </span>
          </div>
          <Accordion defaultActiveKey='1' className='padding-top'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Explanation</Accordion.Header>
              <Accordion.Body>
                {Object.entries(questionList).length ? questionList.explanation_text_en : '<< explanation_text_en >>'}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>Example</Accordion.Header>
              <Accordion.Body>
                {Object.entries(questionList).length ? questionList.examples_text_en : '<< examples_text_en >>'}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default PreviewPage;
