import React from 'react';
import SASidebar from '../SuperAdmin/Shared/Sidebar';
import Header from './Header';
import defaultImage from '../../images/default-image.png';
import { ReactComponent as EditIcon } from '../../images/svg-icons/edit.svg';
import line from '../../images/line.png';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function UserDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  let user = location.state.user;

  return (
    <div className='container-scroller'>
      <Header />
      <div className='container-fluid page-body-wrapper'>
        <SASidebar />
        <div className='main-panel'>
          <div className='content-wrapper'>
            <div className='row page-title'>
              <div className='d-sm-flex align-items-center justify-content-between page-content padding-top padding-right'>
                <span>
                  <img src={line} className='heading-line' />
                  <span className='header-2 padding-left'>
                    {user.first_name} {user.last_name}
                  </span>
                </span>
                <button className='primary-button-outlined'>
                  <EditIcon className='svg-icon mr-point2' />
                  <span>{t('misc.edit')}</span>
                </button>
              </div>
            </div>
            <div className='row justify-content-md-center padding-left'>
              <div className='page-content text-center'>
                <img src={defaultImage} className='investor-profile-img' />
                <p className='text-muted'>{user.role ? user.role : t('shared.defaultRole')}</p>
                <h3>
                  {user.first_name} {user.last_name}
                </h3>
                <p className='primary'>{user.email}</p>
              </div>
              <div className='col-md-8 col-md-2-offset'>
                <table className='table main2'>
                  <tbody>
                    <tr className='table-line'>
                      <td className='fw-bold vertical-content'>{t('shared.addressLine1')}</td>
                      <td className='vertical-content'>{user.address_line1}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold vertical-content'>{t('shared.addressLine2')}</td>
                      <td className='vertical-content'>{user.address_line2}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold vertical-content'>{t('shared.city').toUpperCase()}</td>
                      <td className='vertical-content'>{user.city}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold vertical-content'>{t('shared.zip')}</td>
                      <td className='vertical-content'>{user.zip_code}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold vertical-content'>{t('shared.region').toUpperCase()}</td>
                      <td className='vertical-content'>{user.region}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold vertical-content'>{t('shared.country').toUpperCase()}</td>
                      <td className='vertical-content'>{user.country}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold vertical-content'>{t('shared.phone').toUpperCase()}</td>
                      <td className='vertical-content'>{user.phone_number}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
