import React from 'react';
import { Form, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { getDevelopmentGoals } from '../../../services/getDevelopmentGoals';
import { useTranslation } from 'react-i18next';

function CreateInvestees(props) {
  const { t } = useTranslation();
  let { emptyInvestee, investees, submitted, isError, message, showModal } = props;
  let { onChange, onSubmit, setInvestees, setIsError, setShowModal, setSubmitted } = props;
  let options = getDevelopmentGoals();

  const displayModal = () => {
    setShowModal(true);
    setInvestees([emptyInvestee]);
  };

  const closeModal = () => {
    setShowModal(false);
    setIsError(false);
    setSubmitted(false);
  };

  const handleAddRow = () => {
    setInvestees([...investees, emptyInvestee]);
  };

  const handleRemoveRow = () => {
    if (investees.length < 2) return;
    const updatedInvestees = [...investees];
    updatedInvestees.pop();
    setInvestees(updatedInvestees);
  };

  const handleGoalOptions = (index, options) => {
    let goalValues = [];
    for (let i of options) {
      goalValues.push(i.value);
    }
    const updatedInvestees = [...investees];
    updatedInvestees[index].development_goals = goalValues.toString();
    setInvestees(updatedInvestees);
  };

  return (
    <>
      <button className='primary-button' onClick={displayModal}>
        <i className='fas fa-fw fa-plus mr-point2'></i>
        <span>{t('investorDashboard.addEnterprise')}</span>
      </button>
      <Modal show={showModal} onHide={closeModal} fullscreen={true} centered className='multiple-investees'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('investorDashboard.addInvestee')}</Modal.Title>
        {isError ? (
          <Alert variant='danger' className='mt-1 ml-1 mr-1 mb-auto'>
            {message}
          </Alert>
        ) : (
          ''
        )}
        <Modal.Body className='align-self-center w-100 create-investees'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>
                  {t('shared.company_name')} <span className='text-danger'>*</span>
                </th>
                <th className='text-center'>
                  {t('shared.company_email')} <span className='text-danger'>*</span>
                </th>
                <th className='text-center'>{t('shared.description')}</th>
                <th className='text-center'>{t('shared.investmentAmountMaj')}</th>
                <th className='text-center'>
                  {t('shared.developmentGoals')} <span className='text-danger'>*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {investees.map((investee, index) => (
                <tr key={index}>
                  <td style={{ width: '15%' }}>
                    <Form.Control name='name' value={investee.name} onChange={(event) => onChange(index, event)} />
                    {submitted && !investee.name ? (
                      <p className='text-danger error-message'>{t('shared.company_name_required')}</p>
                    ) : (
                      <p className='error-message'>&nbsp;</p>
                    )}
                  </td>
                  <td style={{ width: '15%' }}>
                    <Form.Control
                      name='company_email'
                      value={investee.company_email}
                      onChange={(event) => onChange(index, event)}
                    />
                    {submitted && !investee.company_email ? (
                      <p className='text-danger error-message'>{t('shared.company_email_error')}</p>
                    ) : (
                      <p className='error-message'>&nbsp;</p>
                    )}
                  </td>
                  <td style={{ width: '13%' }}>
                    <Form.Control
                      name='description'
                      as='textarea'
                      style={{ height: '41.19px', paddingLeft: '10px' }}
                      rows={1}
                      value={investee.description}
                      onChange={(event) => onChange(index, event)}
                    />
                    {submitted && investee.description.length > 500 ? (
                      <p className='text-danger error-message'>{t('shared.descriptionValidation')}</p>
                    ) : (
                      <p className='error-message'>&nbsp;</p>
                    )}
                  </td>
                  <td style={{ width: '10%' }}>
                    <Form.Control
                      name='investment_amount'
                      type='number'
                      value={investee.investment_amount}
                      onChange={(event) => onChange(index, event)}
                    />
                  </td>
                  <td style={{ width: '40%' }}>
                    <Select isMulti options={options} onChange={(options) => handleGoalOptions(index, options)} />
                    {submitted && !investee.development_goals ? (
                      <p className='text-danger error-message'>{t('shared.developmentGoals')}</p>
                    ) : (
                      <p className='error-message'>&nbsp;</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='mb-2 d-flex justify-content-between align-items-center'>
            <div>
              <button className='btn btn-addRow text-white mr-1' onClick={handleAddRow}>
                {t('shared.add_row')}
              </button>
              <button className='btn btn-removeRow text-white' onClick={handleRemoveRow}>
                {t('shared.remove_row')}
              </button>
            </div>
            <div>
              <button onClick={closeModal} className='btn primary-button cancel-button mr-1'>
                {t('misc.cancel')}
              </button>
              <button onClick={onSubmit} className='btn primary-button content-right'>
                {t('misc.save')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateInvestees;
