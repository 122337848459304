import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ListGroup, Spinner, Modal } from 'react-bootstrap';
import FilterGraphView from './FilterGraphView';
import { loadReportingPeriods, loadReportStatuses } from '../../../actions/auth';
import car from '../../../images/car.png';
import house from '../../../images/house.png';
import API from '../../../api';
import { useTranslation } from 'react-i18next';

function GraphView(props) {
  const { t, i18n } = useTranslation();

  let { reportingPeriods, reportStatuses, businessEntity } = props;
  let { loadReportingPeriods, loadReportStatuses } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [graphList, setGraphList] = useState([]);
  let [products, setProducts] = useState([]);
  let [isSelected, setIsSelected] = useState(false);
  let [emptyGraph, setEmptyGraph] = useState(false);
  let [currentPage] = useState(1);
  let [itemsPerPage] = useState(5);
  let [distanceByCar, setDistanceByCar] = useState(0);
  let [houseHolds, setHouseHolds] = useState(0);
  let [showModal, setShowModal] = useState(false);
  let [textForShow, setTextForShow] = useState('');
  let [modalTitle, setModalTitle] = useState('');
  const currentLanguage = i18n.language;
  const titleKey = `title_kpi_${currentLanguage}`;
  const aboutKey = `about_kpi_graphs_${currentLanguage}`;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const fetchGraphs = () => {
    setGraphList([]);
  };

  const about_Last_Segment = {
    english:
      ' These figures provide a meaningful perspective on emissions, offering a sense of scale through equivalent factors that represent common sources of greenhouse gas (GHG) emissions. The first figure, "Distance Traveled by Car (per 1,000 km)," represents the equivalent distance an average-sized car with one passenger would need to travel to emit the same amount of GHGs. The conversion factor is derived from Statista (2018). The second figure, "Canadian Households," corresponds to the emissions equivalent of a Canadian household with one person for one year. The conversion factor is sourced from Statistics Canada (2023).',
    french:
      "Ces chiffres apportent une perspective significative sur les émissions, offrant une idée de l'ampleur grâce à des facteurs équivalents représentant des sources courantes d'émissions de gaz à effet de serre (GES). Le premier chiffre, \"Distance Parcourue en Voiture (pour 1 000 km),\" représente l'équivalence avec la distance qu'une voiture de taille moyenne avec un passager devrait parcourir pour émettre la même quantité de GES. Le facteur de conversion provient de Statista (2018). La deuxième figure, \"Ménages Canadiens,\" correspond à l'équivalence des émissions d'un ménage canadien composé d'une personne pendant un an. Le facteur de conversion est tiré de Statistique Canada (2023).",
  };
  const fetchTravelledDistanceandCanadianHouseholds = () => {
    API.get(`api/kpi_image/${businessEntity.id}`, { headers })
      .then((response) => {
        setDistanceByCar(response.data[0]);
        setHouseHolds(response.data[1]);
      })
      .catch(function () {
        console.log('Error while loading graphs.');
      });
  };
  const displayModal = (title, text) => {
    setModalTitle(title);
    setTextForShow(text);
    setShowModal(true);
  };
  const displayModalForLastSegment = (lang) => {
    if (lang == 'en') {
      setTextForShow(about_Last_Segment['english']);
      setModalTitle('Contextualizing Your Carbon Footprint');
    } else {
      setTextForShow(about_Last_Segment['french']);
      setModalTitle('Équivalences de votre Empreinte Carbone');
    }
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchGraphs();
    API.get(`api/kpi_condition/${businessEntity.id}`, { headers })
      .then((response) => {
        setProducts(response.data);
      })
      .catch(function () {
        console.log('Error while loading graphs.');
      });
    fetchTravelledDistanceandCanadianHouseholds();
  }, []);

  const displayGraphList = (graphs) => {
    let backendURL = process.env.REACT_APP_API_SERVER;
    return (
      <div className='mt-1 kpi-wrapper'>
        <ListGroup>
          {isSelected ? (
            graphs && graphs.length > 0 ? (
              <>
                {graphs.map((graph, index) => (
                  <ListGroup.Item key={index}>
                    <div className='d-sm-flex align-items-center justify-content-between'>
                      <div>
                        <div className='kpi-graph' key={index}>
                          <img src={backendURL + graph.file_path} alt={`Graph ${index}`} />
                          <button
                            className='circle-button'
                            onClick={() => displayModal(graph[titleKey], graph[aboutKey])}
                          >
                            i
                          </button>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
                <Modal show={showModal} onHide={closeModal} centered>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Title>{modalTitle}</Modal.Title>
                  <Modal.Body>
                    <p>{textForShow}</p>
                  </Modal.Body>
                </Modal>
                <div className='kpi-last-segment'>
                  <button className='circle-button' onClick={() => displayModalForLastSegment(currentLanguage)}>
                    i
                  </button>
                  <div className='card-wrapper'>
                    <div className='card'>
                      <h1>{distanceByCar}</h1>
                      <div className='card-content'>
                        <h2 className='card-title'>Distance travelled by Car (per 1,000 km)</h2>
                      </div>
                    </div>
                    <img src={car} />
                  </div>
                  <div className='card-wrapper'>
                    <div className='card'>
                      <h1>{houseHolds}</h1>
                      <div className='card-content'>
                        <h2 className='card-title'>Canadian households</h2>
                      </div>
                    </div>
                    <img src={house} />
                  </div>
                </div>
              </>
            ) : null
          ) : (
            <ListGroup.Item>{t('shared.needToSelect')}</ListGroup.Item>
          )}
          {emptyGraph && <ListGroup.Item>{t('shared.noGraphsAvailable')}</ListGroup.Item>}
        </ListGroup>
      </div>
    );
  };

  if (!reportingPeriods) {
    loadReportingPeriods();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!reportStatuses) {
    loadReportStatuses();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    const currentItems = graphList.slice(indexOfFirstItem, indexOfLastItem);
    return (
      <div>
        <FilterGraphView
          headers={headers}
          reportingPeriods={reportingPeriods}
          products={products}
          setGraphList={setGraphList}
          fetchGraphs={fetchGraphs}
          businessEntity={businessEntity}
          setIsSelected={setIsSelected}
          setEmptyGraph={setEmptyGraph}
        />
        {displayGraphList(currentItems)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reportingPeriods: state.auth.reportingPeriods,
  reportStatuses: state.auth.reportStatuses,
});

export default connect(mapStateToProps, { loadReportingPeriods, loadReportStatuses })(GraphView);
