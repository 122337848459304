import { SET_ALERT, REMOVE_ALERT } from '../actions/types';

const initialState = {
  errorPresent: false,
  errors: [], // [ { msg: '', type: '', id: '' } ]
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log('Reduce');

  switch (type) {
    case SET_ALERT:
      return {
        ...state,
        errors: [...state.errors, payload],
        errorPresent: true,
      };

    case REMOVE_ALERT:
      // eslint-disable-next-line no-case-declarations
      let newErrors = state.errors.filter((alert) => alert.id !== payload);
      // eslint-disable-next-line no-case-declarations
      let errorPresent = newErrors.lenth === 0 ? false : true;
      return {
        ...state,
        errors: newErrors,
        errorPresent: errorPresent,
      };

    default:
      return state;
  }
}
