import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { getDevelopmentGoals } from '../../../services/getDevelopmentGoals';
import { useTranslation } from 'react-i18next';

function CreateInvestor(props) {
  const { t } = useTranslation();
  let { newVal, submitted, showModal } = props;
  let { onChange, onSubmit, setShowModal, setNewVal, setSubmitted } = props;
  let options = getDevelopmentGoals();

  const handleOption = (options) => {
    let goalValues = [];
    for (let i of options) {
      goalValues.push(i.value);
    }
    setNewVal({
      ...newVal,
      development_goals: goalValues.toString(),
    });
  };
  const setSelectedOption = (options) => {
    setNewVal({
      ...newVal,
      aggregator_type: options.target.value,
    });
  };

  const displayModal = () => {
    setShowModal(true);
    setNewVal({
      id: '',
      name: '',
      company_email: '',
      description: '',
      development_goals: '',
      allowed_portfolio_limit: '',
      aggregator_type: '',
      total_allowed_investees_limit: '',
    });
  };

  const validateDescription = (desc) => {
    if (desc.length > 500) return true;
    else return false;
  };

  const closeModal = () => {
    setShowModal(false);
    setSubmitted(false);
  };

  return (
    <>
      <button className='primary-button' onClick={displayModal}>
        <i className='fas fa-fw fa-plus mr-point2'></i>
        <span>{t('shared.add_aggregator')}</span>
      </button>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('shared.add_aggregator')}</Modal.Title>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.company_name')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control name='name' value={newVal.name} onChange={(e) => onChange(e)} className='style-bottom' />
            {submitted && !newVal.name ? (
              <p className='text-danger error-message'>{t('shared.company_name_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.company_email')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='company_email'
              value={newVal.company_email}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.company_email ? (
              <p className='text-danger error-message'>{t('shared.company_email_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.description')}</Form.Label>
            <Form.Control
              name='description'
              as='textarea'
              rows={3}
              value={newVal.description}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && validateDescription(newVal.description) ? (
              <label className='text-danger'>{t('shared.descriptionValidation')}</label>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.developmentGoals')} <span className='text-danger'>*</span>
            </Form.Label>
            <Select isMulti options={options} onChange={(e) => handleOption(e)} className='development-goal-style' />
            {submitted && !newVal.development_goals ? (
              <p className='text-danger error-message'>{t('shared.developmentGoals')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.allowedPortfolioLimitLabel')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='allowed_portfolio_limit'
              type='number'
              value={newVal.allowed_portfolio_limit}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.allowed_portfolio_limit ? (
              <p className='text-danger error-message'>{t('shared.allowed_portfolio_limit_required')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {' '}
              {t('shared.aggregator_type')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select className='aggregator-type' name='aggregator_type' onChange={(e) => setSelectedOption(e)}>
              <option value='' disabled selected hidden>
                Select an option
              </option>
              <option value='Investor'> {t('shared.investor')} </option>
              <option value='Consultant'> {t('shared.consultant')} </option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.totalAllowedInvesteesLimitLabel')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='total_allowed_investees_limit'
              type='number'
              value={newVal.total_allowed_investees_limit}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.total_allowed_investees_limit ? (
              <p className='text-danger error-message'>{t('shared.total_allowed_investees_limit_required')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button onClick={onSubmit} className='primary-button content-right'>
              {t('misc.save')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateInvestor;
