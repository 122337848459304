import React from 'react';
import { NavLink } from 'react-router-dom';
// import facebook from "../../../images/facebook.png";
// import linkedin from "../../../images/linkedin.png";
import { ReactComponent as Dashboard } from '../../../images/svg-icons/dashboard.svg';
import { ReactComponent as Bag } from '../../../images/svg-icons/bag.svg';
import { ReactComponent as Users } from '../../../images/svg-icons/users.svg';
import { ReactComponent as FolderOpen } from '../../../images/svg-icons/folder-open.svg';
import { ReactComponent as FileContract } from '../../../images/svg-icons/file-contract-dollar.svg';
import { ReactComponent as Building } from '../../../images/svg-icons/building.svg';
import { useTranslation } from 'react-i18next';

function Sidebar() {
  const { t } = useTranslation();

  return (
    <nav className='sidebar' id='sidebar'>
      <ul className='nav dashboard-sidebar'>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/'>
            <Dashboard className='svg-icon mr-1' />
            <span>Home</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/superadmin/investor-companies'>
            <Bag className='svg-icon mr-1' />
            <span>{t('shared.aggregators')}</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/superadmin/investor-accounts'>
            <Users className='svg-icon mr-1' />
            <span>{t('shared.aggregatorMembers')}</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/superadmin/investee-companies'>
            <Building className='svg-icon mr-1' />
            <span>{t('shared.enterprises')}</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/superadmin/investee-accounts'>
            <FileContract className='svg-icon mr-1' />
            <span>{t('shared.enterpriseMembers')}</span>
          </NavLink>
        </li>
        <li className='nav-item'>
          <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/superadmin/portfolios'>
            <FolderOpen className='svg-icon mr-1' />
            <span>Portfolios</span>
          </NavLink>
        </li>
      </ul>

      {/* <ul className="nav sidebar-footer">
        <li className="nav-item">
          <img src={facebook} className="social-icon" />
          <span>Facebook</span>
        </li>
        <li className="nav-item">
          <img src={linkedin} className="social-icon" />
          <span>Linkedin</span>
        </li>
      </ul> */}
    </nav>
  );
}

export default Sidebar;
