import React, { useState, useEffect } from 'react';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestees } from '../../../actions/auth';
import DevelopmentGoals from '../../Shared/DevelopmentGoals';
import { Spinner } from 'react-bootstrap';
import CompaniesReport from '../../SuperAdmin/Shared/CompaniesReport';
import DisplayInvesteeUsers from '../../Shared/ShowAction/DisplayInvesteeUsers';
import DisplayInvesteeKpis from '../../Shared/ShowAction/DisplayInvesteeKpis';
import { useTranslation } from 'react-i18next';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';

function InvesteeDetails(props) {
  const { t } = useTranslation();
  let { user, investors, investees } = props;
  let { loadUser, loadInvestors } = props;
  const navigate = useNavigate();
  const location = useLocation();
  let investee = location.state.investee;
  let portfolio = location.state.portfolio;
  const storedValue = JSON.parse(localStorage.getItem('investor'));
  let [showReports, setShowReports] = useState(true);
  let [showInvesteeUsers, setShowInvesteeUsers] = useState(false);
  let [showKpis, setShowKpis] = useState(false);

  useEffect(() => {
    return () => {
      localStorage.removeItem('members');
    };
  }, []);

  const displayReport = () => {
    setShowReports(true);
    setShowInvesteeUsers(false);
    setShowKpis(false);
  };

  const displayInvestee = () => {
    setShowReports(false);
    setShowInvesteeUsers(true);
    setShowKpis(false);
  };

  const displayKpis = () => {
    setShowReports(false);
    setShowInvesteeUsers(false);
    setShowKpis(true);
  };

  const selectSpecificTab = () => {
    return (
      <div className='padding-left padding-top tabs'>
        {showReports ? (
          <button className='active-tab'>{t('misc.reports')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayReport}>
            {t('misc.reports')}
          </button>
        )}
        {showInvesteeUsers ? (
          <button className='active-tab'>{t('investorDashboard.members')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayInvestee}>
            {t('investorDashboard.members')}
          </button>
        )}
        {showKpis ? (
          <button className='active-tab'>{t('misc.dashboard')}</button>
        ) : (
          <button className='inactive-tab' onClick={displayKpis}>
            {t('misc.dashboard')}
          </button>
        )}
      </div>
    );
  };

  const displaySelectedTabDetails = (investor) => {
    return showInvesteeUsers ? (
      <DisplayInvesteeUsers
        investor={investor}
        investee={investee}
        portfolio={portfolio}
        idInvestee={investee.id}
        url='/investor/portfolios/portfolio-details/investee/member'
      />
    ) : showKpis ? (
      <DisplayInvesteeKpis investee={investee} />
    ) : (
      <CompaniesReport investees={investees} isAdmin={false} investee={investee} isInvestor={true} />
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  }  else {
    let investor = storedValue.investor;
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investor={investor} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <button
                      className='investor-profile-back-button padding-left'
                      onClick={() =>
                        navigate('/investor/portfolios/portfolio-details', { state: { portfolio: portfolio } })
                      }
                    >
                      <i className='fas fa-fw fa-arrow-left'></i>
                      <span>{t('misc.back')}</span>
                    </button>
                  </span>
                </div>
                <div>
                  <span className='header-2 padding-left'>{investee.name}</span>
                  <p className='primary padding-left'>{investee.company_email}</p>
                  <p className='primary padding-left'>{investee.company_website}</p>
                </div>
                <div className='d-sm-flex align-items-center justify-content-between company-content padding-right'>
                  <div className='padding-right padding-left' style={{ width: '80%' }}>
                    <h5>{t('shared.description')}</h5>
                    <p>{investee.description}</p>
                  </div>
                  <Card style={{ width: '40%' }}>
                    <Card.Body>
                      <Card.Title className='fs-16 black'>{t('shared.investmentAmount')}</Card.Title>
                      <Card.Title className='primary fs-2'>$ {investee.investment_amount}</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <DevelopmentGoals tags={investee.development_goals} />
              </div>
              {/* Working with Tabs */}
              {selectSpecificTab()}
              {displaySelectedTabDetails(investor)}
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, { loadUser, loadInvestors, loadInvestees })(InvesteeDetails);
