import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Spinner } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import CreatePortfolio from '../../Shared/CreateAction/CreatePortfolio';
import SortingArrows from '../../Shared/SortingArrows';
import EditPortfolio from '../../Shared/EditAction/EditPortfolio';
import DeletePortfolio from '../../Shared/DeleteAction/DeletePortfolio';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import { removePathEmptyParams } from '../../../services/helpers';
import { loadUser, loadInvestors, loadInvestees } from '../../../actions/auth';
import line from '../../../images/line.png';

function Portfolio(props) {
  let itemsPerPage = 25;
  const { t, i18n } = useTranslation();
  const current_language = i18n.language;
  const navigate = useNavigate();
  const headers = { Authorization: 'Token ' + localStorage.token };
  const storedValue = JSON.parse(localStorage.getItem('investor'));
  const { user, investors, investees } = props;
  const { loadUser, loadInvestors, loadInvestees } = props;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [newVal, setNewVal] = useState({
    id: '',
    name: '',
    description: '',
    logo: null,
    status: '',
    allowed_investee_limit: '',
    owner: '',
  });
  const [isCreated, setIsCreated] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [portfolios, setPortfolios] = useState([]);
  const [sortParameters, setsortParameters] = useState({
    name: '',
    status: '',
    allowed_investee_limit: '',
    noInvestee: '',
    search: '',
  });

  useEffect(() => {
    setIsSearchActive(false);
    fetchPortfolios();
    setIsCreated(false);
  }, []);

  useEffect(() => {
    if (portfolios) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(portfolios.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(portfolios.length / itemsPerPage));
    }
  }, [portfolios, itemOffset, itemsPerPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(sortParameters).toString();
    API.get(`api/portfolios?${removePathEmptyParams(queryParams)}`, {
      headers,
      sortParameters,
    }).then((response) => {
      setPortfolios(response.data);
    });
  }, [sortParameters]);

  const fetchPortfolios = () => {
    API.get('api/portfolios/', { headers })
      .then((response) => {
        setPortfolios(response.data);
      })
      .catch(function () {
        console.log('Error while loading report request');
      });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % portfolios.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const handleChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      logo: event.target.files[0],
    });
  };

  const createFormData = (formData) => {
    formData.append('name', newVal.name);
    formData.append('description', newVal.description);
    formData.append('status', newVal.status);
    formData.append('allowed_investee_limit', newVal.allowed_investee_limit);
    newVal.logo ? formData.append('logo', newVal.logo) : '';
  };

  const handleSubmit = () => {
    const url = `api/portfolios/${newVal.id}/`;
    const formData = new FormData();
    formData.append('name', newVal.name);
    formData.append('description', newVal.description);
    formData.append('status', newVal.status);
    newVal.logo ? formData.append('logo', newVal.logo) : '';
    if (newVal.name && newVal.status && newVal.description.length <= 500) {
      API.put(url, formData, { headers })
        .then(() => {
          setIsCreated(false);
          setShowMessage(true);
          fetchPortfolios();
        })
        .catch(function () {
          console.log('Error while updating');
        });
    } else {
      setSubmitted(true);
    }
  };

  const createPortfolio = () => {
    const url = `api/portfolios/`;
    const formData = new FormData();
    createFormData(formData);
    formData.append('owner', storedValue.investor.id);

    if (newVal.name && newVal.status && newVal.description.length <= 500) {
      API.post(url, formData, { headers })
        .then(() => {
          setIsCreated(true);
          fetchPortfolios();
        })
        .catch(function (response) {
          setIsError(true);
          setMessage(response.response.data);
        });
    } else {
      setSubmitted(true);
    }
  };

  const deletePortfolio = () => {
    const url = `api/portfolios/${newVal.id}/`;
    API.delete(url, { headers })
      .then(() => {
        fetchPortfolios();
        loadInvestees();
      })
      .catch(function () {
        console.log('Error while deleting portfolio');
      });
  };

  const investeeCompaniesCount = (id) => {
    let investeeCount = 0;
    if (investees) {
      for (const investee of investees) {
        if (investee.owner === id) {
          investeeCount++;
        }
      }
    }
    return investeeCount;
  };

  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }
    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  const handleSeachAndFilters = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  const displayPortfolios = (portfolios) => {
    return (
      <>
        {showMessage && (
          <div className='alert alert-success mt-1 mb-1'>
            {t('shared.portfolio')} {newVal.name}{' '}
            {isCreated ? t('shared.portfolio_created') : t('shared.agregator_updated')}
          </div>
        )}
        <div className='search-container'>
          <Form.Select
            className='mr-1'
            name='status'
            onChange={(e) => {
              setIsSearchActive(false);
              handleSeachAndFilters(e);
            }}
            defaultValue=''
          >
            <option value=''>{t('shared.sortStatus')}</option>
            <option value='Active'>{t('misc.active')}</option>
            <option value='Pending'>{t('misc.pending')}</option>
            <option value='Closed'>{t('misc.closed')}</option>
          </Form.Select>
          <Form.Control
            name='search'
            type='text'
            placeholder={t('search.byNamePlaceholder')}
            value={sortParameters.search}
            onChange={(e) => {
              setIsSearchActive(true);
              handleSeachAndFilters(e);
            }}
          />
          <span className='pl-1'>
            <CreatePortfolio
              user={user}
              newVal={newVal}
              submitted={submitted}
              message={message}
              isError={isError}
              showModal={showModal}
              onChange={handleChange}
              onFileChange={handleFileChange}
              onSubmit={createPortfolio}
              setIsError={setIsError}
              setNewVal={setNewVal}
              setShowModal={setShowModal}
              setShowMessage={setShowMessage}
              setSubmitted={setSubmitted}
            />
          </span>
        </div>
        <div className='table-style'>
          <Table className='table-link'>
            <thead>
              <tr>
                <th>
                  {t('shared.portfolioName')}
                  <SortingArrows
                    sortParameters={sortParameters}
                    name='name'
                    parametarAsc='name'
                    parametarDesc='-name'
                    handleOnClick={changeSortParameters}
                  />
                </th>
                <th>{t('shared.status')}</th>
                <th>
                  {user.type === 'Consultant' ? t('shared.noEnterprises') : t('shared.noInvestees')}
                  <SortingArrows
                    sortParameters={sortParameters}
                    name='noInvestee'
                    parametarAsc='lowtoHigh'
                    parametarDesc='hightoLow'
                    handleOnClick={changeSortParameters}
                  />
                </th>
                <th>
                  {user.type === 'Consultant' ? t('shared.enterpriseLimit') : t('shared.investeeLimit')}
                  <SortingArrows
                    sortParameters={sortParameters}
                    name='allowed_investee_limit'
                    parametarAsc='allowed_investee_limit'
                    parametarDesc='-allowed_investee_limit'
                    handleOnClick={changeSortParameters}
                  />
                </th>
                <th>{t('misc.options')}</th>
              </tr>
            </thead>
            <tbody>
              {portfolios &&
                portfolios.map((portfolio, index) => (
                  <tr key={index} className='vertical-content main2'>
                    <td
                      className='hover'
                      onClick={() =>
                        navigate('/investor/portfolios/portfolio-details', {
                          state: { portfolio: portfolio },
                        })
                      }
                    >
                      {portfolio.name}
                    </td>
                    <td
                      onClick={() =>
                        navigate('/investor/portfolios/portfolio-details', {
                          state: { portfolio: portfolio },
                        })
                      }
                    >
                      {portfolio.status === 'Active' && t('misc.active')}

                      {portfolio.status === 'Pending' && t('misc.pending')}

                      {portfolio.status === 'Closed' && t('misc.closed')}
                    </td>
                    <td
                      onClick={() =>
                        navigate('/investor/portfolios/portfolio-details', {
                          state: { portfolio: portfolio },
                        })
                      }
                    >
                      {investeeCompaniesCount(portfolio.id)}
                    </td>
                    <td>
                      <div className='d-sm-flex align-items-center justify-content-between'>
                        <div
                          onClick={() =>
                            navigate('/investor/portfolios/portfolio-details', {
                              state: { portfolio: portfolio },
                            })
                          }
                        >
                          {portfolio.allowed_investee_limit}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='table-action-buttons-edit-delete'>
                        <EditPortfolio
                          newVal={newVal}
                          portfolio={portfolio}
                          submitted={submitted}
                          showMessage={showMessage}
                          setNewVal={setNewVal}
                          onChange={handleChange}
                          onFileChange={handleFileChange}
                          onSubmit={handleSubmit}
                          setSubmitted={setSubmitted}
                          setShowMessage={setShowMessage}
                        />
                        <DeletePortfolio
                          button={false}
                          portfolio={portfolio}
                          newVal={newVal}
                          setNewVal={setNewVal}
                          onSubmit={deletePortfolio}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {isSearchActive && currentItems.length === 0 && (
            <div className='alert alert-warning'>{t('errors.noPortfolio')}</div>
          )}
        </div>
      </>
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    let termsOfAgreementRoute = `/terms-of-agreement/${current_language}`;
    return <Navigate to={termsOfAgreementRoute} />;
  } else {
    let investor = investors[0];

    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investor={investor} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row padding-top'>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>{t('misc.portfolios')}</span>
                  </span>
                </div>
              </div>
              <div className='padding-left'>
                {displayPortfolios(currentItems)}
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
                  {pageCount > 0 ? (
                    <p className='main2'>
                      {t('investorDashboard.pageof', {
                        curPage: currentPage,
                        pageC: pageCount,
                      })}
                    </p>
                  ) : (
                    ''
                  )}
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel={t('shared.nextLabel')}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={t('shared.previousLabel')}
                    renderOnZeroPageCount={null}
                    className='pagination'
                    previousClassName='previous-pagination'
                    nextClassName='next-pagination'
                    activeClassName='active-pagination'
                    disabledClassName='disabled-pagination'
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, {
  loadUser,
  loadInvestors,
  loadInvestees,
})(Portfolio);
