import React from 'react';
import InvestorDashboard from './components/Investor/Dashboard/Dashboard';
import OxiaAdminDashboard from './components/SuperAdmin/Dashboard/Dashboard';
import InvesteeDashboard from './components/Investee/Dashboard/Dashboard';
import { Navigate } from 'react-router-dom';

const AppRouter = () => {
  const role = localStorage.userType;

  if (role === 'Investor') return <InvestorDashboard />;
  else if (role === 'Consultant') return <InvestorDashboard />;
  else if (role === 'Enterprise') return <InvesteeDashboard />;
  else if (role === 'Investee') return <InvesteeDashboard />;
  else if (role === 'Oxia admin') return <OxiaAdminDashboard />;
  else return <Navigate to='/login' />;
};

export default AppRouter;
