import React, { useState, useEffect } from 'react';
import API from '../../../api';
import { ListGroup, Dropdown, Spinner, Badge, Alert } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import FileUpload from '../../SuperAdmin/Shared/FileUpload';
import EditReportRequest from '../EditAction/EditReportRequest';
import { connect } from 'react-redux';
import { loadInvestees, loadProducts, loadReportingPeriods, loadReportStatuses } from '../../../actions/auth';
import { ThreeDots } from '../ThreeDots';
import FilterReport from './FilterReport';
import { AuthenticatedLink } from './AuthenticatedLink';
import Collapsible from 'react-collapsible';
import DeleteReport from '../../Shared/DeleteAction/DeleteReport';
import { useTranslation } from 'react-i18next';

function Report(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const nameKey = `name_${currentLanguage}`;
  let { investees, products, reportingPeriods, reportStatuses, isAdmin, isInvestor } = props;
  let { loadInvestees, loadProducts, loadReportingPeriods, loadReportStatuses } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  let navigate = useNavigate();
  let [reportList, setReportList] = useState([]);
  let [newVal, setNewVal] = useState({
    uploaded_file: null,
    description: '',
    report_request: '',
  });
  let [submitted, setSubmitted] = useState(false);
  let [files, setFiles] = useState([]);
  let [reportRequestValue, setReportRequestValue] = useState({
    id: '',
    start_date: '',
    end_date: '',
    product: '',
    reporting_period: '',
    report_status: '',
  });
  let [buttonDisable, setButtonDisable] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let itemsPerPage = 5;
  let [currentItems, setCurrentItems] = useState(null);
  let [pageCount, setPageCount] = useState(0);
  let [itemOffset, setItemOffset] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!reportList) {
      fetchReportRequests();
      return <Spinner animation='grow' variant='info' className='loader' />;
    } else {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(reportList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(reportList.length / itemsPerPage));
    }
  }, [reportList, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reportList.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const fetchReportRequests = () => {
    API.get('api/report_requests/?report_status=1', { headers })
      .then((response) => {
        setReportList(response.data);
      })
      .catch(function () {
        console.log('Error while loading report request');
      });
  };

  const fetchUploadFile = () => {
    API.get('api/upload_file/?question=null', { headers })
      .then((response) => {
        setFiles(response.data);
      })
      .catch(function () {
        console.log('Error while loading file upload');
      });
  };

  useEffect(() => {
    fetchReportRequests();
    fetchUploadFile();
  }, []);

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      uploaded_file: event.target.files[0],
      description: event.target.files[0].name,
    });
  };

  const handleFileUploadChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const url = 'api/upload_file/';
    const formData = new FormData();
    formData.append('uploaded_file', newVal.uploaded_file);
    formData.append('description', newVal.description);
    formData.append('report_request', newVal.report_request);

    if (newVal.uploaded_file && newVal.report_request) {
      API.post(url, formData, { headers })
        .then(() => {
          setButtonDisable(true);
          fetchUploadFile();
          setShowModal(false);
        })
        .catch(function () {
          console.log('Error while updating');
        });
    } else {
      setSubmitted(true);
    }
  };

  const displayUploadFiles = (report) => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/upload_file`;
    const filesFromReport = files && files.filter((file) => file.report_request === report.id);

    if (filesFromReport && filesFromReport.length > 0) {
      return (
        <ul className='file-upload-list'>
          {t('shared.files')}
          {filesFromReport.map((filteredFiles, index) => (
            <li key={index} className='d-flex justify-content-between align-items-baseline'>
              <span>
                <AuthenticatedLink url={URL + '/' + filteredFiles.id + '/'} filename={filteredFiles.description}>
                  <button className='primary-button-outlined bg-white'>
                    {t('shared.download')} {filteredFiles.description}
                  </button>
                </AuthenticatedLink>
              </span>
              <span>
                <DeleteReport
                  product={findFilesName(filteredFiles.id)}
                  owner={findOwnerName(report.owner)}
                  type={t('shared.deleteType')}
                  onSubmit={() => deleteUploadFile(filteredFiles.id)}
                />
              </span>
            </li>
          ))}
        </ul>
      );
    } else {
      return '';
    }
  };

  const handleEditReportRequest = (event) => {
    const { name, value } = event.target;
    setReportRequestValue({
      ...reportRequestValue,
      [name]: value,
    });
  };

  const updateReportRequest = () => {
    const url = `api/report_requests/${reportRequestValue.id}/`;

    if (reportRequestValue.start_date && reportRequestValue.end_date && reportRequestValue.report_status) {
      API.put(
        url,
        {
          start_date: reportRequestValue.start_date,
          end_date: reportRequestValue.end_date,
          report_status: reportRequestValue.report_status,
        },
        { headers },
      )
        .then(() => {
          fetchReportRequests();
        })
        .catch(function () {
          console.log('Error while updating report request');
        });
    } else {
      setSubmitted(true);
    }
  };

  const deleteReport = (id) => {
    const url = `api/report_requests/${id}/`;
    API.delete(url, { headers }).then(() => {
      fetchReportRequests();
    });
  };

  const deleteUploadFile = (id) => {
    const url = `api/upload_file/${id}/`;
    API.delete(url, { headers }).then(() => {
      fetchUploadFile();
    });
  };

  const findProductName = (value) => {
    let searchValue = products.find((product) => product.id === value);
    return searchValue[nameKey];
  };

  const findFilesName = (value) => {
    let searchValue = files.find((files) => files.id === value);
    return searchValue.description;
  };

  const displayProductPeriodAndName = (report) => {
    const productPeriod = reportingPeriods.find((period) => period.id === report.reporting_period);
    const product = products.find((product) => product.id === report.product);
    let productPeriodValue = productPeriod.name_en;
    let productValue = product[nameKey];

    if (productPeriod.name_en.includes(' ')) {
      // Take product period without short name from the previous generated reports that were like "CFR 2020"
      productPeriodValue = productPeriod.name_en.split(' ')[1];
    }

    if (product[nameKey].includes(' ')) {
      // Take product name without additional parentheses
      productValue = product[nameKey].split('(')[0];
    }

    return (
      <>
        <span className='primary'>
          {productPeriodValue} {product.code}
        </span>
        <span className='mx-3 fw-bold'>{productValue}</span>
      </>
    );
  };

  const findReportStatus = (value) => {
    let searchValue = reportStatuses.find((status) => status.id === value);
    return searchValue[nameKey];
  };

  const findOwnerName = (value) => {
    let searchValue = investees.find((investee) => investee.id === value);
    if (!searchValue) {
      return;
    }
    return searchValue.name;
  };

  const displayDataSummaryButton = (reportId) => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/export_answer_csv/${reportId}?human_friendly=1`;
    const fileName = `datasummary_rr${reportId}.csv`;

    return (
      <AuthenticatedLink url={URL} filename={fileName}>
        <button className='primary-button-outlined bg-white mb-1 report-enter-data-button'>
          {t('shared.downloadDataSummaryHuman')}
        </button>
      </AuthenticatedLink>
    );
  };

  const displayMachineDataSummaryButton = (reportId) => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/export_answer_csv/${reportId}`;
    const fileName = `datasummary_machine_rr${reportId}.csv`;

    return (
      <AuthenticatedLink url={URL} filename={fileName}>
        <button className='primary-button-outlined bg-white mb-1 report-enter-data-button'>
          {t('shared.downloadDataSummaryImport')}
        </button>
      </AuthenticatedLink>
    );
  };

  const displayEmissionDataButton = (reportId) => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/export_emission_data/${reportId}`;
    const fileName = `emission_data_rr${reportId}.csv`;

    return (
      <AuthenticatedLink url={URL} filename={fileName}>
        <button className='primary-button-outlined bg-white mb-1 report-enter-data-button'>
          {t('shared.calculateExportEmissionData')}
        </button>
      </AuthenticatedLink>
    );
  };

  const displayAllocationTableButton = (reportId) => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/export_allocation_table/${reportId}`;
    const fileName = `allocation_table_rr${reportId}.csv`;

    return (
      <AuthenticatedLink url={URL} filename={fileName}>
        <button className='primary-button-outlined bg-white mb-1 report-enter-data-button'>
          {t('shared.calculateExportAllocationTable')}
        </button>
      </AuthenticatedLink>
    );
  };
  

  const DisplayGenerateReportButton = ({ reportId }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    return (
      <>
        {error && <Alert variant="danger">{error}</Alert>}
        <button
          className='primary-button-outlined bg-white mb-1 report-enter-data-button'
          onClick={() => handleGenerateReportClick(reportId, setLoading, setError)}
        >
          {loading ? <Spinner animation='border' size='sm' /> : t('shared.generateReportRecalculatesData')}
        </button>
      </>
    );
  };

  const handleGenerateReportClick = async (reportId, setLoading, setError) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/generate_report/${reportId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Token ' + localStorage.token,
        },
      });

      if (response.status === 404) {
        setError(t('shared.businessInformationRequired'));
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `report-${reportId}-to-be-validated.docx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error generating report:', error);
      setError(t('shared.errorGeneratingReport'));
    } finally {
      setLoading(false);
    }
  };

  const displayReportList = (reports) => {
    return (
      <ListGroup>
        {reports &&
          reports.map((report, index) => (
            <ListGroup.Item key={index}>
              <div className='d-sm-flex align-items-center justify-content-between'>
                <div>{displayProductPeriodAndName(report)}</div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Badge pill bg='secondary' className='mr-1'>
                          {findReportStatus(report.report_status)}
                        </Badge>
                      </td>
                      {isAdmin ? (
                        <td>
                          <Dropdown drop='start' className='report-dropdown ml-1'>
                            <Dropdown.Toggle as={ThreeDots} />
                            <Dropdown.Menu>
                              <Dropdown.Header>{t('misc.options')}</Dropdown.Header>
                              <>
                                <FileUpload
                                  newVal={newVal}
                                  report={report}
                                  showModal={showModal}
                                  submitted={submitted}
                                  buttonDisable={buttonDisable}
                                  setNewVal={setNewVal}
                                  setShowModal={setShowModal}
                                  onFileChange={handleFileChange}
                                  onChange={handleFileUploadChange}
                                  onSubmit={handleSubmit}
                                  setButtonDisable={setButtonDisable}
                                  setSubmitted={setSubmitted}
                                />
                                <EditReportRequest
                                  report={report}
                                  reportRequestValue={reportRequestValue}
                                  submitted={submitted}
                                  reportStatuses={reportStatuses}
                                  setReportRequestValue={setReportRequestValue}
                                  onChange={handleEditReportRequest}
                                  onSubmit={updateReportRequest}
                                />
                                <DeleteReport
                                  product={findProductName(report.product)}
                                  owner={findOwnerName(report.owner)}
                                  type={t('shared.deleteReport')}
                                  onSubmit={() => deleteReport(report.id)}
                                />
                              </>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      ) : null}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='mt-1'>
                <div>
                  <span className='fw-bold'>{findOwnerName(report.owner)}</span>
                </div>
                <div>
                  <span className='main2'>{t('shared.startDate')} </span>
                  {report.start_date}
                </div>
                <div>
                  <span className='main2'>{t('shared.endDate')} </span>
                  {report.end_date}
                </div>
              </div>
              <div className='mt-1'>
                {displayUploadFiles(report)}
                {isInvestor ? (
                  ''
                ) : findReportStatus(report.report_status) === t('shared.ongoing') && !isAdmin ? (
                  <button
                    className='primary-button mb-1 mt-1 report-enter-data-button'
                    onClick={() =>
                      navigate(`/questionnaire/report-request-${report.id}/forms-intro`, {
                        state: {
                          openModule: '',
                          reportRequestId: report.id,
                          product: report.product,
                        },
                      })
                    }
                  >
                    {t('shared.enterRequiredData')}
                  </button>
                ) : isAdmin ? (
                  <div>
                    <button
                      className='primary-button mb-1 mt-1'
                      onClick={() =>
                        navigate(`/questionnaire/report-request-${report.id}/forms-intro`, {
                          state: {
                            openModule: '',
                            reportRequestId: report.id,
                            product: report.product,
                          },
                        })
                      }
                    >
                      {t('shared.enterRequiredData')}
                    </button>
                    <Collapsible
                      trigger={t('shared.adminDataExport')}
                      className='admin-data-export'
                      transitionTime='200'
                    >
                      {displayDataSummaryButton(report.id)}
                      {displayMachineDataSummaryButton(report.id)}
                      {displayEmissionDataButton(report.id)}
                      {displayAllocationTableButton(report.id)}
                      <DisplayGenerateReportButton reportId={report.id} />
                    </Collapsible>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </ListGroup.Item>
          ))}
      </ListGroup>
    );
  };

  if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!products) {
    loadProducts();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!reportingPeriods) {
    loadReportingPeriods();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!reportStatuses) {
    loadReportStatuses();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='col-md-7 divider-line padding-right'>
        <div className='padding-left padding-top mb-1'>
          <h3 className='mb-1'>{t('misc.reports')}</h3>
          <FilterReport
            headers={headers}
            reportingPeriods={reportingPeriods}
            products={products}
            reportStatuses={reportStatuses}
            state={false}
            owner=''
            investorId=''
            setItems={setReportList}
            fetchItems={fetchReportRequests}
          />
        </div>
        <div className='padding-left'>
          {displayReportList(currentItems)}
          <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
            {pageCount > 0 ? (
              <p className='main2'>
                {t('shared.pageInfo', {
                  currentPage: currentPage,
                  pageCount: pageCount,
                })}
              </p>
            ) : (
              ''
            )}
            <ReactPaginate
              breakLabel='...'
              nextLabel={t('shared.nextLabel')}
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={t('shared.previousLabel')}
              renderOnZeroPageCount={null}
              className='pagination'
              previousClassName='previous-pagination'
              nextClassName='next-pagination'
              activeClassName='active-pagination'
              disabledClassName='disabled-pagination'
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  investees: state.auth.investees,
  products: state.auth.products,
  reportingPeriods: state.auth.reportingPeriods,
  reportStatuses: state.auth.reportStatuses,
});

export default connect(mapStateToProps, {
  loadInvestees,
  loadProducts,
  loadReportingPeriods,
  loadReportStatuses,
})(Report);
