import React, { useState, useEffect } from 'react';
import API from '../../../api';
import Sidebar from '../Shared/Sidebar';
import Header from '../../Shared/Header';
import Edit from './Edit';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner, Table } from 'react-bootstrap';
import line from '../../../images/line.png';
import ReactPaginate from 'react-paginate';
import DeletePortfolio from '../../Shared/DeleteAction/DeletePortfolio';
import CreateReportRequest from '../../Shared/CreateAction/CreateReportRequest';
import CreateInvestees from '../../Shared/CreateAction/CreateInvestees';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadProducts, loadReportingPeriods } from '../../../actions/auth';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import { useTranslation } from 'react-i18next';

function PortfolioName(props) {
  const { t } = useTranslation();
  const headers = { Authorization: 'Token ' + localStorage.token };
  const location = useLocation();
  const navigate = useNavigate();
  let { portfolio } = location.state;
  let { user, investors, products, reportingPeriods } = props;
  let { loadUser, loadInvestors, loadProducts, loadReportingPeriods } = props;
  const [investeeList, setInvesteeList] = useState([]);
  let itemsPerPage = 25;
  let [currentItems, setCurrentItems] = useState(null);
  let [pageCount, setPageCount] = useState(0);
  let [itemOffset, setItemOffset] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  const emptyInvestee = {
    name: '',
    company_email: '',
    description: '',
    development_goals: '',
    investment_amount: '',
    owner: portfolio.id,
  };
  let [newVal, setNewVal] = useState({
    id: '',
    name: '',
    description: '',
    logo: null,
    status: '',
    allowed_investee_limit: '',
    owner: '',
  });
  let [reportRequestValue, setReportRequestValue] = useState({
    id: '',
    start_date: '',
    end_date: '',
    product: '',
    owner: '',
    reporting_period: '',
    report_status: '',
  });
  let [showMessage, setShowMessage] = useState(false);
  let [submitted, setSubmitted] = useState(false);
  let [submittedReqReport, setSubmittedReqReport] = useState(false);
  let [isError, setIsError] = useState(false);
  let [message, setMessage] = useState('');
  let [showModal, setShowModal] = useState(false);
  const [newInvestees, setNewInvestees] = useState([emptyInvestee]);

  useEffect(() => {
    fetchInvesteeCompanies();
  }, []);

  useEffect(() => {
    if (investeeList) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(investeeList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(investeeList.length / itemsPerPage));
    }
  }, [investeeList, itemOffset, itemsPerPage]);

  const fetchInvesteeCompanies = async () => {
    let pID = portfolio.id;
    const response = await API.get(`api/get_direct_children/${pID}/`, { headers });
    setInvesteeList(response.data);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % investeeList.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const handleChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      logo: event.target.files[0],
    });
  };

  const handleSubmit = () => {
    const url = `api/portfolios/${newVal.id}/`;
    const formData = new FormData();
    formData.append('name', newVal.name);
    formData.append('description', newVal.description);
    formData.append('status', newVal.status);
    formData.append('allowed_investee_limit', newVal.allowed_investee_limit);
    newVal.logo ? formData.append('logo', newVal.logo) : '';
    formData.append('owner', newVal.owner);

    if (newVal.name && newVal.status && newVal.owner && newVal.description.length <= 500) {
      API.put(url, formData, { headers }).catch(function () {
        console.log('Error while updating');
      });
      setShowMessage(true);
    } else {
      setSubmitted(true);
    }
  };

  const changeInvesteesFormInput = (index, event) => {
    const { name, value } = event.target;
    const updatedNewInvestees = [...newInvestees];
    updatedNewInvestees[index][name] = value;
    setNewInvestees(updatedNewInvestees);
  };

  const handleReportRequest = (event) => {
    const { name, value } = event.target;
    setReportRequestValue({
      ...reportRequestValue,
      [name]: value,
    });
  };

  const createReportRequest = () => {
    const url = 'api/report_requests/';

    if (
      reportRequestValue.product &&
      reportRequestValue.start_date &&
      reportRequestValue.end_date &&
      reportRequestValue.reporting_period &&
      reportRequestValue.report_status
    ) {
      API.post(
        url,
        {
          product: reportRequestValue.product,
          start_date: reportRequestValue.start_date,
          end_date: reportRequestValue.end_date,
          owner: portfolio.id,
          reporting_period: reportRequestValue.reporting_period,
          report_status: Number(reportRequestValue.report_status),
          request_multiple_reports: true,
        },
        { headers },
      );
    } else {
      setSubmittedReqReport(true);
    }
  };

  const submitCreateInvestees = () => {
    const url = 'api/investees/create_multiple/';
    const areInvesteesValid = newInvestees.every(
      (investee) =>
        investee.name.trim() !== '' && investee.company_email.trim() !== '' && investee.description.length <= 500,
    );

    if (areInvesteesValid) {
      API.post(url, newInvestees, { headers })
        .then(() => {
          setIsError(false);
          setShowModal(false);
          fetchInvesteeCompanies();
        })
        .catch(function (response) {
          setIsError(true);
          setMessage(response.response.data);
        });
    } else {
      setSubmitted(true);
    }
  };

  const deletePortfolio = () => {
    const url = `api/portfolios/${newVal.id}/`;
    API.delete(url, { headers })
      .then(() => {
        window.location.href = '/superadmin/portfolios';
      })
      .catch(function () {
        console.log('Error while deleting portfolio');
      });
  };

  const displayInvesteeCompanies = () => {
    if (investeeList.length > 0) {
      return (
        <div className='table-style'>
          <Table className='table-link'>
            <thead>
              <tr>
                <th>{t('investorDashboard.enterpriseCompany')}</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {investeeList.map((investee, index) => (
                <tr key={index} className='vertical-content hover'>
                  <td
                    onClick={() =>
                      navigate('/superadmin/portfolios/portfolio-details/investee', {
                        state: { investee: investee, portfolio: portfolio },
                      })
                    }
                  >
                    {investee.name}
                  </td>
                  <td>
                    <div className='d-sm-flex align-items-center justify-content-between'>
                      <div
                        className='primary'
                        onClick={() =>
                          navigate('/superadmin/portfolios/portfolio-details/investee', {
                            state: {
                              investee: investee,
                              portfolio: portfolio,
                            },
                          })
                        }
                      >
                        {investee.company_email}
                      </div>
                      <div className='table-action-buttons'></div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    }
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!products) {
    loadProducts();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!reportingPeriods) {
    loadReportingPeriods();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        {showMessage && (
          <div className='alert alert-success mt-1 mb-1'>
            {t('shared.portfolio')} {newVal.name} {t('shared.portfolioUpdate')}
          </div>
        )}
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='col-12 padding-top'>
                  <img src={line} className='heading-line' />
                  <button
                    className='investor-profile-back-button padding-left'
                    onClick={() => navigate('/superadmin/portfolios')}
                  >
                    <i className='fas fa-fw fa-arrow-left'></i>
                    <span>Back</span>
                  </button>
                </div>
                <div className='d-sm-flex align-items-center justify-content-between padding-left padding-right'>
                  <span className='header-2'>{portfolio.name}</span>
                  <div>
                    <Edit
                      newVal={newVal}
                      portfolio={portfolio}
                      investors={investors}
                      submitted={submitted}
                      showMessage={showMessage}
                      button={true}
                      setNewVal={setNewVal}
                      onChange={handleChange}
                      onFileChange={handleFileChange}
                      onSubmit={handleSubmit}
                      setShowMessage={setShowMessage}
                      setSubmitted={setSubmitted}
                    />
                    <DeletePortfolio
                      button={true}
                      portfolio={portfolio}
                      newVal={newVal}
                      setNewVal={setNewVal}
                      onSubmit={deletePortfolio}
                    />
                    <CreateInvestees
                      investees={newInvestees}
                      emptyInvestee={emptyInvestee}
                      submitted={submitted}
                      message={message}
                      isError={isError}
                      showModal={showModal}
                      setInvestees={setNewInvestees}
                      setMessage={setMessage}
                      setIsError={setIsError}
                      setShowModal={setShowModal}
                      setSubmitted={setSubmitted}
                      onChange={changeInvesteesFormInput}
                      onSubmit={submitCreateInvestees}
                    />
                    <CreateReportRequest
                      products={products}
                      investee={''}
                      reportRequestValue={reportRequestValue}
                      reportingPeriods={reportingPeriods}
                      submitted={submittedReqReport}
                      setReportRequestValue={setReportRequestValue}
                      onChange={handleReportRequest}
                      onSubmit={createReportRequest}
                      forAllInvestees={true}
                    />
                  </div>
                </div>
              </div>
              <div className='padding-left'>
                {displayInvesteeCompanies(currentItems)}
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
                  {pageCount > 0 ? (
                    <p className='main2'>
                      Page {currentPage} of {pageCount}
                    </p>
                  ) : (
                    ''
                  )}
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel='Previous'
                    renderOnZeroPageCount={null}
                    className='pagination'
                    previousClassName='previous-pagination'
                    nextClassName='next-pagination'
                    activeClassName='active-pagination'
                    disabledClassName='disabled-pagination'
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  products: state.auth.products,
  reportingPeriods: state.auth.reportingPeriods,
});

export default connect(mapStateToProps, { loadUser, loadInvestors, loadProducts, loadReportingPeriods })(PortfolioName);
