import React from 'react';
// import { Dropdown } from "react-bootstrap";
// import { ThreeDots } from "./ThreeDots";

export default function SortingArrows({ sortParameters, name, parametarAsc, parametarDesc, handleOnClick }) {
  return (
    <div className='sort-dropdown'>
      <i
        onClick={() => handleOnClick(name, parametarAsc, parametarDesc)}
        name={name}
        className={
          sortParameters[name] === ''
            ? 'fa-solid fa-arrows-up-down'
            : sortParameters[name] === parametarAsc
            ? 'fa-solid fa-arrow-down-short-wide active-sort'
            : 'fa-solid fa-arrow-up-short-wide active-sort'
        }
      ></i>
    </div>
  );
}
