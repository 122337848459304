import React, { useEffect, useState } from 'react';
import API from '../../../api';
// import Header from "../Header";
import { connect } from 'react-redux';
import { loadUser } from '../../../actions/auth';
import { Spinner, Row, Col, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function TermsOfAgreementFR(props) {
  let { user } = props;
  let { loadUser } = props;
  let [agreementStatus, setAgreementStatus] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.languages[0];

    if (browserLanguage.startsWith('en')) {
      navigate('/terms-of-agreement/en');
    }
    if (!user) {
      loadUser();
      return <Spinner animation='grow' variant='info' className='loader' />;
    } else {
      if (user.status === 'Active') setAgreementStatus(true);
    }
  }, [user]);

  const handleCheckbox = () => {
    setAgreementStatus(!agreementStatus);
  };

  const updateUserStatus = async () => {
    const headers = { Authorization: 'Token ' + localStorage.token };
    const url = `api/users/${user.id}/`;
    await API.put(
      url,
      {
        status: agreementStatus ? 'Active' : 'Pending',
      },
      { headers },
    )
      .then(() => {
        window.location.href = '/';
      })
      .catch(function () {
        console.log('Error while adding the agreement page');
      });
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <div className='main-panel toc-main-panel'>
          <div className='content-wrapper'>
            <Row className='justify-content-center mt-1 padding-top padding-bottom'>
              <Col xl={8} lg={8} md={8}>
                <Card className='toa-card'>
                  <Card.Header>DÉCLARATION DE CONFIDENTIALITÉ</Card.Header>
                  <Card.Body>
                    <Card.Text>Transparence dans notre utilisation des données:</Card.Text>
                    <ul>
                      <li>Ce que nous collectons </li>
                      <li>Avec qui nous le partageons </li>
                      <li>Comment nous l’utilisons</li>
                    </ul>
                    <Card.Text></Card.Text>
                    <Card.Title>1. Introduction</Card.Title>
                    <Card.Text>
                      OXIA Initiative a été créée pour aider le secteur financier à passer à une économie plus verte.
                      Nous sommes convaincus qu&apos;en travaillant ensemble, nous pouvons construire un avenir meilleur
                      et nous ne pouvons y parvenir sans la confiance totale de nos clients. C&apos;est pourquoi la
                      confiance et l&apos;intégrité sont des valeurs fondamentales de notre entreprise. Cela
                      s&apos;applique d&apos;autant plus à la protection des données. Nous pensons que chacun a droit au
                      respect de sa vie privée et nous protégeons vos informations personnelles en conséquence. Nous
                      sommes totalement transparents en ce qui concerne la collecte, le stockage et l&apos;utilisation
                      des informations personnelles.
                    </Card.Text>
                    <Card.Title>2. Portée de cette déclaration de confidentialité et notre rôle </Card.Title>
                    <Card.Text>
                      En tant que prestataire de services de conseil ESG dans le monde de l’investissement financier,
                      nous fournissons cette déclaration de confidentialité pour expliquer comment nous collectons,
                      utilisons et partageons des informations lorsque vous interagissez avec nous, nos offres, services
                      et expériences. Elle décrit nos pratiques en matière de confidentialité lorsque nous traitons:
                    </Card.Text>
                    <ol type='a'>
                      <li>
                        Des informations personnelles aux fins de la préparation du rapport dans le cadre des services
                        de préparation Carboscope;
                      </li>
                      <li>
                        Des informations personnelles aux fins de la fourniture des avantages de la plateforme
                        Carboscope, qui regroupe l’ensemble des services, sites, expériences et logiciels de OXIA
                        Initiative Inc. et de ses filiales, à l’exception des informations de préparation Carboscope (la
                        &ldquo;Plateforme Carboscope&ldquo;); et/ou
                      </li>
                      <li>
                        Des informations personnelles nécessaires à la gestion, à l’exploitation et à l’amélioration de
                        notre entreprise.
                      </li>
                    </ol>
                    <Card.Text>
                      OXIA Initiative Inc. détermine les finalités et les moyens du traitement des informations
                      personnelles (le &ldquo;Contrôleur de l’Information&ldquo;). Si vous avez des questions sur le
                      traitement de vos données, veuillez nous contacter dans la section &ldquo;Comment nous
                      contacter&ldquo; ci-dessous.
                    </Card.Text>
                    <Card.Text>
                      Cette déclaration de confidentialité ne s’applique pas lorsque Carboscope traite des informations
                      personnelles en tant que prestataire de services pour le compte d’un client ou d’une entité qui
                      agit en tant que Contrôleur de l’Information, par exemple, les clients de notre moteur de calcul
                      Carboscope. Lorsque nous agissons en tant que prestataires de services, la déclaration de
                      confidentialité du Contrôleur de l’information concerné et nos accords avec cette entreprise ou
                      entité régissent notre traitement des informations personnelles.
                    </Card.Text>
                    <Card.Text>
                      Dans certaines circonstances, il peut y avoir plus d’un Contrôleur de l’Information traitant vos
                      informations. Par exemple, votre investisseur ou un partenaire financier peut également agir en
                      tant que Contrôleur de l’Information. Dans ces situations, nous agissons en tant que Contrôleur de
                      l’Information indépendant pour nos activités de traitement, ce qui signifie que nous prenons des
                      décisions sur la manière dont vos informations personnelles seront traitées indépendamment de
                      l’autre Contrôleur de l’Information. L’autre Contrôleur de l’Information peut avoir ses propres
                      obligations en vertu des lois sur la protection de l’information applicable, et vous devrez
                      adresser directement à l’autre Contrôleur de l’Information pour poser des questions sur la manière
                      dont il traite vos informations personnelles.
                    </Card.Text>
                    <Card.Title>3. Plateforme Carboscope</Card.Title>
                    <Card.Text className='fw-bold'>Plateforme Carboscope et vos informations</Card.Text>
                    <Card.Text>
                      Quand nous disons &ldquo;plateforme&ldquo;, nous voulons dire que lorsque vous choisissez de
                      partager des données avec nous ou d’importer des informations de tiers (comme une banque ou un
                      fournisseur de prêts), nous utilisons ces données ensemble, pas seulement dans les offres
                      individuelles que vous utilisez. Cela signifie que vos données cohabitent toutes ensemble.
                    </Card.Text>
                    <Card.Text>
                      Les informations personnelles que nous utilisons de cette manière centralisées sont toutes les
                      informations que Carboscope connaît à votre sujet. Cela inclut vos informations d’identification,
                      vos noms et coordonnées, vos informations de paiement, des informations sur vos activités, des
                      informations sur vos finances ou votre entreprise, le contenu que vous placez dans notre
                      plateforme, et des informations que nous avons collectées à votre sujet auprès de sources tierces.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Informations du compte organisationnel</Card.Text>
                    <Card.Text>
                      Certaines expériences et services au sein de la Plateforme Carboscope vous permettent d’interagir
                      avec une organisation (comme votre investisseur). Si vous êtes autorisé à accéder à une
                      organisation, le propriétaire de l’organisation ou un administrateur désigné peut contrôler et
                      gérer les détails de votre compte, par exemple, en déterminant vos droits d’accès, en prenant des
                      décisions concernant vos informations personnelles ou en vous demandant de prendre certaines
                      mesures. Si vos droits d’accès sont modifiés par le propriétaire ou l’administrateur désigné, vous
                      pourriez perdre l’accès aux informations qui relève du contrôle de l’organisation.
                    </Card.Text>
                    <Card.Text>
                      Votre utilisation de la Plateforme Carboscope dans le cadre du compte d’une organisation peut être
                      soumise aux politiques de l’organisation, qui peuvent différer de cette déclaration. Nous ne
                      sommes pas responsables des pratiques en matière de confidentialité ou de sécurité des autres
                      organisations, et vous devriez tenir compte des politiques de l’organisation et de votre confort à
                      permettre à l’organisation d’accéder à vos informations avant de vous connecter à leurs services.
                    </Card.Text>
                    <Card.Title>4. Informations que nous recueillons</Card.Title>
                    <Card.Text className='fw-bold'>Informations que vous nous fournissez</Card.Text>
                    <Card.Text>
                      Les informations personnelles que nous recevons à votre sujet dépendent du contexte de vos
                      interactions avec Carboscope, de la manière dont vous configurez votre compte et des choix que
                      vous faites, y compris vos paramètres de confidentialité. Les informations personnelles que vous
                      fournissez peuvent également dépendre des services ou des expériences que vous utilisez, de votre
                      emplacement et des lois applicables. En savoir plus sur les informations personnelles que nous
                      collectons, comment nous les utilisons et vos droits.
                    </Card.Text>
                    <ul>
                      <li>
                        <span className='fw-bold'>Inscription: </span>Nous recueillons des informations lorsque vous
                        créez un compte, interagissez avec la Plateforme Carboscope ou activez un abonnement. Les
                        informations personnelles peuvent inclure vos coordonnées (telles que votre nom, adresse, numéro
                        de téléphone et courriel), votre photo de profil, votre logo, vos informations de facturation
                        (vos informations de paiement), vos noms d’utilisateur et vos identifiants.
                      </li>
                      <li>
                        <span className='fw-bold'>Informations d’identification: </span>
                        Nous recueillons des informations pour vérifier votre identité, y compris votre nom, votre
                        numéro d’entreprise ou NEQ, par exemple pour vérifier votre identité afin de vous aider à
                        accéder à la plateforme
                      </li>
                      <li>
                        <span className='fw-bold'>
                          Support client, recherche sur les produits, formation et commentaires:{' '}
                        </span>
                        Nous pouvons recueillir des informations personnelles lorsque vous nous contactez pour obtenir
                        du support, nous faire part de vos commentaires, participer à des enquêtes facultatives, à des
                        recherches sur les produits ou à des formations et que vous choisissez de partager.
                      </li>
                      <li>
                        <span className='fw-bold'>Informations sur l’appareil: </span>
                        Nous pouvons recueillir des informations sur votre appareil telles que les adresses de protocole
                        Internet (IP), les informations de journal, les messages d’erreur, le type d’appareil et les
                        identifiants uniques de l’appareil. Par exemple, nous pouvons recueillir des adresses IP lorsque
                        vous utilisez nos fonctionnalités de connexion et de sécurité.
                      </li>
                      <li>
                        <span className='fw-bold'>Contenu: </span>Nous pouvons recevoir des informations sur votre
                        entreprise, vos finances, vos dépenses, vos factures, vos états financiers, les détails de vos
                        transactions financières, les détails de la paie, les détails de paiement, les détails de
                        déclaration fiscale, les détails concernant vos clients ou fournisseurs ou employés, vos revenus
                        et informations salariales, et/ou des informations d’investissement.
                      </li>
                      <li>
                        <span className='fw-bold'>Contenu de services tiers: </span>
                        Nous recevons des informations vous concernant lorsque vous vous connectez à un service tiers
                        avec votre compte ou lorsque vous associez votre compte à un service tiers. Par exemple, vous
                        pouvez choisir de connecter votre compte avec des comptes d’enquête.
                      </li>
                      <li>
                        <span className='fw-bold'>Informations d’utilisation: </span>
                        Nous pouvons recueillir des informations sur l’utilisation, telles que les pages que vous avez
                        consultées, les fonctionnalités que vous utilisez, le type de navigateur que vous utilisez et
                        des détails sur les liens avec lesquels vous interagissez.
                      </li>
                      <li>
                        <span className='fw-bold'>Conseils d’experts: </span>
                        La Plateforme Carboscope offre de nombreuses opportunités pour vous connecter avec des experts
                        en direct, notamment des experts en environnement, par exemple. Lorsque vous interagissez avec
                        ces experts, nous pouvons recevoir des informations sur les questions que vous posez, les
                        détails de vos comptes et les conseils qui vous sont fournis.
                      </li>
                      <li>
                        <span className='fw-bold'> Informations provenant de cookies et d’autres technologies: </span>
                        Les cookies sont des paquets de données envoyés depuis le serveur Web du site Web vers votre
                        navigateur. Ils sont stockés sur votre ordinateur et peuvent être récupérés par le serveur Web
                        lors d’une visite ultérieure. Les cookies stockent des informations sur les préférences en ligne
                        des visiteurs du site Web et nous permettent d’améliorer l’expérience des visiteurs. Carboscope
                        et nos fournisseurs de services peuvent utiliser des outils couramment utilisés tels que les
                        cookies, les balises Web, les pixels, les objets partagés locaux et des technologies similaires
                        (collectivement appelés &ldquo;cookies&ldquo;) pour collecter des informations vous concernant
                        (les &ldquo;informations des cookies&ldquo;) afin de vous fournir les expériences que vous
                        demandez, de reconnaître votre visite, de suivre vos interactions et d’améliorer votre
                        expérience, ainsi que celle d’autres clients. Vous avez le contrôle sur certaines des
                        informations que nous collectons grâce aux cookies et sur la manière dont nous les utilisons.
                      </li>
                    </ul>
                    <Card.Text className='fw-bold'>Informations fournies par d’autres</Card.Text>
                    <Card.Text>
                      Notre plateforme est conçue pour vous aider à vous connecter avec des organisations. En raison de
                      ces connexions, d’autres personnes peuvent être en mesure de fournir des informations vous
                      concernant. Par exemple, votre investisseur peut fournir des informations sur son investissement
                      et son activité professionnelle pour traiter ses analyses. Vous pouvez également être en mesure de
                      fournir ou de traiter des informations sur d’autres personnes, par exemple, si vous êtes
                      administrateur de compte. Si vous fournissez des informations sur d’autres personnes dans notre
                      plateforme, vous ne devez le faire que si vous avez d’abord obtenu les droits et les autorisations
                      appropriés, notamment en obtenant un consentement écrit avancé, si la loi applicable l’exige.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Autres sources d’informations</Card.Text>
                    <Card.Text>
                      Nous pouvons également obtenir des informations vous concernant auprès d’autres personnes, dans la
                      mesure permise par la loi applicable. Nous protégeons et traitons les informations obtenues de ces
                      parties conformément à cette déclaration de confidentialité, en respectant les restrictions
                      supplémentaires imposées par la source des informations. Nos sources peuvent varier avec le temps
                      et dépendre de la manière dont vous utilisez la Plateforme Carboscope. Par exemple, nous recevons
                      des informations de:
                    </Card.Text>
                    <ul>
                      <li>
                        <span className='fw-bold'>
                          Fournisseurs d’informations complémentaires et de vérification d’identité:{' '}
                        </span>
                        Des prestataires de services qui nous aident à vérifier votre identité, les spécificités de
                        votre entreprise et/ou à compléter les informations que vous avez fournies pour garantir
                        l’exactitude de vos informations. Par exemple, nous utilisons des prestataires de services tiers
                        pour valider votre adresse postale, votre numéro de téléphone ou pour fournir des détails
                        supplémentaires sur votre entreprise. Ces prestataires peuvent inclure, par exemple, votre
                        institution financière, votre fournisseur de télécommunications ou votre fournisseur de courrier
                        électronique.
                      </li>
                      <li>
                        <span className='fw-bold'>Fournisseurs de support client: </span>
                        Des prestataires de services qui nous fournissent des informations vous concernant ou sur votre
                        interaction avec la Plateforme Carboscope à des fins de dépannage. Par exemple, nous pouvons
                        obtenir des informations de support ou des problèmes techniques que vous avez signalés à ces
                        tiers.
                      </li>
                      <li>
                        <span className='fw-bold'>Autres utilisateurs: </span>
                        Comme décrit ci-dessus, nous pouvons obtenir des informations vous concernant auprès d’autres
                        utilisateurs, tels que votre comptable, votre comptable ou votre investisseur. Nous pouvons
                        également collecter de telles informations par le biais de fonctionnalités telles que les
                        programmes de recommandation de membres.
                      </li>
                      <li>
                        <span className='fw-bold'>
                          Prestataires de gestion des risques, de cybersécurité et de lutte contre la fraude:{' '}
                        </span>
                        Nous pouvons recevoir des informations de prestataires de services qui nous aident à évaluer les
                        risques associés à nos offres, notamment pour lutter contre la fraude, les activités illégales
                        et protéger vos informations personnelles.
                      </li>
                      <li>
                        <span className='fw-bold'>Prestataires de contenu : </span>
                        Nous pouvons recevoir des informations de prestataires de logiciels qui rendent le contenu
                        généré par l’utilisateur de leur service disponible à d’autres, comme les avis sur les
                        entreprises locales ou les publications publiques sur les médias sociaux.
                      </li>
                      <li>
                        <span className='fw-bold'>Prestataires de communication et réseaux sociaux: </span>
                        Si vous nous donnez votre permission, nous pouvons collecter des informations auprès de
                        fournisseurs de messagerie électronique, de fournisseurs de communication et de réseaux sociaux.
                      </li>
                      <li>
                        <span className='fw-bold'>Partenaires proposant des offres communes: </span>
                        Nous pouvons proposer des services ou des expériences en co-marque ou participer à des activités
                        de marketing conjointes avec d’autres, notamment lors de nos conférences ou de nos événements en
                        direct.
                      </li>
                      <li>
                        <span className='fw-bold'>Sources publiques disponibles: </span>
                        nous collectons des informations auprès de sources publiques disponibles, telles que les bases
                        de données gouvernementales ouvertes.
                      </li>
                      <li>
                        <span className='fw-bold'>Informations requises: </span>
                        Certains services et expériences de la Plateforme Carboscope exigent que vous fournissiez des
                        informations pour fonctionner. Si vous ne souhaitez pas fournir les informations requises, vous
                        ne pourrez peut-être pas utiliser certaines fonctionnalités. Toutes les autres particularités
                        concernant notre collecte de données seront conformes à la Loi sur la protection des
                        renseignements personnels dans le secteur privé.
                      </li>
                    </ul>
                    <Card.Title>5. Comment nous utilisons les informations personnelles</Card.Title>
                    <Card.Text>
                      Nous collectons et traitons les informations personnelles vous concernant uniquement dans les cas
                      suivants:{' '}
                    </Card.Text>
                    <ul>
                      <li>Nous avons votre consentement pour le faire;</li>
                      <li>
                        Nous avons besoin des informations personnelles pour exécuter un contrat avec vous ou vous
                        fournir un service;
                      </li>
                      <li>Vous fournir les avantages de la Plateforme Carboscope et gérer notre entreprise;</li>
                      <li>
                        Le traitement est dans notre intérêt légitime dans les juridictions où l’intérêt légitime de
                        l’entreprise est un fondement légitime du traitement; ou
                      </li>
                      <li>
                        Nous devons respecter les obligations légales, y compris les lois et règlements applicables.
                      </li>
                    </ul>
                    <Card.Text>
                      Les informations personnelles sont utilisées pour exploiter notre entreprise aux fins suivantes,
                      qui sont nécessaires pour établir et maintenir notre relation avec vous, notamment, mais sans s’y
                      limiter:
                    </Card.Text>
                    <ul>
                      <li>Vous fournir la Plateforme Carboscope et créer votre compte;</li>
                      <li>Améliorer nos produits et services;</li>
                      <li>
                        Gérer et exploiter notre entreprise, y compris résoudre les litiges de facturation et
                        financiers;
                      </li>
                      <li>Vous fournir des fonctionnalités, telles que vos rapports et scores;</li>
                      <li>Vous mettre en relation avec des experts et d’autres utilisateurs;</li>
                      <li>
                        Communiquer avec vous, notamment en vous envoyant des notifications électroniques concernant la
                        confidentialité de vos finances;
                      </li>
                      <li>Faire de la publicité et commercialiser nos services et expériences;</li>
                      <li>
                        Personnaliser votre expérience et adapter les recommandations et offres qui vous sont
                        présentées, notamment grâce au développement d’informations vous concernant et de vos besoins;
                      </li>
                      <li>Vous fournir un support et résoudre les litiges;</li>
                      <li>Mener des recherches, notamment en partenariat avec des établissements académiques.</li>
                      <li>Se conformer à nos obligations légales et réglementaires;</li>
                      <li>Authentifier votre identité;</li>
                      <li>
                        Protéger les droits, biens, sécurités ou sûretés de la Plateforme Carboscope, de nos clients, de
                        nos employés ou d’autres personnes et prévenir des activités frauduleuses ou illégales;
                      </li>
                      <li>
                        Exercer nos droits dans le cadre de procédures judiciaires, administratives ou d’arbitrage;
                      </li>
                      <li>Appliquer, réparer ou appliquer nos Conditions de service ou d’autres accords et/ou;</li>
                      <li>
                        À d’autres fins compatibles avec les fins divulguées, si et dans la mesure où cela est autorisé
                        par la loi applicable.
                      </li>
                    </ul>
                    <Card.Text className='fw-bold'>Traitement automatisé</Card.Text>
                    <Card.Text>
                      Pour vous fournir des conseils, des recommandations et des expériences personnalisés précieux,
                      nous pouvons traiter vos informations personnelles à l’aide de méthodes automatisées et manuelles
                      (humaines). Nos méthodes automatisées incluent l’intelligence artificielle (IA) et diverses
                      technologies qui aident nos services à apprendre et à raisonner pour améliorer notre capacité à
                      personnaliser et à améliorer votre expérience sur la Plateforme Carboscope. Toutes les autres
                      particularités concernant notre utilisation des données seront conformes à la Loi sur la
                      protection des renseignements personnels dans le secteur privé.
                    </Card.Text>
                    <Card.Title>6. Comment nous partageons vos informations</Card.Title>
                    <Card.Text>Nous pouvons partager vos informations dans les cas suivants:</Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Avec votre consentement</span>
                      <br />
                      Conformément à nos Principes de gestion des données, sauf indication contraire ci-dessous, nous ne
                      partageons vos informations avec des tiers que lorsque vous nous avez demandé de le faire.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>À des fins de recherche</span>
                      <br />
                      Avec des contrôles appropriés, nous pouvons partager des informations avec des tiers, tels que des
                      établissements académiques, des gouvernements et des organisations à but non lucratif, à des fins
                      de recherche ou pour publier des documents académiques ou liés aux politiques. Nous ne partageons
                      des informations que de manière à ce qu’aucune personne ne puisse être identifiée.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>
                        Pour des fonctionnalités conjointes, des ventes, des promotions et des événements
                      </span>
                      <br />
                      Nous pouvons partager vos informations avec des entreprises tierces qui proposent conjointement
                      des fonctionnalités avec nous.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Avec des fournisseurs de services financiers</span>
                      <br />
                      Nous pouvons partager des informations personnelles avec des agences de recouvrement, des agences
                      de crédit, des fournisseurs de gestion de la fraude et d’autres fournisseurs de services
                      financiers.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Lorsque vous publiez publiquement des informations</span>
                      <br />
                      Nous pouvons vous offrir la possibilité de publier publiquement des critiques, des questions, des
                      commentaires, des suggestions ou tout autre contenu pouvant inclure des informations personnelles,
                      telles que votre nom ou votre nom d&apos;utilisateur. Tout ce que vous partagez sur un forum
                      public est public et vous devez bien réfléchir avant de décider de le partager.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Avec des prestataires de services ou des agents</span>
                      <br />
                      Nous partageons des informations personnelles avec nos prestataires de services ou agents qui
                      fournissent des services en notre nom aux fins décrites dans la présente déclaration de
                      confidentialité. Les fournisseurs de services ou les agents sont tenus de mettre en œuvre des
                      contrôles raisonnables de protection de la vie privée et des informations afin de maintenir la
                      confidentialité et la sécurité des informations qui leur sont fournies, conformément aux pratiques
                      de confidentialité décrites dans la présente déclaration. Les fournisseurs de services ou les
                      agents peuvent inclure des sociétés qui nous assistent dans nos efforts de publicité, de marketing
                      et de vente, nous aident avec nos offres technologiques (telles que des fournisseurs
                      d&apos;hébergement, de sécurité ou de lutte contre la fraude) et nous aident à gérer notre
                      entreprise.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Pour les fusions et acquisitions</span>
                      <br />
                      Si nous sommes impliqués dans une fusion, une vente d&apos;actifs, un financement, une
                      liquidation, une faillite ou l&apos;acquisition de tout ou partie de notre entreprise à une autre
                      société, nous pouvons partager vos informations avec cette société et ses conseillers avant et
                      après la Date de la transaction.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Aucune vente d&apos;informations personnelles à des tiers</span>
                      <br />
                      Nous ne vendons pas et ne vendrons pas d&apos;informations personnelles à des tiers. Nous
                      partageons des informations personnelles avec des tiers aux fins commerciales décrites dans cette
                      déclaration.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>
                        Avec nos sociétés affiliées et filiales et votre droit de limiter le partage d&apos;informations
                      </span>
                      <br />
                      Nous pouvons partager vos informations avec nos sociétés affiliées et filiales à des fins
                      commerciales quotidiennes, comme décrit dans cette déclaration, y compris à des fins de marketing.
                      Certaines lois peuvent vous accorder le droit de limiter nos activités de partage
                      d&apos;informations dans certaines circonstances.
                    </Card.Text>
                    <Card.Text>
                      <span className='fw-bold'>Cookies et autres technologies</span>
                      <br />
                      Vous pouvez trouver des informations sur la modification des paramètres de votre navigateur pour
                      désactiver les cookies dans les paramètres de votre navigateur. Dans certains pays, vous pourrez
                      également modifier vos paramètres de cookies en utilisant notre outil de préférences en matière de
                      cookies. Si vous désactivez tout ou partie des cookies, le service ou certaines parties du service
                      risquent de ne pas fonctionner.
                    </Card.Text>
                    <Card.Text>
                      La plateforme Carboscope n&apos;est actuellement pas configurée pour répondre aux signaux « Do Not
                      Track » des navigateurs car, à l&apos;heure actuelle, aucune norme formelle « Do Not Track »
                      n&apos;a été adoptée.
                    </Card.Text>
                    <Card.Text>
                      Pour des raisons juridiques. Nous pouvons partager vos informations avec des tiers pour des
                      raisons juridiques sans votre consentement et dans la mesure permise par la loi, notamment
                    </Card.Text>
                    <ul>
                      <li>
                        Lorsque nous pensons raisonnablement que la divulgation est nécessaire afin de se conformer à
                        une assignation à comparaître, à une ordonnance du tribunal ou à toute autre loi, réglementation
                        ou procédure judiciaire applicable.
                      </li>
                      <li>
                        Pour protéger les droits, la propriété ou la sécurité de la plateforme Carboscope, de nos
                        clients ou de tiers
                      </li>
                      <li>Pour se protéger ou se défendre contre des attaques</li>
                      <li>
                        Pour faire respecter, remédier ou appliquer nos conditions d&apos;utilisation ou d&apos;autres
                        accords
                      </li>
                      <li>Pour prévenir la fraude, les attaques de cybersécurité ou les activités illégales</li>
                      <li>Pour le recouvrement de créances</li>
                      <li>
                        Avec les agences de réglementation, y compris les agences fiscales gouvernementales, si
                        nécessaire pour aider à détecter et combattre la fraude et/ou protéger nos clients, utilisateurs
                        et/ou la plateforme Carboscope, ou dans le cadre des programmes institutionnels de contrôle des
                        risques requis.
                      </li>
                    </ul>
                    Toute autre particularité concernant notre partage de données suivra la Loi sur la protection des
                    renseignements personnels dans le secteur privé.
                    <Card.Title>7. Vos droits et choix en matière d&apos;information</Card.Title>
                    <Card.Text className='fw-bold'>Vos droits</Card.Text>
                    <Card.Text>
                      Oxia Initiative inc. estime que vous avez des droits sur les informations qui vous concernent
                      et/ou qui concernent votre entreprise. Si une autre personne a saisi ou traité des informations
                      dans la Plateforme Carboscope en votre nom ou au nom de votre entreprise (et que nous traitons ces
                      informations en tant que contrôleur d&apos;informations), vous pouvez demander à recevoir une
                      copie de vos informations, même si vous n&apos;avez pas de compte chez nous.
                    </Card.Text>
                    <Card.Text>
                      En fonction de votre lieu de résidence, vous pouvez disposer de certains droits spécifiques à
                      l&apos;État ou à la nation en ce qui concerne vos informations personnelles que nous collectons et
                      traitons
                    </Card.Text>
                    <Card.Text className='fw-bold'>Vérification</Card.Text>
                    <Card.Text>
                      Afin de protéger la vie privée et la sécurité de vos informations, il peut vous être demandé de
                      fournir des informations supplémentaires pour vérifier votre identité et/ou vos droits de
                      propriété avant que nous n&apos;exercions vos droits sur les données. Si nous ne sommes pas en
                      mesure de vérifier votre identité ou vos droits de propriété sur les données, il se peut que nous
                      ne soyons pas en mesure de vous accorder des droits sur les données jusqu&apos;à ce que vous soyez
                      en mesure de nous fournir les documents appropriés.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Conservation des informations</Card.Text>
                    <Card.Text>
                      À moins que vous ne nous demandiez spécifiquement de supprimer vos informations personnelles, nous
                      conservons vos informations personnelles aussi longtemps qu&apos;il est nécessaire de se conformer
                      à nos exigences en matière de conservation des données et de vous fournir des services et les
                      avantages de la Plateforme Carboscope. Cependant, même si vous demandez une suppression, nous
                      pouvons être tenus de conserver vos informations aussi longtemps que nécessaire pour :
                    </Card.Text>
                    <ul>
                      <li>
                        Se conformer à nos besoins de conformité légale ou réglementaire (par exemple, conserver les
                        enregistrements des transactions que vous avez effectuées avec nous);
                      </li>
                      <li>exercer, établir ou défendre des droits légaux ; et/ou</li>
                      <li>pour nous protéger contre les activités frauduleuses ou abusives sur notre service.</li>
                    </ul>
                    <Card.Text>
                      Cela signifie que nous pouvons conserver des informations différentes pendant des périodes
                      différentes. Si votre compte est annulé parce que vous ne l&apos;avez pas utilisé depuis
                      longtemps, nous pouvons supprimer immédiatement ces informations.
                    </Card.Text>
                    <Card.Text>
                      Il peut arriver que nous ne soyons pas en mesure de supprimer, anonymiser ou dépersonnaliser
                      complètement vos informations pour des raisons techniques, juridiques, de conformité réglementaire
                      ou pour d&apos;autres raisons opérationnelles. Dans ce cas, nous prendrons des mesures
                      raisonnables pour isoler en toute sécurité vos informations personnelles de tout traitement
                      ultérieur jusqu&apos;à ce que nous soyons en mesure de les supprimer, de les rendre anonymes ou de
                      les dépersonnaliser.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Transferts internationaux de données</Card.Text>
                    <Card.Text>
                      Sauf si vous nous avez donné votre accord pour transférer vos informations, les informations
                      relatives à la préparation seront traitées et stockées aux États-Unis conformément à la loi
                      applicable.
                    </Card.Text>
                    <Card.Text>
                      Sous réserve des exceptions mentionnées ci-dessus, vous acceptez et Carboscope se réserve le droit
                      de stocker et de traiter vos informations personnelles aux États-Unis et dans tout autre pays où
                      Carboscope ou ses affiliés, filiales ou prestataires de services exploitent des installations
                      conformément aux lois et réglementations applicables et dans la mesure permise par celles-ci.
                      Certains de ces pays peuvent avoir des lois sur la protection des données qui sont différentes de
                      celles de votre pays (et, dans certains cas, peuvent ne pas être aussi protectrices).
                    </Card.Text>
                    <Card.Text>
                      Lorsque nous transférons, stockons ou traitons des informations personnelles en dehors de votre
                      juridiction, nous prenons les mesures de protection appropriées pour que vos informations
                      personnelles restent protégées conformément à la présente déclaration de confidentialité et à la
                      législation applicable. Nous pouvons utiliser des contrats ou les clauses contractuelles types
                      approuvées par la Commission européenne pour garantir que vos informations sont protégées à un
                      niveau comparable.
                    </Card.Text>
                    <Card.Text>
                      Carboscope se conforme à la loi applicable en ce qui concerne la collecte, l&apos;utilisation et
                      la conservation des renseignements personnels transférés de l&apos;Union européenne (UE), des
                      États-Unis et du Canada.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Sécurité de vos informations personnelles</Card.Text>
                    <Card.Text>
                      Nous utilisons des mesures de protection physiques, techniques et organisationnelles conçues pour
                      protéger vos informations. Cependant, malgré ces contrôles, nous ne pouvons pas assurer ou
                      garantir complètement la sécurité de vos informations. Vous pouvez en savoir plus sur la manière
                      dont nous protégeons vos informations et sur les mesures que vous pouvez prendre pour protéger vos
                      informations ici.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Modifications de nos déclarations de confidentialité</Card.Text>
                    <Card.Text>
                      De temps à autre, nous pouvons modifier ou mettre à jour notre déclaration de confidentialité.
                      Nous nous réservons le droit d&apos;apporter des modifications ou des mises à jour à tout moment.
                      Si nous apportons des modifications importantes à la manière dont nous traitons vos informations
                      personnelles, nous vous en informerons par l&apos;intermédiaire de notre plateforme ou par
                      d&apos;autres moyens conformes à la législation applicable.
                    </Card.Text>
                    <Card.Text>
                      Vous pouvez consulter la date de la dernière mise à jour de la présente déclaration de
                      confidentialité en vérifiant la date de &ldquo;dernière mise à jour&ldquo; affichée en haut de la
                      présente déclaration de confidentialité. Veuillez consulter régulièrement la présente déclaration
                      de confidentialité pour rester informé de la manière dont Carboscope protège votre vie privée.
                      Toute autre particularité concernant vos droits et vos choix sera conforme à la Loi sur la
                      protection des renseignements personnels dans le secteur privé.
                    </Card.Text>
                    <Card.Title>CONDITIONS D&apos;UTILISATION</Card.Title>
                    <Card.Text>
                      Les présentes conditions régissent l&apos;utilisation de la plate-forme CARBOSCOPE, y compris les
                      logiciels, les données, les informations, les rapports et les autres services mis à disposition
                      sur, à partir de ou par l&apos;intermédiaire de CARBOSCOPE et de ses sous-domaines respectifs
                      (ainsi que tous les composants, fonctions ou téléchargements qui y sont liés). En utilisant cette
                      plateforme, vous indiquez que vous avez lu et compris les conditions d&apos;utilisation et que
                      vous acceptez de toujours les respecter.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Propriété intellectuelle</Card.Text>
                    <Card.Text>
                      Tout le contenu publié et mis à disposition sur cette plateforme est la propriété d&apos;Oxia
                      Initiative inc. et de ses créateurs. Cela inclut, sans s&apos;y limiter, les images, les textes,
                      les logos, les documents, les fichiers téléchargeables et tout ce qui contribue à la composition
                      de cette plate-forme.
                    </Card.Text>
                    <Card.Text>
                      L&apos;âge minimum pour utiliser cette plate-forme est de 18 ans. Les utilisateurs conviennent
                      qu&apos;en utilisant notre plate-forme, ils ont plus de 18 ans. Nous n&apos;assumons aucune
                      responsabilité juridique en cas de fausse déclaration d&apos;âge.
                    </Card.Text>
                    <Card.Text>
                      En tant qu&apos;utilisateur, vous acceptez d&apos;utiliser notre plate-forme légalement et de ne
                      pas l&apos;utiliser à des fins illégales, notamment:
                    </Card.Text>
                    <ul>
                      <li>Harceler ou abuser d&apos;autres utilisateurs de la plate-forme;</li>
                      <li>Violer les droits des autres utilisateurs de la plate-forme;</li>
                      <li>
                        Violer les droits de propriété intellectuelle des propriétaires de la plateforme ou de tout
                        tiers à la plateforme;
                      </li>
                      <li>Pirater le compte d&apos;un autre utilisateur de la plate-forme;</li>
                      <li>Agir d&apos;une manière qui pourrait être considérée comme frauduleuse;</li>
                      <li>Participer à une activité illégale sur la plate-forme;</li>
                      <li>Publier tout matériel qui pourrait être considéré comme inapproprié ou offensant;</li>
                      <li>Reproduire, copier, modifier, adapter, traduire, créer des œuvres dérivées;</li>
                      <li>
                        Supprimer, altérer ou masquer tout avis de propriété, restrictif ou autre, apposé sur la
                        plate-forme ou tout autre produit mis à votre disposition sur la plate-forme.
                      </li>
                    </ul>
                    <Card.Text>
                      Tant que vous acceptez ces conditions, et seulement tant qu&apos;Oxia-Initiative Inc. vous
                      autorise à utiliser la plateforme CARBOSCOPE, vous pouvez utiliser ou visualiser toute partie de
                      la plateforme à laquelle nous vous donnons accès en vertu de ces conditions d&apos;utilisation à
                      des fins de visualisation uniquement. Vous acceptez de ne pas afficher publiquement (en tout ou en
                      partie) la plate-forme CARBOSCOPE, y compris tout contenu, service, rapport ou autre produit ou
                      matériel mis à disposition par l&apos;intermédiaire de la plate-forme ou en relation avec
                      celle-ci. Sous réserve de votre accord avec les termes et conditions des présentes,
                      Oxia-Initiative Inc. vous accorde par la présente une licence limitée, non exclusive, révocable,
                      non transférable, non sous-licenciable et non cessible pour reproduire, utiliser et distribuer les
                      rapports uniquement dans le but de les utiliser dans vos activités internes d&apos;évaluation,
                      d&apos;investissement et de gestion.
                    </Card.Text>
                    <Card.Text>
                      Il vous incombe d&apos;obtenir et de payer tout le matériel, les télécommunications et les autres
                      services nécessaires à l&apos;utilisation de la plate-forme.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Contributions des utilisateurs</Card.Text>
                    <Card.Text>
                      Les utilisateurs peuvent publier les informations suivantes sur notre plateforme:
                    </Card.Text>
                    <ul>
                      <li>Les réponses aux questionnaires donnés par Oxia-Initiative Inc.;</li>
                      <li>Information for the creation of an account.</li>
                    </ul>
                    <Card.Text>
                      En publiant sur notre plate-forme, vous vous engagez à ne pas agir illégalement et à ne pas violer
                      les conditions d&apos;utilisation acceptables énumérées dans le présent document.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Information</Card.Text>
                    <Card.Text>
                      En acceptant ces conditions d&apos;utilisation, vous permettez à Oxia-Initiative Inc.
                      d&apos;utiliser toute information que vous partagez sur la plateforme CARBOSCOPE dans la mesure
                      légale autorisée par notre déclaration de confidentialité.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Comptes</Card.Text>
                    <Card.Text>
                      Lorsque vous créez un compte sur notre plateforme, vous acceptez ce qui suit : 1. Vous êtes seul
                      responsable de votre compte, de sa sécurité et de sa confidentialité, y compris de tout mot de
                      passe ou de toute information sensible liée à ce compte. 2. Que toutes les informations
                      personnelles que vous nous fournissez par l&apos;intermédiaire de votre compte sont à jour,
                      exactes et véridiques et que vous mettrez à jour vos informations personnelles en cas de
                      changement.
                    </Card.Text>
                    <Card.Text>
                      Nous nous réservons le droit de suspendre ou de résilier votre compte si vous utilisez notre
                      plateforme de manière illégale ou si vous enfreignez la politique d&apos;utilisation acceptable.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Limitation de responsabilité</Card.Text>
                    <Card.Text>
                      Oxia-Initiative Inc. et ses employés ne seront pas tenus responsables de tout problème découlant
                      d&apos;une mauvaise utilisation de la plate-forme.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Indemnisation</Card.Text>
                    <Card.Text>
                      En tant qu&apos;utilisateur, vous garantissez Oxia-Initiative Inc. contre toute responsabilité,
                      coûts, causes d&apos;action, dommages ou dépenses résultant de votre utilisation de cette
                      plate-forme ou de votre violation de l&apos;une des dispositions énoncées dans le présent
                      document.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Lois applicables</Card.Text>
                    <Card.Text>
                      Le présent document est soumis aux lois applicables au Canada et vise à se conformer aux règles et
                      réglementations nécessaires. Pour les résidents de l&apos;UE, le GDPR (General Data Protection
                      Regulation) est la loi applicable qui régit ce document. En cas d&apos;incohérence entre une
                      disposition du présent document et le GDPR, les règles du GDPR prévaudront.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Divisibilité</Card.Text>
                    <Card.Text>
                      Si, à tout moment, l&apos;une des dispositions énoncées dans le présent document s&apos;avère
                      incompatible ou invalide en vertu du droit applicable, cette disposition sera considérée comme
                      nulle et non avenue et sera supprimée du présent document. Toutes les autres dispositions ne
                      seront pas affectées par ces lois et le reste du présent document continuera d&apos;être considéré
                      comme valide.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Modifications</Card.Text>
                    <Card.Text>
                      Les présentes conditions d&apos;utilisation peuvent être modifiées de temps à autre afin de rester
                      conformes à la loi et de refléter toute modification apportée à la manière dont nous exploitons
                      notre plateforme et à la manière dont nous attendons des utilisateurs qu&apos;ils se comportent
                      sur notre plateforme. Nous recommandons à nos utilisateurs de vérifier ces conditions de temps à
                      autre pour s&apos;assurer qu&apos;ils sont au courant des mises à jour. Le cas échéant, nous
                      informerons les utilisateurs par courrier électronique des modifications apportées à ces
                      conditions ou publierons un avis sur notre plateforme.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Résiliation</Card.Text>
                    <Card.Text>
                      Les présentes conditions générales sont valables jusqu&apos;à ce qu&apos;elles soient résiliées.
                      Oxia Initiative Inc. peut résilier ou suspendre votre utilisation de la plateforme CARBOSCOPE à
                      tout moment et sans préavis, pour quelque raison que ce soit ou sans raison du tout, y compris si
                      Oxia-Initiative Inc. estime que vous avez violé ou agi de manière incompatible avec la lettre ou
                      l&apos;esprit de ces conditions. En cas de résiliation ou de suspension, votre droit
                      d&apos;utiliser la plate-forme cessera immédiatement et Oxia-Initiative Inc. pourra, sans
                      responsabilité envers vous ou un tiers, désactiver ou supprimer immédiatement votre nom
                      d&apos;utilisateur, votre mot de passe et votre compte ainsi que tous les éléments qui y sont liés
                      sans aucune obligation de fournir un nouvel accès à ces éléments.
                    </Card.Text>
                    <Card.Text className='fw-bold'>Informations de contact</Card.Text>
                    <Card.Text>
                      Si vous avez des questions ou des commentaires sur la présente déclaration de confidentialité, nos
                      conditions d&apos;utilisation ou nos pratiques, veuillez nous contacter.
                    </Card.Text>
                    <Card.Text>
                      Si vous avez des préoccupations ou des plaintes concernant la protection de la vie privée ou
                      l&apos;utilisation des informations que nous n&apos;avons pas traitées de manière satisfaisante,
                      veuillez nous contacter.
                    </Card.Text>
                    <Card.Text>
                      Si vous avez des questions ou des préoccupations qui n&apos;ont pas été abordées dans la présente
                      déclaration de confidentialité, vous pouvez nous contacter. Pour nous contacter:{' '}
                      <span className='primary'>contact@oxia-initiative.com</span>
                    </Card.Text>
                    <Form.Group className='checkbox'>
                      <Form.Check
                        label="J'ai lu et j'accepte les conditions."
                        type='checkbox'
                        value={agreementStatus}
                        onChange={handleCheckbox}
                        checked={agreementStatus}
                      />
                    </Form.Group>
                    <button className='primary-button' onClick={updateUserStatus}>
                      Accepter et Continuer
                    </button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(TermsOfAgreementFR);
