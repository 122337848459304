import React, { useState } from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function EditReportRequest(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const nameKey = `name_${currentLanguage}`;

  let { report, reportRequestValue, reportStatuses, submitted } = props;
  let { setReportRequestValue, onChange, onSubmit } = props;
  let [showModal, setShowModal] = useState(false);

  const displayModal = () => {
    setShowModal(true);
    setReportRequestValue({
      id: report.id,
      start_date: report.start_date,
      end_date: report.end_date,
      report_status: report.report_status,
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setReportRequestValue({
      id: '',
      start_date: '',
      end_date: '',
      product: '',
      owner: '',
      reporting_period: '',
      report_status: '',
    });
  };

  const submitEditRequest = () => {
    onSubmit();
    closeModal();
  };

  const checkInputValidation = (value, messageKey) => {
    if (submitted && !value) return <p className='text-danger error-message'>{t(`shared.${messageKey}Required`)}</p>;
    else return <p className='error-message'>&nbsp;</p>;
  };

  return (
    <>
      <Dropdown.Item onClick={displayModal}>{t('shared.editReport')}</Dropdown.Item>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('shared.editReportRequest')}</Modal.Title>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.startDate')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='start_date'
              type='date'
              value={reportRequestValue.start_date}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {checkInputValidation(reportRequestValue.start_date, 'startDate')}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.endDate')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='end_date'
              type='date'
              value={reportRequestValue.end_date}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {checkInputValidation(reportRequestValue.end_date, 'endDate')}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.reportStatus')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select name='report_status' value={reportRequestValue.report_status} onChange={(e) => onChange(e)}>
              <option>{t('shared.selectStatus')}</option>
              {reportStatuses &&
                reportStatuses.map((status, index) => (
                  <option value={status.id} key={index}>
                    {status[nameKey]}
                  </option>
                ))}
            </Form.Select>
            {checkInputValidation(reportRequestValue.report_status, 'reportStatus')}
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button onClick={submitEditRequest} className='primary-button content-right'>
              {t('shared.update')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditReportRequest;
