import React from 'react';
import { Form } from 'react-bootstrap';
import CancelEditForm from './CancelEditForm';
import { useTranslation } from 'react-i18next';

function DisplayEditForm(props) {
  const { t } = useTranslation();
  let { newVal, submitted, onChange, onSubmit } = props;

  return (
    <div className='form'>
      <div className='row justify-content-md-center padding-left mt-1'>
        <div className='col-md-4 width-30 left-row'>
          <Form.Group>
            <Form.Label>
              {t('shared.firstName')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              required
              name='first_name'
              type='text'
              value={newVal.first_name}
              onChange={(e) => onChange(e)}
            />
            {submitted && !newVal.first_name ? (
              <p className='text-danger error-message'>{t('shared.firstNameRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.email')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control name='email' type='email' value={newVal.email} onChange={(e) => onChange(e)} required />
            {submitted && !newVal.email ? (
              <p className='text-danger error-message'>{t('shared.emailRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.addressLine1')}</Form.Label>
            <Form.Control name='address_line1' type='text' value={newVal.address_line1} onChange={(e) => onChange(e)} />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.phoneMaj')}</Form.Label>
            <Form.Control name='phone_number' type='text' value={newVal.phone_number} onChange={(e) => onChange(e)} />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.zip')}</Form.Label>
            <Form.Control name='zip_code' type='text' value={newVal.zip_code} onChange={(e) => onChange(e)} />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.stateCountry')}</Form.Label>
            <Form.Control name='country' type='text' value={newVal.country} onChange={(e) => onChange(e)} />
          </Form.Group>
        </div>
        <div className='col-md-4 width-30'>
          <Form.Group>
            <Form.Label>
              {t('shared.lastName')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control name='last_name' type='text' value={newVal.last_name} onChange={(e) => onChange(e)} />
            {submitted && !newVal.last_name ? (
              <p className='text-danger error-message'>{t('shared.lastNameRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.role')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control name='role' type='text' value={newVal.role} onChange={(e) => onChange(e)} />
            {submitted && !newVal.role ? (
              <p className='text-danger error-message'>{t('shared.roleRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.addressLine2')}</Form.Label>
            <Form.Control name='address_line2' type='text' value={newVal.address_line2} onChange={(e) => onChange(e)} />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.cityMaj')}</Form.Label>
            <Form.Control name='city' type='text' value={newVal.city} onChange={(e) => onChange(e)} />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.stateCountry')}</Form.Label>
            <Form.Control name='region' type='text' value={newVal.region} onChange={(e) => onChange(e)} />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
        </div>
      </div>
      <div className='row justify-content-md-center padding-left padding-bottom mt-1'>
        <div className='col-md-8 width-60'>
          <hr />
          <Form.Group>
            <CancelEditForm />
            <button className='primary-button content-right' onClick={onSubmit}>
              {t('misc.save')}
            </button>
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default DisplayEditForm;
