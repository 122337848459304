import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import DeletePortfolio from '../../Shared/DeleteAction/DeletePortfolio';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import SortingArrows from '../../Shared/SortingArrows';
import CreatePortfolio from '../Portfolios/CreatePortfolio';
import Edit from '../Portfolios/Edit';
import { loadUser, loadInvestees } from '../../../actions/auth';
import { removePathEmptyParams } from '../../../services/helpers';
import line from '../../../images/line.png';

function Portfolio(props) {
  const navigate = useNavigate();
  const headers = { Authorization: 'Token ' + localStorage.token };
  const { t } = useTranslation();
  let itemsPerPage = 25;
  const { user, investees } = props;
  const { loadUser, loadInvestees } = props;
  const [newVal, setNewVal] = useState({
    id: '',
    name: '',
    description: '',
    logo: null,
    status: '',
    allowed_investee_limit: '',
    owner: '',
  });
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [portfolios, setPortfolios] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [sortParameters, setsortParameters] = useState({
    name: '',
    status: '',
    allowed_investee_limit: '',
    noInvestee: '',
    search: '',
  });
  const [showCreateMessage, setShowCreateMessage] = useState(false);

  useEffect(() => {
    fetchPortfolios();
    fetchInvestors();
  }, []);

  useEffect(() => {
    if (portfolios) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(portfolios.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(portfolios.length / itemsPerPage));
    }
  }, [portfolios, itemOffset, itemsPerPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(sortParameters).toString();
    API.get(`api/portfolios?${removePathEmptyParams(queryParams)}`, {
      headers,
      sortParameters,
    }).then((response) => {
      setPortfolios(response.data);
    });
  }, [sortParameters]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % portfolios.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const fetchPortfolios = () => {
    API.get('api/portfolios/', { headers })
      .then((response) => {
        setPortfolios(response.data);
      })
      .catch(function () {
        console.log('Error while loading portfolios.');
      });
  };

  const fetchInvestors = () => {
    API.get(`api/investors/`, { headers })
      .then((response) => {
        setInvestors(response.data);
      })
      .catch(function () {
        console.log('Error while loading investors.');
      });
  };

  const handleChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      logo: event.target.files[0],
    });
  };

  const createFormData = (formData) => {
    formData.append('name', newVal.name);
    formData.append('description', newVal.description);
    formData.append('status', newVal.status);
    formData.append('allowed_investee_limit', newVal.allowed_investee_limit);
    newVal.logo ? formData.append('logo', newVal.logo) : '';
    newVal.logo ? formData.append('logo', newVal.logo) : '';
  };

  const handleSubmit = () => {
    const url = `api/portfolios/${newVal.id}/`;
    const formData = new FormData();
    createFormData(formData);
    formData.append('owner', newVal.owner);

    if (newVal.name && newVal.status && newVal.description.length <= 500) {
      API.put(url, formData, { headers })
        .then(() => {
          setShowMessage(true);
          fetchPortfolios();
        })
        .catch(function () {
          console.log('Error while updating');
        });
    } else {
      setSubmitted(true);
    }
  };

  const createPortfolio = () => {
    const url = `api/portfolios/`;
    const formData = new FormData();
    createFormData(formData);
    formData.append('owner', newVal.owner);

    if (newVal.name && newVal.status && newVal.owner && newVal.description.length <= 500) {
      API.post(url, formData, { headers })
        .then(() => {
          setIsError(false);
          fetchPortfolios();
        })
        .catch(function (response) {
          setIsError(true);
          setMessage(response.response.data);
        });
    } else {
      setSubmitted(true);
    }
  };

  const deletePortfolio = () => {
    const url = `api/portfolios/${newVal.id}/`;
    API.delete(url, { headers })
      .then(() => {
        fetchPortfolios();
        loadInvestees();
      })
      .catch(function () {
        console.log('Error while deleting portfolio');
      });
  };

  const investeeCompaniesCount = (id) => {
    let investeeCount = 0;
    if (investees) {
      for (const investee of investees) {
        if (investee.owner === id) {
          investeeCount++;
        }
      }
    }
    return investeeCount;
  };

  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }

    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  const handleSeachAndFilters = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  const displayPortfolioList = (portfolios) => {
    return portfolios ? (
      <>
        {showCreateMessage && (
          <div className='alert alert-success mt-1 mb-1'>
            {t('shared.portfolio')} {newVal.name} {t('shared.for')} {t('shared.agregator')} {newVal.owner}
            {t('shared.portfolio_created')}
          </div>
        )}
        {showMessage && (
          <div className='alert alert-success mt-1 mb-1'>
            {t('shared.portfolio')} {newVal.name} {t('shared.portfolioUpdate')}
          </div>
        )}
        <table className='table table-link'>
          <thead>
            <tr>
              <th>
                Portfolio name
                <SortingArrows
                  sortParameters={sortParameters}
                  name='name'
                  parametarAsc='name'
                  parametarDesc='-name'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th>Status</th>
              <th>
                {t('shared.noEnterprises')}
                <SortingArrows
                  sortParameters={sortParameters}
                  name='noInvestee'
                  parametarAsc='lowtoHigh'
                  parametarDesc='hightoLow'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th>
                {t('shared.enterpriseLimit')}
                <SortingArrows
                  sortParameters={sortParameters}
                  name='allowed_investee_limit'
                  parametarAsc='allowed_investee_limit'
                  parametarDesc='-allowed_investee_limit'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th>Options</th>
            </tr>
          </thead>

          <tbody>
            {portfolios.map((portfolio, index) => (
              <tr key={index} className='vertical-content main2'>
                <td
                  className='hover'
                  onClick={() =>
                    navigate('/superadmin/portfolios/portfolio-details', {
                      state: { portfolio: portfolio },
                    })
                  }
                >
                  {portfolio.name}
                </td>
                <td
                  onClick={() =>
                    navigate('/superadmin/portfolios/portfolio-details', {
                      state: { portfolio: portfolio },
                    })
                  }
                >
                  {portfolio.status === 'Active' && t('misc.active')}

                  {portfolio.status === 'Pending' && t('misc.pending')}

                  {portfolio.status === 'Closed' && t('misc.closed')}
                </td>
                <td
                  onClick={() =>
                    navigate('/superadmin/portfolios/portfolio-details', {
                      state: { portfolio: portfolio },
                    })
                  }
                >
                  {investeeCompaniesCount(portfolio.id)}
                </td>
                <td>
                  <div className='d-sm-flex align-items-center justify-content-between'>
                    <span
                      onClick={() =>
                        navigate('/superadmin/portfolios/portfolio-details', {
                          state: { portfolio: portfolio },
                        })
                      }
                    >
                      {portfolio.allowed_investee_limit}
                    </span>
                  </div>
                </td>
                <td>
                  <div className='table-action-buttons-edit-delete'>
                    <Edit
                      newVal={newVal}
                      portfolio={portfolio}
                      investors={investors}
                      submitted={submitted}
                      showMessage={showMessage}
                      isError={isError}
                      setNewVal={setNewVal}
                      onChange={handleChange}
                      onFileChange={handleFileChange}
                      onSubmit={handleSubmit}
                      setShowMessage={setShowMessage}
                      setSubmitted={setSubmitted}
                    />
                    <DeletePortfolio
                      button={false}
                      portfolio={portfolio}
                      newVal={newVal}
                      setNewVal={setNewVal}
                      onSubmit={deletePortfolio}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    ) : (
      <span className='text-warning'>No Portfolio in the list</span>
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    return <Navigate to='/terms-of-agreement' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row padding-top'>
                <div className='d-sm-flex align-items-center justify-content-between'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>Portfolios</span>
                  </span>
                </div>
                <div className='search-container'>
                  <Form.Select
                    className='mr-1'
                    name='status'
                    onChange={(e) => {
                      handleSeachAndFilters(e);
                    }}
                    defaultValue=''
                  >
                    <option value=''>{t('shared.sortStatus')}</option>
                    <option value='Active'>{t('misc.active')}</option>
                    <option value='Pending'>{t('misc.pending')}</option>
                    <option value='Closed'>{t('misc.closed')}</option>
                  </Form.Select>
                  <Form.Control
                    name='search'
                    type='text'
                    placeholder={t('search.byNamePlaceholder')}
                    value={sortParameters.search}
                    onChange={(e) => {
                      handleSeachAndFilters(e);
                    }}
                  />
                  <span className='pl-1'>
                    <CreatePortfolio
                      newVal={newVal}
                      investors={investors}
                      submitted={submitted}
                      message={message}
                      isError={isError}
                      showModal={showModal}
                      onChange={handleChange}
                      onFileChange={handleFileChange}
                      onSubmit={createPortfolio}
                      setIsError={setIsError}
                      setShowModal={setShowModal}
                      setNewVal={setNewVal}
                      setSubmitted={setSubmitted}
                      setShowCreateMessage={setShowCreateMessage}
                    />
                  </span>
                </div>
              </div>
              <div className='padding-left'>
                {displayPortfolioList(currentItems)}
                {currentItems?.length === 0 && sortParameters.search !== '' && (
                  <div className='alert alert-warning'>{t('errors.noPortfolio')}</div>
                )}
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
                  {pageCount > 0 ? (
                    <p className='main2'>
                      Page {currentPage} of {pageCount}
                    </p>
                  ) : (
                    ''
                  )}
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel='Previous'
                    renderOnZeroPageCount={null}
                    className='pagination'
                    previousClassName='previous-pagination'
                    nextClassName='next-pagination'
                    activeClassName='active-pagination'
                    disabledClassName='disabled-pagination'
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, { loadUser, loadInvestees })(Portfolio);
