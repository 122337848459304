import React, { useEffect } from 'react';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadUser, loadInvestees } from '../../../actions/auth';
import Report from '../../Shared/ReportAction/Report';
import Notification from './Notification';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';

function Dashboard(props) {
  const { i18n } = useTranslation();
  const current_language = i18n.language;
  let { user, investees } = props;
  let { loadUser, loadInvestees } = props;

  useEffect(() => {
    localStorage.removeItem('reportRequestId');
    localStorage.removeItem('questionsInfo');
    localStorage.removeItem('reportProductId');
    localStorage.removeItem('formId');
  }, []);

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    let termsOfAgreementRoute = `/terms-of-agreement/${current_language}`;
    return <Navigate to={termsOfAgreementRoute} />;
  } else {
    localStorage.setItem('investee', JSON.stringify({ investee: investees[0] }));
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investee={props.investees[0]} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row height-100 mt-1'>
                <Report isAdmin={false} />
                <Notification />
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, { loadUser, loadInvestees })(Dashboard);
