import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { ReactComponent as EditIcon } from '../../../images/svg-icons/edit.svg';
import Select from 'react-select';
import { getDevelopmentGoals } from '../../../services/getDevelopmentGoals';
import { useTranslation } from 'react-i18next';

function EditInvestorCompany(props) {
  const { t } = useTranslation();
  let { newVal, investor, submitted, button } = props;
  let { setNewVal, onChange, onSubmit, setShowMessage, setSubmitted } = props;
  let [showModal, setShowModal] = useState(false);
  let options = getDevelopmentGoals();
  let [selectedOptions, setSelectedOptions] = useState([]);

  const handleOption = (options) => {
    setSelectedOptions(options);
    let goalValues = [];
    for (let i of options) {
      goalValues.push(i.value);
    }
    setNewVal({
      ...newVal,
      development_goals: goalValues.toString(),
    });
  };

  const setSelectedOption = (options) => {
    setNewVal({
      ...newVal,
      aggregator_type: options.target.value,
    });
  };

  const displayModal = () => {
    let devGoalValue = investor.development_goals[0] === '[]' ? '' : investor.development_goals;
    setShowModal(true);
    setNewVal({
      id: investor.id,
      name: investor.name,
      company_email: investor.company_email,
      description: investor.description,
      development_goals: devGoalValue,
      allowed_portfolio_limit: investor.allowed_portfolio_limit,
      aggregator_type: investor.aggregator_type,
      total_allowed_investees_limit: investor.total_allowed_investees_limit,
    });

    let selectedDevGoals = devGoalValue && devGoalValue.map((goal) => ({ value: goal }));

    selectedDevGoals &&
      selectedDevGoals.map((goal, index) => {
        if (selectedDevGoals[index].value === '1') {
          goal.label = t('developmentGoal.label1');
        } else if (selectedDevGoals[index].value === '2') {
          goal.label = t('developmentGoal.label2');
        } else if (selectedDevGoals[index].value === '3') {
          goal.label = t('developmentGoal.label3');
        } else if (selectedDevGoals[index].value === '4') {
          goal.label = t('developmentGoal.label4');
        } else if (selectedDevGoals[index].value === '5') {
          goal.label = t('developmentGoal.label5');
        } else if (selectedDevGoals[index].value === '6') {
          goal.label = t('developmentGoal.label6');
        } else if (selectedDevGoals[index].value === '7') {
          goal.label = t('developmentGoal.label7');
        } else if (selectedDevGoals[index].value === '8') {
          goal.label = t('developmentGoal.label8');
        } else if (selectedDevGoals[index].value === '9') {
          goal.label = t('developmentGoal.label9');
        } else if (selectedDevGoals[index].value === '10') {
          goal.label = t('developmentGoal.label10');
        } else if (selectedDevGoals[index].value === '11') {
          goal.label = t('developmentGoal.label11');
        } else if (selectedDevGoals[index].value === '12') {
          goal.label = t('developmentGoal.label12');
        } else if (selectedDevGoals[index].value === '13') {
          goal.label = t('developmentGoal.label13');
        } else if (selectedDevGoals[index].value === '14') {
          goal.label = t('developmentGoal.label14');
        } else if (selectedDevGoals[index].value === '15') {
          goal.label = t('developmentGoal.label15');
        } else if (selectedDevGoals[index].value === '16') {
          goal.label = t('developmentGoal.label16');
        } else goal.label = t('developmentGoal.label17');
      });
    setSelectedOptions(selectedDevGoals);
  };

  const validateDescription = (desc) => {
    if (desc.length > 500) return true;
    else return false;
  };

  const closeModal = () => {
    setShowModal(false);
    setSubmitted(false);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  return (
    <>
      {button === true ? (
        <button className='primary-button-outlined mr-point5' onClick={displayModal}>
          <EditIcon className='svg-icon' />
          <span>{t('misc.edit')}</span>
        </button>
      ) : (
        <a onClick={displayModal}>
          <EditIcon className='svg-icon mr-1 main2' />
        </a>
      )}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>{t('shared.modalTitle')}</Modal.Title>

        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('shared.company_name')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='name'
              type='text'
              value={newVal.name}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.name ? (
              <p className='text-danger error-message'>{t('shared.company_name_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.company_email')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='company_email'
              type='text'
              value={newVal.company_email}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && !newVal.company_email ? (
              <p className='text-danger error-message'>{t('shared.company_email_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.description')}</Form.Label>
            <Form.Control
              name='description'
              type='text'
              as='textarea'
              rows={3}
              value={newVal.description}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            {submitted && validateDescription(newVal.description) ? (
              <label className='text-danger'>{t('shared.descriptionValidation')}</label>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('shared.developmentGoals')} <span className='text-danger'>*</span>
            </Form.Label>
            <Select
              isMulti
              options={options}
              value={selectedOptions}
              onChange={(e) => handleOption(e)}
              className='development-goal-style'
            />
            {submitted && !newVal.development_goals ? (
              <p className='text-danger error-message'>{t('shared.development_goal_error')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.allowedPortfolioLimitLabel')}</Form.Label>
            <Form.Control
              name='allowed_portfolio_limit'
              type='number'
              value={newVal.allowed_portfolio_limit}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
            <p className='error-message'>&nbsp;</p>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {' '}
              {t('shared.aggregator_type')} <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Select
              className='aggregator-type'
              name='aggregator_type'
              value={newVal.aggregator_type}
              onChange={(e) => setSelectedOption(e)}
            >
              <option value='' disabled selected hidden>
                Select an option
              </option>
              <option value='Investor'> {t('shared.investor')} </option>
              <option value='Consultant'> {t('shared.consultant')} </option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('shared.totalAllowedInvesteesLimitLabel')}</Form.Label>
            <Form.Control
              name='total_allowed_investees_limit'
              type='number'
              value={newVal.total_allowed_investees_limit}
              onChange={(e) => onChange(e)}
              className='style-bottom'
            />
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button
              onClick={() => {
                if (!newVal.name || !newVal.company_email || !newVal.development_goals) {
                  setSubmitted(true);
                  return;
                } else {
                  onSubmit();
                  setShowMessage(true);
                  closeModal();
                }
              }}
              className='primary-button content-right'
            >
              {t('misc.save')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditInvestorCompany;
