import React, { useState, useEffect } from 'react';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { Spinner, Card } from 'react-bootstrap';
import line from '../../../images/line.png';
import DeleteInvesteeCompany from '../../Shared/DeleteAction/DeleteInvesteeCompany';
import { useLocation, useNavigate } from 'react-router-dom';
import DevelopmentGoals from '../../Shared/DevelopmentGoals';
import { connect } from 'react-redux';
import { loadUser, loadInvestees, loadPortfolios } from '../../../actions/auth';
import CompaniesReport from '../Shared/CompaniesReport';
import DisplayInvesteeUsers from '../../Shared/ShowAction/DisplayInvesteeUsers';
import DisplayInvesteeKpis from '../../Shared/ShowAction/DisplayInvesteeKpis';
import DisplayCompanyInfo from '../../Shared/ShowAction/DisplayCompanyInfo';
import EditCompanyInfo from '../../Shared/EditAction/EditCompanyInfo';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import { useTranslation } from 'react-i18next';

function CompanyName(props) {
  const { t } = useTranslation();
  let { user, investees, portfolios } = props;
  let { loadUser, loadInvestees, loadPortfolios } = props;
  const navigate = useNavigate();
  const location = useLocation();
  let { investee, portfolio } = location.state;
  const headers = { Authorization: 'Token ' + localStorage.token };
  localStorage.setItem('investee', JSON.stringify({ investee: investee }));
  let storedValue = JSON.parse(localStorage.getItem('investee'));
  let val = JSON.parse(localStorage.getItem('editedVal'));
  let [investeeVal, setInvesteeVal] = useState({
    id: val ? val.investee.id : storedValue.investee.id,
    name: val ? val.investee.name : storedValue.investee.name,
    company_email: val ? val.investee.company_email : storedValue.investee.company_email,
    company_website: val ? val.investee.company_website : storedValue.investee.company_website,
    investment_amount: val ? val.investee.investment_amount : storedValue.investee.investment_amount,
    description: val ? val.investee.description : storedValue.investee.description,
    development_goals: val ? val.investee.development_goals : storedValue.investee.development_goals,
    company_size: val ? val.investee.company_size : storedValue.investee.company_size,
    naics_category_1: val ? val.investee.naics_category_1 : storedValue.investee.naics_category_1,
    naics_category_2: val ? val.investee.naics_category_2 : storedValue.investee.naics_category_2,
    fiscal_year_end: val ? val.investee.fiscal_year_end : storedValue.investee.fiscal_year_end,
    currency: val ? val.investee.currency : storedValue.investee.currency,
    annual_revenue: val ? val.investee.annual_revenue : storedValue.investee.annual_revenue,
    ebitda: val ? val.investee.ebitda : storedValue.investee.ebitda,
    net_income: val ? val.investee.net_income : storedValue.investee.net_income,
    investee_logo: val ? val.investee.investee_logo : storedValue.investee.investee_logo,
    users: val ? val.investee.users : storedValue.investee.users,
    main_contact: val ? val.investee.main_contact : storedValue.investee.main_contact,
  });
  let [newVal, setNewVal] = useState({
    name: investeeVal.name,
    company_email: investeeVal.company_email,
    company_website: investeeVal.company_website,
    description: investeeVal.description,
    development_goals: investeeVal.development_goals || '',
    company_size: investeeVal.company_size || investeeVal.company_size === 0 ? investeeVal.company_size : '',
    naics_category_1: investeeVal.naics_category_1 || '',
    naics_category_2: investeeVal.naics_category_2 || '',
    fiscal_year_end: investeeVal.fiscal_year_end || '',
    currency: investeeVal.currency || '',
    annual_revenue: investeeVal.annual_revenue || '',
    ebitda: investeeVal.ebitda || '',
    net_income: investeeVal.net_income || '',
    investee_logo: null,
    users: investeeVal.users,
    main_contact: investeeVal.main_contact || '',
  });
  let [showReports, setShowReports] = useState(true);
  let [showInvesteeUsers, setShowInvesteeUsers] = useState(false);
  let [showKpis, setShowKpis] = useState(false);
  let [showInfo, setShowInfo] = useState(false);
  let [isEdit, setIsEdit] = useState(false);
  let [companySize, setCompanySize] = useState({});
  let [naics, setNaics] = useState({});
  let [currency, setCurrency] = useState({});
  let [submitted, setSubmitted] = useState(false);
  let [investeeUsers, setInvesteeUsers] = useState([]);
  let [mainContact, setMainContact] = useState('');

  useEffect(() => {
    return () => {
      localStorage.removeItem('investee');
      localStorage.removeItem('editedVal');
      localStorage.removeItem('members');
    };
  }, []);

  useEffect(() => {
    fetchInvesteeUsers();
  }, [investee]);

  const fetchInvesteeUsers = async () => {
    if (investee.users) {
      let userIDs = investee.users;
      for (const id of userIDs) {
        const response = await API.get(`api/users/${id}/`, { headers });
        setInvesteeUsers((users) => [...users, response.data]);
      }
    }
  };

  useEffect(() => {
    const sizeUrl = 'api/constant_ddls/?name=size';
    const naicsUrl = 'api/constant_ddls/?name=naics';
    const currencyUrl = 'api/constant_ddls/?name=currency';
    API.get(sizeUrl, { headers }).then((response) => {
      setCompanySize(response.data);
    });
    API.get(naicsUrl, { headers }).then((response) => {
      setNaics(response.data);
    });
    API.get(currencyUrl, { headers }).then((response) => {
      setCurrency(response.data);
    });
  }, []);

  useEffect(() => {
    fetchMainContact();
  }, [investeeVal]);

  const fetchMainContact = async () => {
    let id = investeeVal.main_contact;
    if (id) {
      const response = await API.get(`api/users/${id}`, { headers });
      setMainContact(response.data);
    }
  };

  const handleFormChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      investee_logo: event.target.files[0],
    });
  };

  const handleSubmit = () => {
    let users = newVal.users;
    const url = `api/investees/${storedValue.investee.id}/`;
    const formData = new FormData();
    formData.append('name', newVal.name);
    formData.append('company_email', newVal.company_email);
    formData.append('company_website', newVal.company_website);
    formData.append('description', newVal.description);
    formData.append('development_goals', newVal.development_goals);
    formData.append('company_size', newVal.company_size);
    formData.append('naics_category_1', newVal.naics_category_1);
    formData.append('naics_category_2', newVal.naics_category_2);
    formData.append('fiscal_year_end', newVal.fiscal_year_end);
    formData.append('currency', newVal.currency);
    formData.append('annual_revenue', newVal.annual_revenue);
    formData.append('ebitda', newVal.ebitda);
    formData.append('net_income', newVal.net_income);
    newVal.investee_logo ? formData.append('investee_logo', newVal.investee_logo) : '';

    for (var i = 0; i < users.length; i++) {
      formData.append('users', users[i]);
    }
    formData.append('main_contact', newVal.main_contact);

    if (
      (newVal.company_size || newVal.company_size === 0) &&
      newVal.description.length <= 500 &&
      newVal.naics_category_1 &&
      newVal.naics_category_2 &&
      newVal.currency &&
      newVal.annual_revenue &&
      newVal.ebitda &&
      newVal.net_income
    ) {
      API.put(url, formData, { headers })
        .then((response) => {
          localStorage.setItem('editedVal', JSON.stringify({ investee: response.data }));
          setInvesteeVal(response.data);
          setMainContact(response.data.main_contact);
          setIsEdit(false);
        })
        .catch(function () {
          console.log('Error while updating the company');
        });
    } else {
      setSubmitted(true);
    }
  };

  const displayReport = () => {
    setShowReports(true);
    setShowInvesteeUsers(false);
    setShowKpis(false);
    setShowInfo(false);
  };

  const displayInvestee = () => {
    setShowReports(false);
    setShowInvesteeUsers(true);
    setShowKpis(false);
    setShowInfo(false);
  };

  const displayKpis = () => {
    setShowReports(false);
    setShowInvesteeUsers(false);
    setShowKpis(true);
    setShowInfo(false);
  };

  const displayInfo = () => {
    setShowReports(false);
    setShowInvesteeUsers(false);
    setShowKpis(false);
    setShowInfo(true);
  };

  const selectSpecificTab = () => {
    return (
      <div className='padding-left padding-top tabs'>
        {showReports ? (
          <button className='active-tab'>Reports</button>
        ) : (
          <button className='inactive-tab' onClick={displayReport}>
            Reports
          </button>
        )}
        {showInvesteeUsers ? (
          <button className='active-tab'>Members</button>
        ) : (
          <button className='inactive-tab' onClick={displayInvestee}>
            Members
          </button>
        )}
        {showKpis ? (
          <button className='active-tab'>Dashboard</button>
        ) : (
          <button className='inactive-tab' onClick={displayKpis}>
            Dashboard
          </button>
        )}
        {showInfo ? (
          <button className='active-tab'>Information</button>
        ) : (
          <button className='inactive-tab' onClick={displayInfo}>
            Information
          </button>
        )}
      </div>
    );
  };

  const displaySelectedTabDetails = () => {
    return showInvesteeUsers ? (
      <DisplayInvesteeUsers
        investor={null}
        investee={investee}
        portfolio={portfolio}
        idInvestee={storedValue.investee.id}
        url='/superadmin/portfolios/portfolio-details/investee/member'
      />
    ) : showKpis ? (
      <DisplayInvesteeKpis investee={investee} />
    ) : showReports ? (
      <CompaniesReport investees={investees} isAdmin={true} investee={investee} />
    ) : (
      displayCompanyInfoTab(investeeVal)
    );
  };

  const displayCompanyInfoTab = (investee) => {
    return (
      <div className='row padding-left padding-top padding-bottom'>
        {isEdit ? (
          <EditCompanyInfo
            investee={investeeVal}
            newVal={newVal}
            companySize={companySize}
            naics={naics}
            currency={currency}
            investeeUsers={investeeUsers}
            submitted={submitted}
            setSubmitted={setSubmitted}
            setNewVal={setNewVal}
            setIsEdit={setIsEdit}
            setInvesteeUsers={setInvesteeUsers}
            onChange={handleFormChange}
            onFileChange={handleFileChange}
            onSubmit={handleSubmit}
          />
        ) : (
          <DisplayCompanyInfo
            investee={investee}
            companySize={companySize}
            naics={naics}
            currencies={currency}
            mainContact={mainContact}
            setIsEdit={setIsEdit}
          />
        )}
      </div>
    );
  };

  const deleteInvestee = () => {
    const url = `api/investees/${storedValue.investee.id}/`;
    API.delete(url, { headers })
      .then(() => {
        window.location.href = '/superadmin/portfolios';
      })
      .catch(function () {
        console.log('Error while deleting investees');
      });
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!portfolios) {
    loadPortfolios();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <button
                      className='investor-profile-back-button padding-left'
                      onClick={() =>
                        navigate('/superadmin/portfolios/portfolio-details', {
                          state: { portfolio: portfolio },
                        })
                      }
                    >
                      <i className='fas fa-fw fa-arrow-left'></i>
                      <span>Back</span>
                    </button>
                  </span>
                  <span>
                    <DeleteInvesteeCompany
                      button={true}
                      investee={investee}
                      newVal={newVal}
                      setNewVal={setNewVal}
                      onSubmit={deleteInvestee}
                    />
                  </span>
                </div>
                <div>
                  <span className='header-2 padding-left'>{investeeVal.name}</span>
                  <p className='primary padding-left'>{investeeVal.company_email}</p>
                  <p className='primary padding-left'>{investeeVal.company_website}</p>
                </div>
                <div className='d-sm-flex align-items-center justify-content-between company-content padding-right'>
                  <div className='padding-right padding-left width-80'>
                    <h5>DESCRIPTION</h5>
                    <p>{investeeVal.description}</p>
                  </div>
                  <Card className='width-40'>
                    <Card.Body>
                      <Card.Title className='fs-16 black'>
                        {investee.enterprise_type === 'Enterprise'
                          ? t('shared.mandateAmount')
                          : t('shared.investmentAmount')}
                      </Card.Title>
                      <Card.Title className='primary fs-2'>{investeeVal.investment_amount}$</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <DevelopmentGoals tags={investeeVal.development_goals} />
              </div>
              {/* Working with Tabs */}
              {selectSpecificTab()}
              {displaySelectedTabDetails()}
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investees: state.auth.investees,
  portfolios: state.auth.portfolios,
});

export default connect(mapStateToProps, {
  loadUser,
  loadInvestees,
  loadPortfolios,
})(CompanyName);
