import React, { useEffect, useState } from 'react';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from './Sidebar';
import AnswerField from './AnswerField';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadUser, loadAnswerFields, loadQuestions, loadModules } from '../../../actions/auth';
import { Accordion, Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
// import ShareButton from './ShareButton';
import UploadFiles from './UploadFiles';
import { useTranslation } from 'react-i18next';

function QuestionContent(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const title_key = `title_${currentLanguage}`;
  const stem_key = `stem_${currentLanguage}`;
  const explanation_key = `explanation_text_${currentLanguage}`;
  const examples_key = `examples_text_${currentLanguage}`;
  const quote_key = `quote_${currentLanguage}`;
  const source_key = `source_${currentLanguage}`;
  const display_id_key = `display_id_${currentLanguage}`
  const navigate = useNavigate();
  const location = useLocation();
  const headers = { Authorization: 'Token ' + localStorage.token };
  const storedReportId = JSON.parse(localStorage.getItem('reportRequestId'));
  const formId = JSON.parse(localStorage.getItem('formId'));
  const [readyToNavigate, setReadyToNavigate] = useState(false);
  let { user, answerFields, questions, modules } = props;
  let { loadUser, loadAnswerFields, loadQuestions, loadModules } = props;
  let question = location.state.question;
  let openModule = location.state.openModule ? location.state.openModule : '-1';
  let [repeatAnswerRows, setRepeatAnswerRows] = useState(1);
  let [newAnswerValue, setNewAnswerValue] = useState({
    0: { answer_values: {} },
  });
  let [questionsInfo, setQuestionsInfo] = useState([]);
  let [isChecked, setIsChecked] = useState({});
  let [previous, setPrevious] = useState({});
  let [next, setNext] = useState({});
  let [loading, setLoading] = useState(true);
  const [showSumPercantageMess, setShowSumPercantageMess] = useState(false);
  const [questionsMandatory, setQuestionsMandatory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [unansweredMandatoryQuestions, setUnansweredMandatoryQuestions] = useState([]);
  const [showValidationMess, setShowValidationMess] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState('');

  useEffect(() => {
    if (showValidationMess) {
      const timer = setTimeout(() => {
        setShowValidationMess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showValidationMess]);

  useEffect(() => {
    if (showSumPercantageMess) {
      const timer = setTimeout(() => {
        setShowSumPercantageMess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSumPercantageMess]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetchData(signal);

    // Return the cleanup function
    return () => {
      // Cancel the fetch calls when the component unmounts
      controller.abort();
    };
  }, [question]);

  useEffect(() => {
    fetchMandatoryQuestions();
  }, []);

  useEffect(() => {
    if (readyToNavigate && next) {
      displayNextQuestion();
      setReadyToNavigate(false);
    }
  }, [readyToNavigate, next]);

  const fetchLoadingMessages = async () => {
    try {
      const response = await API.get('/api/loading_screen/');
      setLoadingMessages(response.data);
  
      if (response.data.length > 0) {
        selectRandomMessage(response.data);
      }
    } catch (error) {
      console.error('Error fetching loading messages:', error);
    }
  };

  const selectRandomMessage = (messages = loadingMessages) => {
    if (messages.length > 0) {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setSelectedMessage(messages[randomIndex]);
    }
  };

  useEffect(() => {
    fetchLoadingMessages();
  }, []);
  
  useEffect(() => {
    if (loadingMessages.length > 0) {
      selectRandomMessage();
    }
  }, [loadingMessages]);
  
  useEffect(() => {
    selectRandomMessage();
  }, [question]);

  const fetchMandatoryQuestions = async () => {
    const response = await API.get(`api/get_mandatoryQuestions`, { headers });
    setQuestionsMandatory(response.data);
  };
  const fetchData = async (signal, shouldNavigateToNext = false) => {
    try {
      setLoading(true);
      await Promise.all([
        fetchAnswerValueRows(signal),
        fetchQuestionsInfo(signal)
      ]);
      setLoading(false);
      if (shouldNavigateToNext) {
        setReadyToNavigate(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchQuestionsInfo = async (signal) => {
    const response = await API.get(`api/get_questions_info/${storedReportId}`, { headers, signal });
    let dataArray = Object.keys(response.data).map(function (obj) {
      return response.data[obj];
    });
    setQuestionsInfo(dataArray);
    let searchValue = dataArray.find((value) => value.id === question.id);
    setPrevious(searchValue.previous);
    setNext(searchValue.next);
  };

  const fetchAnswerValueRows = async (signal) => {
    if (repeatAnswerRows === 1 && Object.keys(newAnswerValue['0'].answer_values).length === 0) {
      const response = await API.get(
        `api/report_request_question_value_rows/${storedReportId}?question=${question.id}`,
        { headers, signal },
      );
      let dataArray = Object.keys(response.data.answer_value_rows).map(function (obj) {
        return response.data.answer_value_rows[obj];
      });
      if (dataArray.length > 0) {
        setRepeatAnswerRows(1);
        setNewAnswerValue({ 0: { answer_values: {} } });
        dataArray.map((answer, index) => {
          newAnswerValue[index] = { answer_values: answer.answer_values };
          setNewAnswerValue(newAnswerValue);
        });
        setRepeatAnswerRows(dataArray.length);
      }
    } else {
      setRepeatAnswerRows(1);
      setNewAnswerValue({ 0: { answer_values: {} } });
      const response = await API.get(
        `api/report_request_question_value_rows/${storedReportId}?question=${question.id}`,
        { headers, signal },
      );
      let dataArray = Object.keys(response.data.answer_value_rows).map(function (obj) {
        return response.data.answer_value_rows[obj];
      });
      if (dataArray.length > 0) {
        setRepeatAnswerRows(1);
        setNewAnswerValue({ 0: { answer_values: {} } });
        dataArray.map((answer, index) => {
          newAnswerValue[index] = { answer_values: answer.answer_values };
          setNewAnswerValue(newAnswerValue);
        });
        setRepeatAnswerRows(dataArray.length);
      }
    }
  };

const fetchFirstQuestionOfModule = async (moduleId) => {
  const response = await API.get(`api/questions/getFirstModuleQuestion/${moduleId}`, { headers });
  return response.data;
};

  const findModuleID = (id) => {
    let searchValue = modules.find((value) => value.id === id);
    return searchValue.id;
  };

  const findAnswerFields = () => {
    return answerFields.filter((value) => value.question === question.id);
  };

  const addAnswerField = () => {
    repeatAnswerRows++;
    setRepeatAnswerRows(repeatAnswerRows);
    newAnswerValue[repeatAnswerRows - 1] = { answer_values: {} };
    setNewAnswerValue(newAnswerValue);
  };

  const removeAnswerField = () => {
    repeatAnswerRows--;
    setRepeatAnswerRows(repeatAnswerRows);
    delete newAnswerValue[repeatAnswerRows];
  };

  const changeCheckboxInput = (event, index) => {
    let { name, checked } = event.target;
    if (checked) {
      newAnswerValue[index]['answer_values'][name] = 'True';
      setNewAnswerValue(newAnswerValue);
    } else {
      newAnswerValue[index]['answer_values'][name] = 'False';
      setNewAnswerValue(newAnswerValue);
    }
    setIsChecked({
      [index]: {
        answer_values: {
          [name]: !isChecked,
        },
      },
    });
  };

  const changeFormInput = (event, index) => {
    let { name, value } = event.target;

    setNewAnswerValue((prevState) => ({
      ...prevState,
      [index]: {
        answer_values: {
          ...prevState[index]['answer_values'],
          [name]: value,
        },
      },
    }));
  };

  const validateQuestion = () => {
    const searchValue = questionsInfo.find((info) => info.human_id === question.human_id);
    const questions = ['G.26', 'G.28', 'G.32', 'S.6'];
    if (questions.includes(searchValue?.human_id)) {
      return true;
    }
    return false;
  };

  const allRowsMeetCondition = () => {
    return Object.keys(newAnswerValue).every((key) => {
      const sum = Object.values(newAnswerValue[key].answer_values).reduce(
        (acc, value) => acc + parseFloat(value, 10) || 0,
        0,
      );
      return sum === 100;
    });
  };

  const submitAnswerValue = async () => {
    if (validateQuestion()) {
      if (allRowsMeetCondition()) {
        const url = `api/report_request_question_value_rows/${storedReportId}?question=${question.id}`;
        await API.post(url, { answer_value_rows: newAnswerValue }, { headers });
        fetchData(new AbortController().signal, true);
      } else {
        setShowSumPercantageMess(true);
        setTimeout(() => {
          setShowSumPercantageMess(false);
        }, 3000);
      }
    } else {
      const url = `api/report_request_question_value_rows/${storedReportId}?question=${question.id}`;
      await API.post(url, { answer_value_rows: newAnswerValue }, { headers });
      fetchData(new AbortController().signal, true);
    }
  };

  const displayModuleComponent = async (newContent, button) => {
    let moduleId = findModuleID(newContent['item_id'] || newContent);
    let open = openModule;
    if (button === 'next') {
      open = (parseInt(openModule) + 1).toString();
    }
  
    try {
      const firstQuestionOfModule = await fetchFirstQuestionOfModule(moduleId);
      if (firstQuestionOfModule) {
        navigate(`/questionnaire/report-request-${storedReportId}/form-intro/module-${moduleId}/question-${firstQuestionOfModule.id}`, {
          state: {
            openModule: open,
            question: firstQuestionOfModule,
          },
        });
      } else {
        console.error('Failed to fetch the first question of the module');
      }
    } catch (error) {
      console.error('Failed to fetch the first question of the module:', error);
    }
  };

  const displayQuestionComponent = (newContent) => {
    let queId = newContent['item_id'];
    let prevQuestion = questions.find((question) => question.id === queId && question.form === formId);
    navigate(
      `/questionnaire/report-request-${storedReportId}/form-intro/module-${prevQuestion.module}/question-${queId}`,
      {
        state: {
          openModule: openModule,
          question: prevQuestion,
        },
      },
    );
  };

  const displayPreviousQuestion = () => {
    setRepeatAnswerRows(1);
    setNewAnswerValue({ 0: { answer_values: {} } });
    if (previous === '') {
      displayModuleComponent(1);
    } else if (previous['type'] == 'module') {
      displayModuleComponent(previous, 'prev');
    } else {
      displayQuestionComponent(previous);
    }
  };

  const displayNextQuestion = () => {
    setRepeatAnswerRows(1);
    setNewAnswerValue({ 0: { answer_values: {} } });
    if (Object.keys(newAnswerValue[0].answer_values).length === 0) {
      setShowValidationMess(true);
    } else {
      setShowValidationMess(false);
      if (next['type'] == 'module') {
        displayModuleComponent(next, 'next');
      } else if (next['type'] === 'end') {
        window.location.href = '/questionnaire/submit-questionnaire';
      } else {
        displayQuestionComponent(next);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      submitAnswerValue();
    }
  };

  const isQuestionLocked = () => {
    let locked = false;
    let searchValue = questionsInfo.find((info) => info.human_id === question.human_id);
    if (searchValue && searchValue.status === '3_LOCKED') locked = true;
    else locked = false;
    return locked;
  };
  const isMandatoryQuestion = () => {
    const unansweredMandatoryQuestions = questionsInfo.filter(
      (question) =>
        questionsMandatory.some((mandatoryQuestion) => mandatoryQuestion.id === question.id) &&
        question.status !== '1_ANSWERED' &&
        question.status !== '3_LOCKED',
    );
    setUnansweredMandatoryQuestions(unansweredMandatoryQuestions);
    if (unansweredMandatoryQuestions.length > 0) {
      setShowModal(true);
    } else {
      const url = `api/report_request_question_value_rows/${storedReportId}?question=${question.id}`;
      API.post(url, { answer_value_rows: newAnswerValue }, { headers }).then(() => {
        navigate(`/questionnaire/report-request-${storedReportId}/forms-intro`);
      });
    }
  };

  const renderLoadingContent = () => (
    <div className="loading-container">
      {selectedMessage && (
        <div className="loading-message">
          {selectedMessage[quote_key]}
          {selectedMessage[source_key] && <div className="loading-source">- {selectedMessage[source_key]}</div>}
        </div>
      )}
      <Spinner animation='grow' variant='info' className='loader' />
    </div>
  );
  const saveAndExit = () => {
    if (validateQuestion()) {
      if (allRowsMeetCondition()) {
        isMandatoryQuestion();
      } else {
        setShowSumPercantageMess(true);
        setTimeout(() => {
          setShowSumPercantageMess(false);
        }, 3000);
      }
    } else {
      isMandatoryQuestion();
    }
    if (validateQuestion()) {
      const sum = Object.values(newAnswerValue[0].answer_values).reduce(
        (acc, value) => acc + parseFloat(value) || 0,
        0,
      );
      if (sum === 100) {
        isMandatoryQuestion();
      } else {
        setShowSumPercantageMess(true);
        setTimeout(() => {
          setShowSumPercantageMess(false);
        }, 3000);
      }
    } else {
      isMandatoryQuestion();
    }
  };

  const displayQuestionContent = () => {
    return (
      <>
        {showValidationMess && (
          <div className='alert alert-warning mt-1 mb-1 sum-message'>{t('shared.validation_mess')}</div>
        )}
        {showSumPercantageMess && (
          <div className='alert alert-warning mt-1 mb-1 sum-message'>{t('shared.maximum_exceed')}</div>
        )}
        <div className='d-sm-flex align-items-center justify-content-between'>
          <span className='main2'>{question[title_key]}</span>
          {/* <ShareButton /> */}
        </div>
        <h2 className='padding-top question-title'>{question[stem_key]}</h2>
        {question.contains_file_uploads ? (
          <UploadFiles queId={question.id} />
        ) : (
          <AnswerField
            ansFieldList={findAnswerFields()}
            repeatAnswerRows={repeatAnswerRows}
            newAnswerValue={newAnswerValue}
            onChange={changeFormInput}
            handleCheckbox={changeCheckboxInput}
            onKeyPress={handleKeyPress}
            isQuestionLocked={isQuestionLocked()}
          />
        )}
        {question.multiple_answer_rows && !isQuestionLocked() ? (
          <div className='mb-2'>
            <button className='btn btn-addRow text-white' onClick={addAnswerField}>
              {t('questionnaire.addRow')}
            </button>
            <button
              className='btn btn-removeRow text-white ml-1'
              onClick={removeAnswerField}
              disabled={repeatAnswerRows === 1}
            >
              {t('questionnaire.removeRow')}
            </button>
          </div>
        ) : (
          ''
        )}
        <div className='d-sm-flex align-items-center justify-content-between mb-1 position-relative'>
          {loading ? (
            <div id='question-buttons-spinner' className='pulse'>
              {t('questionnaire.loading')}
            </div>
          ) : (
            <>
              <button className='primary-button' onClick={displayPreviousQuestion}>
                <i className='fas fa-fw fa-arrow-left'></i>
                <span>{t('misc.back')}</span>
              </button>
              <span>
                <button className='primary-button-outlined mr-1 bg-white' onClick={saveAndExit}>
                  {t('questionnaire.saveExit')}
                </button>
                {isQuestionLocked() ? (
                  <button onClick={displayNextQuestion} className='primary-button'>
                    {t('questionnaire.saveContinue')}
                  </button>
                ) : (
                  <button onClick={submitAnswerValue} className='primary-button'>
                    {t('questionnaire.saveContinue')}
                  </button>
                )}
              </span>
            </>
          )}
        </div>
        <div className='padding-top'>
          {question[explanation_key] ? (
            <Accordion className='accordion-content-top' defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>{t('questionnaire.explanation')}</Accordion.Header>
                <Accordion.Body>{question[explanation_key]}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : null}
          {question[examples_key] ? (
            <Accordion className='accordion-content-bottom' defaultActiveKey='1'>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>{t('questionnaire.examples')}</Accordion.Header>
                <Accordion.Body>{question[examples_key]}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : null}
        </div>
      </>
    );
  };

  if (!user) {
    loadUser();
    return renderLoadingContent();
  } else if (loading) {
    return renderLoadingContent();
  } else if (!answerFields) {
    loadAnswerFields();
    return renderLoadingContent();
  } else if (!questions) {
    loadQuestions();
    return renderLoadingContent();
  } else if (!modules) {
    loadModules();
    return renderLoadingContent();
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        {showModal && (
          <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Title>{t('questionnaire.mandatoryQuestion')}</Modal.Title>
            <Modal.Body className='padding-top text-center'>
              {unansweredMandatoryQuestions.map((question) => (
                <div key={question.id}>{question[display_id_key]}</div>
              ))}
            </Modal.Body>
          </Modal>
        )}
        {questionsInfo.length == 0 ? renderLoadingContent(
          <div>
          {selectedMessage && (
            <div className="loading-message">
              {selectedMessage.quote_en}
              {selectedMessage.source_en && <div className="loading-source">- {selectedMessage.source_en}</div>}
            </div>
          )}
          <Spinner animation='grow' variant='info' className='loader' />
        </div>
        ) : (
          <div className='container-fluid page-body-wrapper'>
            <Sidebar questionsInfo={questionsInfo} />
            <div className='main-panel'>
              <div className='content-wrapper'>
                <div className='row padding-top padding-bottom justify-content-center mt-1'>
                  <div className='col-lg-10 col-md-10'>{displayQuestionContent()}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  answerFields: state.auth.answerFields,
  questions: state.auth.questions,
  modules: state.auth.modules,
});

export default connect(mapStateToProps, { loadUser, loadQuestions, loadAnswerFields, loadModules })(QuestionContent);
