import React, { useState, useEffect } from 'react';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { Card, Form } from 'react-bootstrap';
import defaultImage from '../../../images/default-image.png';
import InviteMember from '../../Shared/CreateAction/InviteMember';
import EditInvestorCompany from '../CompanyName/EditInvestorCompany';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestees } from '../../../actions/auth';
import DevelopmentGoals from '../../Shared/DevelopmentGoals';
import { Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import SortingArrows from '../../Shared/SortingArrows';
import { removePathEmptyParams } from '../../../services/helpers';

function CompanyName(props) {
  const { t, i18n } = useTranslation();
  const current_language = i18n.language;
  const navigate = useNavigate();
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [userDetails, setUserDetails] = useState([]);
  let itemsPerPage = 10;
  let [currentItems, setCurrentItems] = useState(null);
  let [pageCount, setPageCount] = useState(0);
  let [itemOffset, setItemOffset] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let { user, investors, investees, loadUser, loadInvestors, loadInvestees } = props;
  const storedValue = JSON.parse(localStorage.getItem('investor'));
  let [newVal, setNewVal] = useState({
    name: storedValue ? storedValue.investor.name : '',
    company_email: storedValue ? storedValue.investor.company_email : '',
    description: storedValue ? storedValue.investor.description : '',
    development_goals: storedValue ? storedValue.investor.development_goals : '',
  });
  const [sortParameters, setsortParameters] = useState({
    name: '',
    status: '',
    search: '',
  });
  const [showMessage, setShowMessage] = useState(false);
  let [submitted, setSubmitted] = useState(false);
  let updatedMemberList = JSON.parse(localStorage.getItem('members'));

  const closeMessage = () => {
    setShowMessage(false);
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('members');
    };
  }, []);

  useEffect(() => {
    if (userDetails) {
      helperFunctionForSetCurrentItems(userDetails);
    }
    localStorage.setItem('members', JSON.stringify(userDetails));
  }, [userDetails, itemOffset, itemsPerPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(sortParameters).toString();
    API.get(`api/users/investorMembers?${removePathEmptyParams(queryParams)}`, {
      headers,
      sortParameters,
    }).then((response) => {
      setUserDetails(response.data);
    });
  }, [sortParameters]);

  const helperFunctionForSetCurrentItems = (data) => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(userDetails.length / itemsPerPage));
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userDetails.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }

    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  const handleSeachAndFilters = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const url = `api/investors/${storedValue.investor.id}/`;
    if (newVal.name && newVal.company_email && newVal.description.length <= 500 && newVal.development_goals) {
      API.patch(
        url,
        {
          name: newVal.name,
          company_email: newVal.company_email,
          description: newVal.description,
          development_goals: newVal.development_goals,
        },
        { headers },
      )
        .then((response) => {
          localStorage.setItem('investor', JSON.stringify({ investor: response.data }));
          loadInvestors();
          setShowMessage(true);
        })
        .catch(function () {
          console.log('Error while updating the company');
        });
    } else {
      setSubmitted(true);
    }
  };

  const fetchUserDetails = async () => {
    if (storedValue) {
      const response = await API.get(`api/users/?idInvestee=${storedValue.investor.id}`, { headers });
      setUserDetails(response.data);
    }
  };

  useEffect(() => {
    if (updatedMemberList) {
      setUserDetails(updatedMemberList);
    } else fetchUserDetails();
  }, []);

  const totalInvestmentAmount = () => {
    let sum = 0;
    props.investees.forEach((element) => {
      sum += element.investment_amount;
    });
    return sum;
  };

  const displayInvestorUsers = (users, investor) => {
    return (
      <table className='table table-link'>
        <thead>
          <tr>
            <th>
              {t('misc.name')}
              <SortingArrows
                sortParameters={sortParameters}
                name='name'
                parametarAsc='name'
                parametarDesc='-name'
                handleOnClick={changeSortParameters}
              />
            </th>
            <th>{t('misc.email')}</th>
            <th>{t('investorDashboard.role')}</th>
            <th>{t('shared.status')}</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user, index) => (
              <tr
                onClick={() =>
                  navigate('/investor/company-name/users', {
                    state: { user: user, investor: investor },
                  })
                }
                key={index}
              >
                <td>
                  <span className='nav-profile'>
                    <img src={defaultImage} className='user-img' />
                    <div className='name-block table-name-margin'>
                      <span>{user.first_name.charAt(0).toUpperCase()}</span>
                    </div>
                  </span>
                  <span className='main2'>
                    {user.first_name} {user.last_name}
                  </span>
                </td>
                <td className='primary vertical-content'>{user.email}</td>
                <td className='vertical-content'>{user.role ? user.role : '-'}</td>
                <td>
                  {user.status === 'Active' ? (
                    <button className='active-button'>{t('misc.active')}</button>
                  ) : (
                    <button className='pending-button'>{t('misc.pending')}</button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (user.status === 'Pending') {
    let termsOfAgreementRoute = `/terms-of-agreement/${current_language}`;
    return <Navigate to={termsOfAgreementRoute} />;
  } else {
    let investor = storedValue.investor;

    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          <Sidebar investor={investor} />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>{investor.name}</span>
                  </span>
                  <EditInvestorCompany
                    newVal={newVal}
                    showMessage={showMessage}
                    submitted={submitted}
                    setNewVal={setNewVal}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    setShowMessage={setShowMessage}
                    closeMessage={closeMessage}
                  />
                </div>
                <div>
                  <p className='primary padding-left'>{investor.company_email}</p>
                </div>
                <div className='d-sm-flex align-items-center justify-content-between company-content padding-right'>
                  <div className='padding-right padding-left' style={{ width: '80%' }}>
                    <h5>{t('shared.description')}</h5>
                    <p>{investor.description}</p>
                  </div>
                  <Card style={{ width: '40%' }}>
                    <Card.Body>
                      <Card.Title className='fs-16 black'>
                        {user.type === 'Investor' ? t('shared.investmentAmount') : t('shared.mandateAmount')}
                      </Card.Title>
                      <Card.Title className='primary fs-2'>$ {totalInvestmentAmount()}</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <DevelopmentGoals tags={investor.development_goals} />
              </div>
              <div className='row padding-left padding-top'>
                <div className='d-sm-flex align-items-center justify-content-between page-content'>
                  <h3>{user.type === 'Investor' ? t('shared.investeeMembers') : t('shared.enterpriseMembers')}</h3>
                  <div className='search-container'>
                    <Form.Select
                      className='mr-1'
                      name='status'
                      onChange={(e) => handleSeachAndFilters(e)}
                      defaultValue=''
                    >
                      <option value=''>{t('shared.sortStatus')}</option>
                      <option value='Active'>{t('misc.active')}</option>
                      <option value='Pending'>{t('misc.pending')}</option>
                      <option value=''>{t('shared.na')}</option>
                    </Form.Select>
                    <Form.Control
                      name='search'
                      type='text'
                      placeholder={t('search.byNamePlaceholder')}
                      value={sortParameters.search}
                      onChange={(e) => handleSeachAndFilters(e)}
                    />

                    <InviteMember id={storedValue.investor.id} fetchUserDetails={fetchUserDetails} />
                  </div>
                </div>
                <div>
                  {displayInvestorUsers(currentItems, investor)}
                  {sortParameters.search !== '' && currentItems?.length === 0 && (
                    <div className='alert alert-warning'>{t('errors.noEnterpriseMember')}</div>
                  )}
                  <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
                    {pageCount > 0 ? (
                      <p className='main2'>
                        {t('investorDashboard.pageof', {
                          curPage: currentPage,
                          pageC: pageCount,
                        })}
                      </p>
                    ) : (
                      ''
                    )}
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel={t('shared.nextLabel')}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={t('shared.previousLabel')}
                      renderOnZeroPageCount={null}
                      className='pagination'
                      previousClassName='previous-pagination'
                      nextClassName='next-pagination'
                      activeClassName='active-pagination'
                      disabledClassName='disabled-pagination'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, {
  loadUser,
  loadInvestors,
  loadInvestees,
})(CompanyName);
