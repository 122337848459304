import React from 'react';
import update from 'immutability-helper';
import CreateQuestion from './Shared/CreateQuestion';
import CreateAnswer from './Shared/CreateAnswer';
import DisplayQuestions from './Shared/DisplayQuestions';
import DisplayAnswers from './Shared/DisplayAnswers';
import PreviewPage from './Shared/PreviewPage';

class QuestionTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: {
        module: 'General',
        section: '',
        tags: '',
        human_id: 'G.1',
        title_en: "Company's details",
        stem_en: 'Select the industry sector for your secondary activity (if applicable).',
        explanation_text_en:
          "The different industries' sectors were classified according to the North American Industry Classification System (NAICS).",
        examples_text_en:
          'Name of site: Green Peace Inc. Type of site: Production site. State: Florida. Address: 54990 RED OAK CALLAHAN 340031-5474 City: Orlando Ownership: Leased',
        multiple_answer_rows: '',
        max_answer_rows: '',
        optional: 'No',
        allocation1: 'N/A',
        allocation2: '',
        comments: '',
      },
      answers: {
        queHumanId: 'G.1',
        labelIsHeader: 'No',
      },
      answerField: [
        {
          dataType: '',
          labelEn: '',
          textInfo: '',
          ddlInfo: '',
          booleanInfo: '',
          ansFieldHumanID: 'G.1_A.1',
          refQueId: '',
          minValue: '',
          maxValue: '',
          helpTextEn: '',
          hideQueIfNo: '',
          hideTagIfNo: '',
          hideSectionIfNo: '',
          comments: '',
        },
      ],
      questionList: {},
      answerList: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.addAnswerField = this.addAnswerField.bind(this);
    this.saveAnswerField = this.saveAnswerField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { questions, answers } = this.state;
    this.setState({
      questions: {
        ...questions,
        [name]: value,
      },
      answers: {
        ...answers,
        [name]: value,
      },
    });
  }

  addAnswerField() {
    let { answerField } = this.state;
    let newItem = {
      dataType: '',
      labelEn: '',
      textInfo: '',
      ddlInfo: '',
      booleanInfo: '',
      ansFieldHumanID: '',
      refQueId: '',
      minValue: '',
      maxValue: '',
      helpTextEn: '',
      hideQueIfNo: '',
      hideTagIfNo: '',
      hideSectionIfNo: '',
      comments: '',
    };
    this.setState({
      answerField: [...answerField, newItem],
    });
  }

  saveAnswerField(event, index) {
    const { name, value } = event.target;
    let updateSpec;
    if (name === 'dataType') {
      updateSpec = {
        [index]: {
          dataType: { $set: value },
        },
      };
    } else if (name === 'labelEn') {
      updateSpec = {
        [index]: {
          labelEn: { $set: value },
        },
      };
    } else if (name === 'textInfo') {
      updateSpec = {
        [index]: {
          textInfo: { $set: value },
        },
      };
    } else if (name === 'ddlInfo') {
      updateSpec = {
        [index]: {
          ddlInfo: { $set: value },
        },
      };
    } else if (name === 'booleanInfo') {
      updateSpec = {
        [index]: {
          booleanInfo: { $set: value },
        },
      };
    } else if (name === 'ansFieldHumanID') {
      updateSpec = {
        [index]: {
          ansFieldHumanID: { $set: value },
        },
      };
    } else if (name === 'refQueId') {
      updateSpec = {
        [index]: {
          refQueId: { $set: value },
        },
      };
    } else if (name === 'minValue') {
      updateSpec = {
        [index]: {
          minValue: { $set: value },
        },
      };
    } else if (name === 'maxValue') {
      updateSpec = {
        [index]: {
          maxValue: { $set: value },
        },
      };
    } else if (name === 'helpTextEn') {
      updateSpec = {
        [index]: {
          helpTextEn: { $set: value },
        },
      };
    } else if (name === 'hideQueIfNo') {
      updateSpec = {
        [index]: {
          hideQueIfNo: { $set: value },
        },
      };
    } else if (name === 'hideTagIfNo') {
      updateSpec = {
        [index]: {
          hideTagIfNo: { $set: value },
        },
      };
    } else if (name === 'hideSectionIfNo') {
      updateSpec = {
        [index]: {
          hideSectionIfNo: { $set: value },
        },
      };
    } else if (name === 'comment') {
      updateSpec = {
        [index]: {
          comment: { $set: value },
        },
      };
    } else {
      updateSpec = {
        [index]: {
          attributes: {
            [name]: { $set: value },
          },
        },
      };
    }
    this.setState({ answerField: update(this.state.answerField, updateSpec) });
  }

  submitAnswer() {
    let { answerList, answers, answerField, questionList, questions } = this.state;
    answers.answerField = answerField;
    answerList = answers;
    questionList = questions;
    this.setState({
      answerList,
      questionList,
    });
  }

  handleSubmit() {
    let { questionList, questions } = this.state;
    questionList = questions;
    this.setState({
      questionList,
    });
  }

  render() {
    let { questions, questionList, answers, answerList, answerField } = this.state;

    return (
      <div className='container-scroller'>
        <div className='container-fluid page-body-wrapper'>
          <div className='main-panel' style={{ width: '100%', paddingLeft: '2.375rem' }}>
            <div className='content-wrapper'>
              <div className='text-center'>
                <CreateQuestion questions={questions} onChange={this.handleChange} onSubmit={this.handleSubmit} />
                <CreateAnswer
                  answers={answers}
                  answerField={answerField}
                  onChange={this.handleChange}
                  saveAnswerField={this.saveAnswerField}
                  addAnswerField={this.addAnswerField}
                  onSubmit={this.submitAnswer}
                />
              </div>
              <div className='padding-top'>
                <h2>Questions</h2>
                <DisplayQuestions questionList={questionList} />
              </div>
              <div className='padding-top'>
                <h2>Answers</h2>
                <DisplayAnswers answerList={answerList} />
              </div>
              <div className='padding-top'>
                <hr />
              </div>
              <PreviewPage questionList={questionList} answerList={answerList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionTwo;
