import React from 'react';
import LanguageSwitcher from '../LanguageSwitcher';
import logo from '../../images/oxia-initiative-logo.png';
import { Link } from 'react-router-dom';
import defaultImage from '../../images/default-image.png';
import searchIcon from '../../images/svg-icons/search.svg';
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import Logout from './UserAuthentication/Logout';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/auth';
import { useTranslation } from 'react-i18next';

function Header(props) {
  const { t } = useTranslation();
  const firstLetter = props.user.first_name.charAt(0).toUpperCase();
  let url = '/';

  document.body.classList.add(('user-type-' + props.user.type).toLowerCase().replaceAll(' ', '-'));

  return (
    <nav className='navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
      <div className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'>
        <Link to={url} className='navbar-brand brand-logo'>
          <img src={logo} />
        </Link>
      </div>
      <div className='navbar-menu-wrapper d-flex align-items-center justify-content-end'>
        <ul className='navbar-nav navbar-nav-left mr-lg-2'>
          <li className='nav-item d-lg-block'>
            <Form className='login search-input'>
              <InputGroup>
                <InputGroup.Text id='keyword'>
                  <img src={searchIcon} className='svg-icon' />
                </InputGroup.Text>
                <FormControl type='text' placeholder='Keyword' aria-label='Keyword' aria-describedby='keyword' />
              </InputGroup>
            </Form>
          </li>
        </ul>

        <ul className='navbar-nav navbar-nav-right'>
          <li className='nav-item'>
            <LanguageSwitcher />
          </li>
          <li className='nav-item nav-profile'>
            <Link to='/user-profile'>
              <img src={defaultImage} className='user-profile-img' />
              <div className='name-block header-name-margin'>
                <span>{firstLetter}</span>
              </div>
            </Link>
          </li>
          <li className='nav-item header-bar'>
            <Link to='/user-profile'>
              <span>
                <span className='main2'>{t('header.hey')}</span> {firstLetter + props.user.first_name.slice(1)}
              </span>
            </Link>
          </li>
          <li className='nav-item logout-menu'>
            <Logout />
          </li>
        </ul>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(Header);
