import React, { useEffect, useState } from 'react';
import API from '../../../api';
import { useLocation, NavLink } from 'react-router-dom';
import { Accordion, Spinner, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadAnswerFields } from '../../../actions/auth';
import { useTranslation } from 'react-i18next';

function Sidebar(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  let { answerFields, questionsInfo } = props;
  let { loadAnswerFields } = props;
  const location = useLocation();
  let openModule = location.state ? location.state.openModule : '-1';
  const storedReportId = JSON.parse(localStorage.getItem('reportRequestId'));
  const formId = JSON.parse(localStorage.getItem('formId'));
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [modules, setModules] = useState([]);
  let [questions, setQuestions] = useState([]);
  const display_id_key = `display_id_${currentLanguage}`


  const fetchModules = async () => {
    const response = await API.get(`api/modules/?form=${formId}`, { headers });
    let dataArray = Object.keys(response.data).map(function (obj) {
      return response.data[obj];
    });
    setModules(dataArray);
  };

  const fetchQuestions = async () => {
    const response = await API.get(`api/questions/?form=${formId}`, { headers });
    let dataArray = Object.keys(response.data).map(function (obj) {
      return response.data[obj];
    });
    setQuestions(dataArray);
  };

  useEffect(() => {
    fetchModules();
    fetchQuestions();
  }, []);

  const renderTooltip = (question) => (
    <Tooltip id='button-tooltip'>
      <div>
        {question.title_en} / {question.title_fr}
      </div>
    </Tooltip>
  );

  const displayTooltip = (question) => {
    return (
      <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={renderTooltip(question)}>
        {isQuestionLocked(question.human_id) ? (
          <Button variant='secondary' className='btn btn-sm tooltip-style grey disable-tooltip'>
            ?
          </Button>
        ) : (
          <Button variant='secondary' className='btn btn-sm tooltip-style'>
            ?
          </Button>
        )}
      </OverlayTrigger>
    );
  };

  const handleChange = (event) => {
    event.target.value;
  };

  const isAnswered = (humanId) => {
    let searchValue = questionsInfo.find((info) => info.human_id == humanId);
    if (searchValue && searchValue.status === '1_ANSWERED') return true;
    else return false;
  };

  const isQuestionLocked = (humanId) => {
    let searchValue = questionsInfo.find((info) => info.human_id === humanId);
    if (searchValue && searchValue.status === '3_LOCKED') return true;
    else return false;
  };

  const displayModuleQuestions = (open, module) => {
    return (
      <ul className='nav questionnaire-sidebar'>
        {questions &&
          questions.map((question, index) =>
            question.module == module ? (
              <li key={index} className='nav-item'>
                {
                  <NavLink
                    className={`nav-item d-sm-flex align-items-center justify-content-between page-content 
                  ${({ isActive }) => (isActive ? 'active-questionnaire' : 'inactive-questionnaire')}`}
                    to={
                      '/questionnaire/report-request-' +
                      storedReportId +
                      '/form-intro/module-' +
                      module +
                      '/question-' +
                      question.id
                    }
                    state={{
                      question: question,
                      openModule: open,
                    }}
                  >
                    <label className='checkbox-container'>
                      <input type='checkbox' onChange={handleChange} checked={isAnswered(question.human_id)} />
                      <span className='checkmark'></span>
                      {isQuestionLocked(question.human_id) ? (
                        <span className='grey'>
                          {question[display_id_key] ? question[display_id_key] : question.human_id}
                        </span>
                      ) : (
                        <span>
                          {question[display_id_key] ? question[display_id_key] : question.human_id}
                        </span>
                      )}
                    </label>
                    {displayTooltip(question)}
                  </NavLink>
                }
              </li>
            ) : (
              ''
            ),
          )}
      </ul>
    );
  };

  if (!answerFields) {
    loadAnswerFields();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    questions.sort((prev, next) => prev.weight - next.weight);

    return (
      <nav className='sidebar' id='sidebar'>
        <ul className='nav dashboard-sidebar'>
          <li className='nav-item'>
            <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/investee/dashboard'>
              <span>{t('questionnaire.backHome')}</span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
              to={'/questionnaire/report-request-' + storedReportId + '/forms-intro'}
            >
              <span>{t('questionnaire.intro')}</span>
            </NavLink>
          </li>
        </ul>
        <Accordion className='accordion-top questionnaire-accordion' defaultActiveKey={openModule}>
          {modules &&
            modules.map((module, index) => (
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header>{module.title_en}</Accordion.Header>
                <Accordion.Body>{displayModuleQuestions(index.toString(), module.id)}</Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
        <ul className='nav dashboard-sidebar'>
          <li className='nav-item'>
            <NavLink
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
              to='/questionnaire/submit-questionnaire'
            >
              <span>{t('questionnaire.endQuestionnaire')}</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  answerFields: state.auth.answerFields,
});

export default connect(mapStateToProps, { loadAnswerFields })(Sidebar);
