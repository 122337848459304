import React from 'react';
import Header from '../Header';
import SASidebar from '../../SuperAdmin/Shared/Sidebar';
import InvestorSidebar from '../../Investor/Shared/Sidebar';
import InvesteeSidebar from '../../Investee/Shared/Sidebar';
import { ReactComponent as EditIcon } from '../../../images/svg-icons/edit.svg';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestees } from '../../../actions/auth';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DisplayUserDetails from '../../Shared/ShowAction/DisplayUserDetails';
import { useTranslation } from 'react-i18next';

function UserProfile(props) {
  const { t } = useTranslation();
  let { user, investors, investees } = props;
  let { loadUser, loadInvestors, loadInvestees } = props;
  let navigate = useNavigate();

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          {user.type === 'Investor' ? (
            <InvestorSidebar investor={investors[0]} />
          ) : user.type === 'Investee' ? (
            <InvesteeSidebar investee={investees[0]} />
          ) : (
            <SASidebar />
          )}
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between page-content padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <span className='header-2 padding-left'>
                      {user.first_name} {user.last_name}
                    </span>
                  </span>
                  <button className='primary-button-outlined' onClick={() => navigate('/user-profile/edit')}>
                    <EditIcon className='svg-icon mr-point2' />
                    <span>{t('shared.editProfil')}</span>
                  </button>
                </div>
              </div>
              {/* Displaying the user details */}
              <DisplayUserDetails userDetails={user} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, { loadUser, loadInvestors, loadInvestees })(UserProfile);
