import React, { useEffect, useState } from 'react';
import API from '../../../api';
import CreateReportRequest from '../../Shared/CreateAction/CreateReportRequest';
import EditReportRequest from '../../Shared/EditAction/EditReportRequest';
import { ListGroup, Dropdown, Spinner, Badge } from 'react-bootstrap';
import { ReactComponent as ShareIcon } from '../../../images/svg-icons/share.svg';
import FileUpload from '../Shared/FileUpload';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadProducts, loadReportingPeriods, loadReportStatuses } from '../../../actions/auth';
import { ThreeDots } from '../../Shared/ThreeDots';
import FilterReport from '../../Shared/ReportAction/FilterReport';
import ReactPaginate from 'react-paginate';
import { AuthenticatedLink } from '../../Shared/ReportAction/AuthenticatedLink';
import DeleteReport from '../../Shared/DeleteAction/DeleteReport';

function CompaniesReport(props) {
  let { products, reportingPeriods, reportStatuses, isAdmin, isInvestor, investee, investor } = props;
  let { loadProducts, loadReportingPeriods, loadReportStatuses } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  let navigate = useNavigate();
  let [newVal, setNewVal] = useState({
    uploaded_file: null,
    description: '',
    report_request: '',
  });
  let [submitted, setSubmitted] = useState(false);
  let [files, setFiles] = useState('');
  let [reportRequestValue, setReportRequestValue] = useState({
    id: '',
    start_date: '',
    end_date: '',
    product: '',
    owner: '',
    reporting_period: '',
    report_status: '',
  });
  let [reportList, setReportList] = useState([]);
  let itemsPerPage = 8;
  let [currentItems, setCurrentItems] = useState(null);
  let [pageCount, setPageCount] = useState(0);
  let [itemOffset, setItemOffset] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [buttonDisable, setButtonDisable] = useState(false);
  let [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (reportList) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(reportList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(reportList.length / itemsPerPage));
    }
  }, [reportList, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reportList.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const fetchReportRequests = async () => {
    if (investee) {
      let response = await API.get('api/report_requests/?report_status=1', {
        headers,
      });
      let searchValue = response.data.filter((value) => value.owner === investee.id);
      setReportList(searchValue);
    } else {
      let response = await API.get(`api/report_requests/?report_status=1&&investor_id=${investor.id}`, { headers });
      setReportList(response.data);
    }
  };

  const fetchData = () => {
    API.get(`api/upload_file/`, { headers })
      .then((response) => {
        setFiles(response.data);
      })
      .catch(function () {
        console.log('Error while loading file upload');
      });
  };

  useEffect(() => {
    fetchReportRequests();
    fetchData();
  }, []);

  const findProductName = (value) => {
    let searchValue = products.find((product) => product.id === value);
    return searchValue.name_en;
  };

  const displayProductPeriodAndName = (report) => {
    const productPeriod = reportingPeriods.find((period) => period.id === report.reporting_period);
    const product = products.find((product) => product.id === report.product);
    let productPeriodValue = productPeriod.name_en;
    let productValue = product.name_en;

    if (productPeriod.name_en.includes(' ')) {
      // Take product period without short name from the previous generated reports that were like "CFR 2020"
      productPeriodValue = productPeriod.name_en.split(' ')[1];
    }

    if (product.name_en.includes(' ')) {
      // Take product name without additional parentheses
      productValue = product.name_en.split('(')[0];
    }

    return (
      <>
        <span className='primary'>
          {productPeriodValue} {product.code}
        </span>
        <span className='mx-3 fw-bold'>{productValue}</span>
      </>
    );
  };

  const findReportStatus = (value) => {
    let searchValue = reportStatuses.find((status) => status.id === value);
    return (
      <Badge pill bg='secondary' className='mr-1'>
        {searchValue.name_en}
      </Badge>
    );
  };

  const findOwnerName = (value) => {
    let searchValue = props.investees.find((investee) => investee.id === value);
    return searchValue.name;
  };

  const handleReportRequest = (event) => {
    const { name, value } = event.target;
    setReportRequestValue({
      ...reportRequestValue,
      [name]: value,
    });
  };

  const createReportRequest = () => {
    const url = 'api/report_requests/';

    if (
      reportRequestValue.product &&
      reportRequestValue.start_date &&
      reportRequestValue.end_date &&
      reportRequestValue.owner &&
      reportRequestValue.reporting_period &&
      reportRequestValue.report_status
    ) {
      API.post(
        url,
        {
          product: reportRequestValue.product,
          start_date: reportRequestValue.start_date,
          end_date: reportRequestValue.end_date,
          owner: reportRequestValue.owner,
          reporting_period: reportRequestValue.reporting_period,
          report_status: Number(reportRequestValue.report_status),
        },
        { headers },
      )
        .then(() => {
          fetchReportRequests();
        })
        .catch(function () {
          console.log('Error while creating report request');
        });
    } else {
      setSubmitted(true);
    }
  };

  const updateReportRequest = () => {
    const url = `api/report_requests/${reportRequestValue.id}/`;

    if (reportRequestValue.start_date && reportRequestValue.end_date && reportRequestValue.report_status) {
      API.put(
        url,
        {
          start_date: reportRequestValue.start_date,
          end_date: reportRequestValue.end_date,
          report_status: reportRequestValue.report_status,
        },
        { headers },
      )
        .then(() => {
          fetchReportRequests();
        })
        .catch(function () {
          console.log('Error while updating report request');
        });
    } else {
      setSubmitted(true);
    }
  };

  const deleteReport = (id) => {
    const url = `api/report_requests/${id}/`;
    API.delete(url, { headers }).then(() => {
      fetchReportRequests();
    });
  };

  const displayReports = (reports) => {
    return (
      <ListGroup>
        {reports &&
          reports.map((report, index) => (
            <ListGroup.Item key={index}>
              <div className='d-sm-flex align-items-center justify-content-between'>
                <div>{displayProductPeriodAndName(report)}</div>
                <table>
                  <tbody>
                    <tr>
                      <td>{findReportStatus(report.report_status)}</td>
                      <td className='report-enter-data-button'>
                        <a
                          className='report-questionnaire'
                          onClick={() =>
                            navigate(`/questionnaire/report-request-${report.id}/forms-intro`, {
                              state: {
                                openModule: '',
                                reportRequestId: report.id,
                              },
                            })
                          }
                        >
                          <ShareIcon className='svg-icon' />
                        </a>
                      </td>
                      <td>
                        <Dropdown drop='start' className='report-dropdown ml-1'>
                          <Dropdown.Toggle as={ThreeDots} />
                          <Dropdown.Menu>
                            <Dropdown.Header>Options</Dropdown.Header>
                            {isAdmin ? (
                              <>
                                <FileUpload
                                  newVal={newVal}
                                  report={report}
                                  showModal={showModal}
                                  submitted={submitted}
                                  buttonDisable={buttonDisable}
                                  setNewVal={setNewVal}
                                  setShowModal={setShowModal}
                                  onFileChange={handleFileChange}
                                  onChange={handleFileUploadChange}
                                  onSubmit={handleSubmit}
                                  setButtonDisable={setButtonDisable}
                                  setSubmitted={setSubmitted}
                                />
                                <EditReportRequest
                                  report={report}
                                  reportRequestValue={reportRequestValue}
                                  submitted={submitted}
                                  reportStatuses={reportStatuses}
                                  setReportRequestValue={setReportRequestValue}
                                  onChange={handleReportRequest}
                                  onSubmit={updateReportRequest}
                                />
                                <DeleteReport
                                  product={findProductName(report.product)}
                                  owner={findOwnerName(report.owner)}
                                  onSubmit={() => deleteReport(report.id)}
                                  type={'Delete Report'}
                                />
                              </>
                            ) : (
                              ''
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='mt-1'>
                <div>
                  <span className='fw-bold'>{findOwnerName(report.owner)}</span>
                </div>
                <div>
                  <span className='main2'>Start date: </span>
                  {report.start_date}
                </div>
                <div>
                  <span className='main2'>End date: </span>
                  {report.end_date}
                </div>
              </div>
              <div className='mt-1'>
                {displayUploadFiles(report)}
                {isInvestor ? (
                  ''
                ) : (
                  <button
                    className='primary-button-outlined bg-white mb-1 report-enter-data-button'
                    onClick={() =>
                      navigate(`/questionnaire/report-request-${report.id}/forms-intro`, {
                        state: {
                          openModule: '',
                          reportRequestId: report.id,
                          product: report.product,
                        },
                      })
                    }
                  >
                    Enter the required data
                  </button>
                )}
              </div>
            </ListGroup.Item>
          ))}
      </ListGroup>
    );
  };

  const reportRequestColumns = (reports) => {
    const leftReports = reports && reports.slice().splice(0, 4);
    const rightReports = reports && reports.slice().splice(4);

    return (
      <div className='row padding-top'>
        <div className='col-md-6'>{displayReports(leftReports)}</div>
        <div className='col-md-6'>{displayReports(rightReports)}</div>
      </div>
    );
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      uploaded_file: event.target.files[0],
      description: event.target.files[0].name,
    });
  };

  const handleFileUploadChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const url = 'api/upload_file/';
    const formData = new FormData();
    formData.append('uploaded_file', newVal.uploaded_file);
    formData.append('description', newVal.description);
    formData.append('report_request', newVal.report_request);
    if (newVal.uploaded_file && newVal.report_request) {
      API.post(url, formData, { headers })
        .then(() => {
          setButtonDisable(true);
          setShowModal(false);
          fetchData();
        })
        .catch(function () {
          console.log('Error while updating');
        });
    } else {
      setSubmitted(true);
    }
  };

  const displayUploadFiles = (report) => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/upload_file`;
    const filesFromReport = files && files.filter((file) => file.report_request === report.id);

    if (filesFromReport && filesFromReport.length > 0) {
      return (
        <ul className='file-upload-list'>
          Files
          {filesFromReport.map((filteredFiles, index) => (
            <li key={index}>
              <AuthenticatedLink url={URL + '/' + filteredFiles.id + '/'} filename={filteredFiles.description}>
                <button className='uploaded-file'>{filteredFiles.description}</button>
              </AuthenticatedLink>
            </li>
          ))}
        </ul>
      );
    } else {
      return '';
    }
  };

  if (!products) {
    loadProducts();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!reportingPeriods) {
    loadReportingPeriods();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!reportStatuses) {
    loadReportStatuses();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <>
        <div className='row padding-left padding-top'>
          <div className='d-sm-flex align-items-center justify-content-between page-content'>
            <div>
              <span className='header-4 mr-1'>Reports</span>
              <FilterReport
                headers={headers}
                reportingPeriods={reportingPeriods}
                products={products}
                state={false}
                reportStatuses={reportStatuses}
                investee={investee}
                owner={investee ? investee.id : ''}
                investorId={!investee ? investor.id : ''}
                setItems={setReportList}
                fetchItems={fetchReportRequests}
              />
            </div>
            {investee ? (
              <CreateReportRequest
                products={products}
                investee={investee ? investee : ''}
                reportRequestValue={reportRequestValue}
                reportingPeriods={reportingPeriods}
                submitted={submitted}
                setReportRequestValue={setReportRequestValue}
                onChange={handleReportRequest}
                onSubmit={createReportRequest}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='padding-left'>
          {reportRequestColumns(currentItems)}
          <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
            {pageCount > 0 ? (
              <p className='main2'>
                Page {currentPage} of {pageCount}
              </p>
            ) : (
              ''
            )}
            <ReactPaginate
              breakLabel='...'
              nextLabel='Next'
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='Previous'
              renderOnZeroPageCount={null}
              className='pagination'
              previousClassName='previous-pagination'
              nextClassName='next-pagination'
              activeClassName='active-pagination'
              disabledClassName='disabled-pagination'
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.auth.products,
  reportingPeriods: state.auth.reportingPeriods,
  reportStatuses: state.auth.reportStatuses,
});

export default connect(mapStateToProps, {
  loadProducts,
  loadReportingPeriods,
  loadReportStatuses,
})(CompaniesReport);
