export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAIL = 'PASSWORD_CHANGE_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const INVESTORS_LOADED = 'INVESTORS_LOADED';
export const INVESTEES_LOADED = 'INVESTEES_LOADED';
export const PORTFOLIOS_LOADED = 'PORTFOLIOS_LOADED';
export const INVESTOR_DATA_SUMMARY_LOADED = 'INVESTOR_DATA_SUMMARY_LOADED';
export const OXIA_ADMIN_DATA_SUMMARY_LOADED = 'OXIA_ADMIN_DATA_SUMMARY_LOADED';

export const FORMS_LOADED = 'FORMS_LOADED';
export const MODULES_LOADED = 'MODULES_LOADED';
export const SECTIONS_LOADED = 'SECTIONS_LOADED';
export const QUESTIONS_LOADED = 'QUESTIONS_LOADED';
export const ANSWER_FIELDS_LOADED = 'ANSWER_FIELDS_LOADED';

export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const REPORT_STATUSES_LOADED = 'REPORT_STATUSES_LOADED';
export const REPORTING_PERIODS_LOADED = 'REPORTING_PERIODS_LOADED';
export const REPORTS_GENERATED = 'REPORTS_GENERATED';

export const SET_LANGUAGE = 'SET_LANGUAGE';
