import React from 'react';
import { useTranslation } from 'react-i18next';

function TimeAgo(props) {
  const { t } = useTranslation();
  let { dateTime } = props;

  const timeAgo = (prevDate) => {
    let diff = Number(new Date()) - prevDate;
    let seconds = '';
    let minute = 60 * 1000;
    let hour = minute * 60;
    let day = hour * 24;
    let month = day * 30;
    let year = day * 365;
    switch (true) {
      case diff < minute:
        seconds = Math.round(diff / 1000);
        return `${seconds} ${seconds > 1 ? t('shared.seconds') : t('shared.second')} ${t('shared.ago')}`;
      case diff < hour:
        return Math.round(diff / minute) + ` ${t('shared.minutes')} ${t('shared.ago')}`;
      case diff < day:
        return Math.round(diff / hour) + ` ${t('shared.hours')} ${t('shared.ago')}`;
      case diff < month:
        return Math.round(diff / day) + ` ${t('shared.days')} ${t('shared.ago')}`;
      case diff < year:
        return Math.round(diff / month) + ` ${t('shared.months')} ${t('shared.ago')}`;
      case diff > year:
        return Math.round(diff / year) + ` ${t('shared.years')} ${t('shared.ago')}`;
      default:
        return '';
    }
  };

  return <div className='small text-muted'>{timeAgo(new Date(dateTime).getTime())}</div>;
}

export default TimeAgo;
