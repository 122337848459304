import React, { useEffect, useState } from 'react';
import API from '../../../api';
import Header from '../Header';
import SASidebar from '../../SuperAdmin/Shared/Sidebar';
import InvestorSidebar from '../../Investor/Shared/Sidebar';
import InvesteeSidebar from '../../Investee/Shared/Sidebar';
import defaultImage from '../../../images/default-image.png';
import { Spinner } from 'react-bootstrap';
import line from '../../../images/line.png';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestees } from '../../../actions/auth';
import DisplayEditForm from './DisplayEditForm';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EditUser(props) {
  const { t } = useTranslation();
  let { user, investors, investees } = props;
  let { loadUser, loadInvestors, loadInvestees } = props;
  const navigate = useNavigate();
  let [newVal, setNewVal] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    address_line1: '',
    address_line2: '',
    phone_number: '',
    city: '',
    zip_code: '',
    region: '',
    country: '',
  });
  let [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (!user) {
      loadUser();
      return <Spinner animation='grow' variant='info' className='loader' />;
    } else {
      setNewVal({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role: user.role,
        address_line1: user.address_line1,
        address_line2: user.address_line2,
        phone_number: user.phone_number,
        city: user.city,
        zip_code: user.zip_code,
        region: user.region,
        country: user.country,
      });
    }
  }, [user]);

  const handleChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const headers = { Authorization: 'Token ' + localStorage.token };
    const url = `api/users/${user.id}/`;

    if (newVal.first_name && newVal.last_name && newVal.email && newVal.role) {
      API.put(
        url,
        {
          first_name: newVal.first_name,
          last_name: newVal.last_name,
          email: newVal.email,
          role: newVal.role,
          address_line1: newVal.address_line1,
          address_line2: newVal.address_line2,
          phone_number: newVal.phone_number,
          city: newVal.city,
          zip_code: newVal.zip_code,
          region: newVal.region,
          country: newVal.country,
          type: user.type,
          username: user.email,
        },
        { headers },
      )
        .then(() => {
          window.location.href = '/user-profile';
        })
        .catch(function () {
          console.log('Error while updating');
        });
    } else {
      setSubmitted(true);
    }
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        <div className='container-fluid page-body-wrapper'>
          {user.type === 'Investor' ? (
            <InvestorSidebar investor={investors[0]} />
          ) : user.type === 'Investee' ? (
            <InvesteeSidebar investee={investees[0]} />
          ) : (
            <SASidebar />
          )}
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between page-content padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <button
                      className='investor-profile-back-button padding-left'
                      onClick={() => navigate('/user-profile')}
                    >
                      <i className='fas fa-fw fa-arrow-left'></i>
                      <span>{t('misc.back')}</span>
                    </button>
                    <br />
                    <span className='header-2 padding-left'>
                      {user.first_name} {user.last_name}
                    </span>
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='page-content text-center'>
                  <img src={defaultImage} className='investor-profile-img' />
                </div>
              </div>
              <DisplayEditForm newVal={newVal} submitted={submitted} onChange={handleChange} onSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, { loadUser, loadInvestors, loadInvestees })(EditUser);
