import React, { useState, useEffect } from 'react';
import API from '../../../api';
import Header from '../../Shared/Header';
import Sidebar from '../Shared/Sidebar';
import { Spinner, Card } from 'react-bootstrap';
import line from '../../../images/line.png';
import EditCompanyDetails from '../../Shared/EditAction/EditInvestorCompany';
import DeleteInvestorCompany from '../../Shared/DeleteAction/DeleteInvestorCompany';
import { useLocation, useNavigate } from 'react-router-dom';
import DevelopmentGoals from '../../Shared/DevelopmentGoals';
import { connect } from 'react-redux';
import { loadUser, loadInvestors, loadInvestees } from '../../../actions/auth';
import CompaniesReport from '../Shared/CompaniesReport';
import DisplayInvestorUsers from '../../Shared/ShowAction/DisplayInvestorUsers';
import DisplayInvestorKpis from '../../Shared/ShowAction/DisplayInvestorKpis';
import DisplayInvestorPortfolios from '../../Shared/ShowAction/DisplayInvestorPortfolios';
import ButtonScrollUp from '../../Shared/ButtonScrollUp';
import { useTranslation } from 'react-i18next';

function CompanyDetails(props) {
  const { t } = useTranslation();
  let { user, investors, investees } = props;
  let { loadUser, loadInvestors, loadInvestees } = props;
  const navigate = useNavigate();
  const location = useLocation();
  let investor = location.state.investor;
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [newVal, setNewVal] = useState({
    name: '',
    company_email: '',
    description: '',
    development_goals: '',
    allowed_portfolio_limit: '',
    aggregator_type: '',
    total_allowed_investees_limit: '',
  });
  let [submitted, setSubmitted] = useState(false);
  let [showMessage, setShowMessage] = useState(false);
  let [showReports, setShowReports] = useState(true);
  let [showInvestorUsers, setShowInvestorUsers] = useState(false);
  let [showKpis, setShowKpis] = useState(false);
  let [showPortfolios, setShowPortfolios] = useState(false);

  useEffect(() => {
    return () => {
      localStorage.removeItem('members');
    };
  }, []);

  const changeFormInput = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = () => {
    const url = `api/investors/${investor.id}/`;
    if (newVal.name && newVal.company_email && newVal.development_goals) {
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      API.put(
        url,
        {
          name: newVal.name,
          company_email: newVal.company_email,
          description: newVal.description,
          development_goals: newVal.development_goals,
          allowed_portfolio_limit: newVal.allowed_portfolio_limit,
          aggregator_type: newVal.aggregator_type,
          total_allowed_investees_limit: newVal.total_allowed_investees_limit,
        },
        { headers },
      )
        .then(() => {
          investor.aggregator_type = newVal.aggregator_type;
          navigate('/superadmin/investor-companies/company-details', { state: { investor: investor } });
        })
        .catch(function () {
          console.log('Error while updating the company');
        });
    } else {
      setSubmitted(true);
    }
  };

  const deleteInvestor = () => {
    const url = `api/investors/${newVal.id}/`;
    API.delete(url, { headers })
      .then(() => {
        window.location.href = '/superadmin/investor-companies';
      })
      .catch(function () {
        console.log('Error while deleting investors');
      });
  };

  const displayReport = () => {
    setShowReports(true);
    setShowInvestorUsers(false);
    setShowKpis(false);
    setShowPortfolios(false);
  };

  const displayInvestee = () => {
    setShowReports(false);
    setShowInvestorUsers(true);
    setShowKpis(false);
    setShowPortfolios(false);
  };

  const displayKpis = () => {
    setShowReports(false);
    setShowInvestorUsers(false);
    setShowKpis(true);
    setShowPortfolios(false);
  };

  const displayPortfolios = () => {
    setShowReports(false);
    setShowInvestorUsers(false);
    setShowKpis(false);
    setShowPortfolios(true);
  };

  const selectSpecificTab = () => {
    return (
      <div className='padding-left padding-top tabs'>
        {showReports ? (
          <button className='active-tab'>Reports</button>
        ) : (
          <button className='inactive-tab' onClick={displayReport}>
            Reports
          </button>
        )}
        {showInvestorUsers ? (
          <button className='active-tab'>Members</button>
        ) : (
          <button className='inactive-tab' onClick={displayInvestee}>
            Members
          </button>
        )}
        {showKpis ? (
          <button className='active-tab'>Dashboard</button>
        ) : (
          <button className='inactive-tab' onClick={displayKpis}>
            Dashboard
          </button>
        )}
        {showPortfolios ? (
          <button className='active-tab'>Portfolio</button>
        ) : (
          <button className='inactive-tab' onClick={displayPortfolios}>
            Portfolio
          </button>
        )}
      </div>
    );
  };

  const displaySelectedTabDetails = () => {
    return showInvestorUsers ? (
      <DisplayInvestorUsers investor={investor} url='/superadmin/investor-companies/company-details/member' />
    ) : showKpis ? (
      <DisplayInvestorKpis investor={investor} />
    ) : showPortfolios ? (
      <DisplayInvestorPortfolios investor={investor} />
    ) : (
      <CompaniesReport investees={investees} isAdmin={true} investee={null} investor={investor} />
    );
  };

  if (!user) {
    loadUser();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investors) {
    loadInvestors();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else if (!investees) {
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='container-scroller'>
        <Header />
        {showMessage && (
          <div className='alert alert-success'>
            {t('shared.agregator')} {newVal.name} {t('shared.agregator_updated')}
          </div>
        )}
        <div className='container-fluid page-body-wrapper'>
          <Sidebar />
          <div className='main-panel'>
            <div className='content-wrapper'>
              <div className='row page-title'>
                <div className='d-sm-flex align-items-center justify-content-between padding-top padding-right'>
                  <span>
                    <img src={line} className='heading-line' />
                    <button
                      className='investor-profile-back-button padding-left'
                      onClick={() => navigate('/superadmin/investor-companies')}
                    >
                      <i className='fas fa-fw fa-arrow-left'></i>
                      <span>Back</span>
                    </button>
                  </span>
                  <span>
                    <EditCompanyDetails
                      newVal={newVal}
                      investor={investor}
                      submitted={submitted}
                      button={true}
                      showMessage={showMessage}
                      onChange={changeFormInput}
                      onSubmit={handleFormSubmit}
                      setNewVal={setNewVal}
                      setShowMessage={setShowMessage}
                      setSubmitted={setSubmitted}
                    />
                    <DeleteInvestorCompany
                      button={true}
                      investor={investor}
                      newVal={newVal}
                      setNewVal={setNewVal}
                      onSubmit={deleteInvestor}
                    />
                  </span>
                </div>
                <div>
                  <span className='header-2 padding-left'>{investor.name}</span>
                  <p className='primary padding-left'>{investor.company_email}</p>
                </div>
                <div className='d-sm-flex align-items-center justify-content-between company-content padding-right'>
                  <div className='padding-right padding-left width-80'>
                    <h5>DESCRIPTION</h5>
                    <p>{investor.description}</p>
                  </div>
                  <Card className='width-40'>
                    {investor.aggregator_type === 'Consultant' ? (
                      <Card.Body>
                        <Card.Title className='fs-16 black'>{t('shared.consultantAmount')}</Card.Title>
                        <Card.Title className='primary fs-2'>$ 0</Card.Title>
                      </Card.Body>
                    ) : (
                      <Card.Body>
                        <Card.Title className='fs-16 black'>{t('shared.investeeAmount')}</Card.Title>
                        <Card.Title className='primary fs-2'>$ 0</Card.Title>
                      </Card.Body>
                    )}
                  </Card>
                </div>
                <DevelopmentGoals tags={investor.development_goals} />
              </div>
              {/* Working with Tabs */}
              {selectSpecificTab()}
              {displaySelectedTabDetails()}
            </div>
          </div>
          <ButtonScrollUp />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  investors: state.auth.investors,
  investees: state.auth.investees,
});

export default connect(mapStateToProps, {
  loadUser,
  loadInvestors,
  loadInvestees,
})(CompanyDetails);
