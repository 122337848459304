import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadInvestees, loadPortfolios } from '../../../actions/auth';
import { Spinner, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import SortingArrows from '../../Shared/SortingArrows';
import Form from 'react-bootstrap/Form';

function DisplayInvestorPortfolios(props) {
  const { t } = useTranslation();
  const [sortParameters, setsortParameters] = useState({
    name: '',
    status: '',
    allowed_investee_limit: '',
    noInvestee: '',
    search: '',
  });

  let { investees, portfolios, investor } = props;
  let { loadInvestees, loadPortfolios } = props;
  let [investorPortfolios, setInvestorPortfolios] = useState([]);
  const navigate = useNavigate();
  let itemsPerPage = 10;
  let [currentItems, setCurrentItems] = useState(null);
  let [pageCount, setPageCount] = useState(0);
  let [itemOffset, setItemOffset] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (investorPortfolios) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(investorPortfolios.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(investorPortfolios.length / itemsPerPage));
    }
  }, [investorPortfolios, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % investorPortfolios.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const handleSeachAndFilters = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  const fetchInvestorPortfolios = () => {
    if (!portfolios) {
      loadPortfolios();
      return <Spinner animation='grow' variant='info' className='loader' />;
    } else {
      let searchValue = portfolios.filter((portfolio) => portfolio.owner === investor.id);

      if (sortParameters.status !== '') {
        searchValue = searchValue.filter((portfolio) => portfolio.status === sortParameters.status);
      }

      if (sortParameters.search !== '') {
        searchValue = searchValue.filter((portfolio) =>
          portfolio.name.toLowerCase().includes(sortParameters.search.toLowerCase()),
        );
      }
      // Sort the portfolios based on the sortParameters
      searchValue.sort((a, b) => {
        for (let key in sortParameters) {
          if (sortParameters[key] !== '') {
            if (key === 'noInvestee') {
              // Special case for noInvestee, as it's not a direct property of the portfolio
              let countA = investeeCompaniesCount(a.id);
              let countB = investeeCompaniesCount(b.id);
              if (sortParameters[key] === 'lowtoHigh') {
                return countA - countB;
              } else {
                return countB - countA;
              }
            } else {
              // For other keys, we can access the properties of the portfolio directly
              if (sortParameters[key].startsWith('-')) {
                // Descending order
                return b[key] > a[key] ? 1 : -1;
              } else {
                // Ascending order
                return a[key] > b[key] ? 1 : -1;
              }
            }
          }
        }
        return 0; // If no sort parameters are set, don't change the order
      });

      setInvestorPortfolios(searchValue);
    }
  };

  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }

    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchInvestorPortfolios();
  }, [portfolios, sortParameters]); // Add sortParameters to the dependency array

  const investeeCompaniesCount = (id) => {
    let investeeCount = 0;
    if (investees) {
      for (const investee of investees) {
        if (investee.owner === id) {
          investeeCount++;
        }
      }
    }
    return investeeCount;
  };

  const displayPortfolios = (portfolios) => {
    return (
      <div className='table-style'>
        <Table className='table-link'>
          <thead>
            <tr>
              <th className='w-25'>
                {t('shared.portfolioName')}
                <SortingArrows
                  sortParameters={sortParameters}
                  name='name'
                  parametarAsc='name'
                  parametarDesc='-name'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th className='w-25'>{t('shared.status')}</th>
              <th className='w-25'>
                {t('shared.noInvestees')}
                <SortingArrows
                  sortParameters={sortParameters}
                  name='noInvestee'
                  parametarAsc='lowtoHigh'
                  parametarDesc='hightoLow'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th className='w-25'>
                {t('shared.investeeLimit')}
                <SortingArrows
                  sortParameters={sortParameters}
                  name='allowed_investee_limit'
                  parametarAsc='allowed_investee_limit'
                  parametarDesc='-allowed_investee_limit'
                  handleOnClick={changeSortParameters}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {portfolios &&
              portfolios.map((portfolio, index) => (
                <tr
                  key={index}
                  className='vertical-content main2 hover'
                  onClick={() =>
                    navigate('/superadmin/portfolios/portfolio-details', {
                      state: { portfolio: portfolio },
                    })
                  }
                >
                  <td>{portfolio.name}</td>
                  <td>
                    {portfolio.status === 'Active' && t('misc.active')}
                    {portfolio.status === 'Pending' && t('misc.pending')}
                    {portfolio.status === 'Closed' && t('misc.closed')}
                  </td>
                  <td>{investeeCompaniesCount(portfolio.id)}</td>
                  <td>{portfolio.allowed_investee_limit}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  };

  if (!portfolios || !investees) {
    loadPortfolios();
    loadInvestees();
    return <Spinner animation='grow' variant='info' className='loader' />;
  } else {
    return (
      <div className='row padding-left padding-top'>
        <div className='d-sm-flex align-items-center justify-content-between page-content'>
          <h3>{t('misc.portfolios')}</h3>
        </div>
        <div className='search-container'>
          <Form.Select className='mr-1' name='status' onChange={(e) => handleSeachAndFilters(e)} defaultValue=''>
            <option value=''>{t('shared.sortStatus')}</option>
            <option value='Active'>{t('misc.active')}</option>
            <option value='Pending'>{t('misc.pending')}</option>
            <option value=''>{t('shared.na')}</option>
          </Form.Select>
          <Form.Control
            name='search'
            type='text'
            placeholder={t('search.byNamePlaceholder')}
            value={sortParameters.search}
            onChange={(e) => handleSeachAndFilters(e)}
          />
        </div>

        <div>
          {displayPortfolios(currentItems)}
          <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
            {pageCount > 0 ? (
              <p className='main2'>
                {t('shared.pageInfo', {
                  currentPage: currentPage,
                  pageCount: pageCount,
                })}
              </p>
            ) : (
              ''
            )}
            <ReactPaginate
              breakLabel='...'
              nextLabel={t('shared.nextLabel')}
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={t('shared.previousLabel')}
              renderOnZeroPageCount={null}
              className='pagination'
              previousClassName='previous-pagination'
              nextClassName='next-pagination'
              activeClassName='active-pagination'
              disabledClassName='disabled-pagination'
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  investees: state.auth.investees,
  portfolios: state.auth.portfolios,
});

export default connect(mapStateToProps, { loadInvestees, loadPortfolios })(DisplayInvestorPortfolios);
