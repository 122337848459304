import {
  USER_LOADED,
  INVESTORS_LOADED,
  INVESTEES_LOADED,
  PORTFOLIOS_LOADED,
  PRODUCTS_LOADED,
  REPORT_STATUSES_LOADED,
  REPORTING_PERIODS_LOADED,
  FORMS_LOADED,
  MODULES_LOADED,
  SECTIONS_LOADED,
  QUESTIONS_LOADED,
  ANSWER_FIELDS_LOADED,
  INVESTOR_DATA_SUMMARY_LOADED,
  OXIA_ADMIN_DATA_SUMMARY_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAIL,
  SET_LANGUAGE,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
  investors: null,
  investees: null,
  portfolios: null,
  products: null,
  reportStatuses: null,
  reportingPeriods: null,
  forms: null,
  modules: null,
  sections: null,
  questions: null,
  answerFields: null,
  investorDataSummary: null,
  oxiaAdminDataSummary: null,
  userType: localStorage.getItem('userType'),
  language: 'en',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      localStorage.setItem('userType', payload.type);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
        language: payload.language || state.language,
      };

    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case INVESTORS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        investors: payload,
      };

    case INVESTEES_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        investees: payload,
      };

    case PORTFOLIOS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        portfolios: payload,
      };

    case PRODUCTS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        products: payload,
      };

    case REPORT_STATUSES_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        reportStatuses: payload,
      };

    case REPORTING_PERIODS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        reportingPeriods: payload,
      };

    case FORMS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        forms: payload,
      };

    case MODULES_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        modules: payload,
      };

    case SECTIONS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        sections: payload,
      };

    case QUESTIONS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        questions: payload,
      };

    case ANSWER_FIELDS_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        answerFields: payload,
      };

    case INVESTOR_DATA_SUMMARY_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        investorDataSummary: payload,
      };

    case OXIA_ADMIN_DATA_SUMMARY_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        oxiaAdminDataSummary: payload,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };

    case PASSWORD_CHANGE_SUCCESS:
    case PASSWORD_CHANGE_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('userType');
      localStorage.removeItem('investor');
      localStorage.removeItem('consultant');
      localStorage.removeItem('enterprise');
      localStorage.removeItem('investee');
      localStorage.removeItem('reportRequestId');
      localStorage.removeItem('questionsInfo');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };

    default:
      return state;
  }
}
