import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

class CreateQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { questions, onChange, onSubmit } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <button className='btn btn-warning text-white' onClick={() => this.setState({ showModal: true })}>
          <i className='fas fa-fw fa-plus'></i>
          <span>Create Question</span>
        </button>
        <Modal className='dashboard-modal' show={showModal} onHide={() => this.setState({ showModal: false })} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Title>Create Question</Modal.Title>
          <Modal.Body>
            <Form.Group>
              <Form.Label>module</Form.Label>
              <Form.Control name='module' type='text' value={questions.module} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>section</Form.Label>
              <Form.Control name='section' type='text' value={questions.section} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>tags</Form.Label>
              <Form.Control name='tags' type='text' value={questions.tags} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>human_id</Form.Label>
              <Form.Control name='human_id' type='text' value={questions.human_id} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>title_en</Form.Label>
              <Form.Control name='title_en' type='text' value={questions.title_en} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>stem_en</Form.Label>
              <Form.Control name='stem_en' type='text' value={questions.stem_en} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>explanation_text_en</Form.Label>
              <Form.Control
                name='explanation_text_en'
                type='text'
                value={questions.explanation_text_en}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>examples_text_en</Form.Label>
              <Form.Control
                name='examples_text_en'
                type='text'
                value={questions.examples_text_en}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group className='mb-1'>
              <Form.Label>multiple_answer_rows</Form.Label>
              <Form.Select
                name='multiple_answer_rows'
                value={questions.multiple_answer_rows}
                onChange={(e) => onChange(e)}
              >
                <option>Choose option</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Form.Select>
            </Form.Group>
            {questions.multiple_answer_rows === 'Yes' ? (
              <Form.Group>
                <Form.Label>max_answer_rows</Form.Label>
                <Form.Control
                  name='max_answer_rows'
                  type='number'
                  value={questions.max_answer_rows}
                  onChange={(e) => onChange(e)}
                />
              </Form.Group>
            ) : (
              ''
            )}
            <Form.Group className='mb-1'>
              <Form.Label>optional</Form.Label>
              <Form.Select name='optional' value={questions.optional} onChange={(e) => onChange(e)}>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>allocation1</Form.Label>
              <Form.Control
                name='allocation1'
                type='text'
                value={questions.allocation1}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>allocation2</Form.Label>
              <Form.Control
                name='allocation2'
                type='text'
                value={questions.allocation2}
                onChange={(e) => onChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>comments</Form.Label>
              <Form.Control name='comments' type='text' value={questions.comments} onChange={(e) => onChange(e)} />
            </Form.Group>
            <Form.Group className='modal-buttons'>
              <button onClick={() => this.setState({ showModal: false })} className='primary-button cancel-button'>
                Cancel
              </button>
              <button onClick={onSubmit} type='submit' className='primary-button content-right'>
                Create
              </button>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CreateQuestion;
