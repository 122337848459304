import React from 'react';

class DisplayQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { questionList } = this.props;

    return (
      <>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>module</th>
              <th>section</th>
              <th>tags</th>
              <th>human_id</th>
              <th>title_en</th>
              <th>stem_en</th>
              <th>explanation_text_en</th>
              <th>examples_text_en</th>
              <th>multiple_answer_rows</th>
              <th>max_answer_rows</th>
              <th>optional</th>
              <th>allocation1</th>
              <th>allocation2</th>
              <th>comments</th>
            </tr>
          </thead>
          <tbody>
            {
              <tr>
                <td>{questionList.module}</td>
                <td>{questionList.section}</td>
                <td>{questionList.tags}</td>
                <td>{questionList.human_id}</td>
                <td>{questionList.title_en}</td>
                <td>{questionList.stem_en}</td>
                <td>{questionList.explanation_text_en}</td>
                <td>{questionList.examples_text_en}</td>
                <td>{questionList.multiple_answer_rows}</td>
                <td>{questionList.max_answer_rows}</td>
                <td>{questionList.optional}</td>
                <td>{questionList.allocation1}</td>
                <td>{questionList.allocation2}</td>
                <td>{questionList.comments}</td>
              </tr>
            }
          </tbody>
        </table>
      </>
    );
  }
}

export default DisplayQuestions;
