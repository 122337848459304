import React, { useEffect, useState } from 'react';
import API from '../../../api';
import { Modal, Form } from 'react-bootstrap';
import { AuthenticatedLink } from '../../Shared/ReportAction/AuthenticatedLink';
import { useTranslation } from 'react-i18next';

function UploadFiles(props) {
  const { t } = useTranslation();
  let { queId } = props;
  const reportRequest = JSON.parse(localStorage.getItem('reportRequestId'));
  let [newVal, setNewVal] = useState({
    uploaded_file: null,
    description: '',
    report_request: reportRequest,
    question: queId,
  });
  let [showModal, setShowModal] = useState(false);
  let [submitted, setSubmitted] = useState(false);
  let [files, setFiles] = useState([]);
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [buttonDisable, setButtonDisable] = useState(false);

  const fetchUploadFile = async () => {
    await API.get(`api/upload_file/?report_request=${reportRequest}&question=${queId}`, { headers })
      .then((response) => {
        setFiles(response.data);
      })
      .catch(function () {
        console.log('Error while loading file upload');
      });
  };

  useEffect(() => {
    fetchUploadFile();
  }, [queId]);

  const displayModal = () => {
    setShowModal(true);
    setButtonDisable(false);
    setSubmitted(false);
    setNewVal({
      uploaded_file: null,
      description: '',
      report_request: reportRequest,
      question: queId,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleFileChange = (event) => {
    setNewVal({
      ...newVal,
      uploaded_file: event.target.files[0],
      description: event.target.files[0].name,
    });
  };

  const handleChange = (event) => {
    setNewVal({
      ...newVal,
      [event.target.name]: event.target.value,
    });
  };

  const submitFileUpload = () => {
    const url = 'api/upload_file/';
    const formData = new FormData();
    formData.append('uploaded_file', newVal.uploaded_file);
    formData.append('description', newVal.description);
    formData.append('report_request', newVal.report_request);
    formData.append('question', newVal.question);

    if (newVal.uploaded_file && newVal.description && newVal.report_request && newVal.question) {
      API.post(url, formData, { headers })
        .then(() => {
          setButtonDisable(true);
          fetchUploadFile();
          setShowModal(false);
        })
        .catch(function () {
          console.log('Error while updating');
        });
    } else {
      setSubmitted(true);
    }
  };

  const displayUploadedFiles = () => {
    const URL = `${process.env.REACT_APP_API_SERVER}/api/upload_file`;
    return files.length > 0 ? (
      <>
        <h5 className='mt-1'>{t('questionnaire.filesLabel')} </h5>
        <ul>
          {files.map((file, index) => (
            <li key={index} className='download-file'>
              <AuthenticatedLink url={URL + '/' + file.id + '/'} filename={file.description}>
                <button className='uploaded-file'>{file.description}</button>
              </AuthenticatedLink>
            </li>
          ))}
        </ul>
      </>
    ) : (
      <p className='mt-1 text-info'>{t('questionnaire.noFilesinfo')}</p>
    );
  };

  return (
    <div className='padding-bottom'>
      {displayUploadedFiles()}
      <button onClick={displayModal}>{t('questionnaire.uploadFileBtn')}</button>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title className='padding-left padding-right'>{t('questionnaire.uploadingFileModalTitle')}</Modal.Title>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {t('questionnaire.fileUploadLabel')}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control type='file' onChange={(e) => handleFileChange(e)} className='style-bottom' />
            {submitted && !newVal.uploaded_file ? (
              <p className='text-danger error-message'>{t('questionnaire.fileUploadRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              {t('questionnaire.fileNameLabel')}
              <span className='text-danger'>*</span>
            </Form.Label>
            <Form.Control
              name='description'
              as='input'
              rows={3}
              value={newVal.description}
              onChange={(e) => handleChange(e)}
              className='style-bottom'
              disabled='true'
            />
            {submitted && !newVal.description ? (
              <p className='text-danger error-message'>{t('questionnaire.fileNameRequired')}</p>
            ) : (
              <p className='error-message'>&nbsp;</p>
            )}
            {t('questionnaire.fileExtensionNote')}
          </Form.Group>
          <Form.Group className='modal-buttons'>
            <button onClick={closeModal} className='primary-button cancel-button'>
              {t('misc.cancel')}
            </button>
            <button
              onClick={() => submitFileUpload()}
              className='primary-button content-right'
              disabled={buttonDisable}
            >
              {t('questionnaire.Submit')}
            </button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UploadFiles;
