import React, { useEffect, useState } from 'react';
import API from '../../../api';
import { ListGroup } from 'react-bootstrap';
import TimeAgo from '../../Shared/TimeAgo';

function Notification() {
  const headers = { Authorization: 'Token ' + localStorage.token };
  let [notify, setNotify] = useState([]);

  const fetchNotifications = async () => {
    const response = await API.get('api/notifications/', { headers });
    const sortedNotifications = response.data.sort(
      (prev, next) => Date.parse(next.sent_time) - Date.parse(prev.sent_time),
    );
    setNotify(sortedNotifications);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className='col-md-5 padding-left'>
      <h3 className='mb-1 mt-1pt375'>Notifications</h3>
      <ListGroup className='list-wrapper'>
        {notify &&
          notify.map((value, index) => (
            <ListGroup.Item key={index}>
              <div className='d-sm-flex align-items-center justify-content-between'>
                <div className='fw-bold'>{value.subject}</div>
                <TimeAgo dateTime={value.sent_time} />
              </div>
              <div className='mt-point5'>{value.content}</div>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
}

export default Notification;
