import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import API from '../../../api';
import InviteMember from '../CreateAction/InviteMember';
import SortingArrows from '../SortingArrows';
import { removePathEmptyParams } from '../../../services/helpers';
import defaultImage from '../../../images/default-image.png';

function DisplayInvestees(props) {
  const { investee, investor, portfolio, url, idInvestee } = props;
  const headers = { Authorization: 'Token ' + localStorage.token };
  const navigate = useNavigate();
  const { t } = useTranslation();
  let itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetails, setUserDetails] = useState([]);

  const [sortParameters, setsortParameters] = useState({
    name: '',
    status: '',
    search: '',
    idInvestee: idInvestee ? idInvestee : '',
  });

  useEffect(() => {
    fetchUserDetails(idInvestee);
  }, []);

  useEffect(() => {
    if (userDetails) {
      helperFunctionForSetCurrentItems(userDetails);
    }
  }, [userDetails, itemOffset, itemsPerPage]);

  useEffect(() => {
    const queryParams = new URLSearchParams(sortParameters).toString();
    API.get(`api/users/investee_investeeMembers?${removePathEmptyParams(queryParams)}`, {
      headers,
      sortParameters,
    }).then((response) => {
      setUserDetails(response.data);
    });
  }, [sortParameters]);

  const helperFunctionForSetCurrentItems = (data) => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(userDetails.length / itemsPerPage));
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userDetails.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const fetchUserDetails = async (id, isAdd) => {
    const response = await API.get(`api/users/?idInvestee=${id}`, { headers });
    if (!isAdd) {
      setUserDetails(response.data);
    } else {
      helperFunctionForSetCurrentItems(response.data);
    }
  };

  const changeSortParameters = (name, paramAsc, paramDesc) => {
    let value = '';
    if (sortParameters[name] === '') {
      value = paramAsc;
    } else if (sortParameters[name] === paramAsc) {
      value = paramDesc;
    }

    setsortParameters({
      ...sortParameters,
      [name]: value,
    });
  };

  const handleSeachAndFilters = (event) => {
    setsortParameters({
      ...sortParameters,
      [event.target.name]: event.target.value,
    });
  };

  const displayInvesteeUsers = (users) => {
    return (
      <>
        <table className='table table-link'>
          <thead>
            <tr>
              <th>
                {t('misc.name')}
                <SortingArrows
                  sortParameters={sortParameters}
                  name='name'
                  parametarAsc='name'
                  parametarDesc='-name'
                  handleOnClick={changeSortParameters}
                />
              </th>
              <th>{t('misc.email')}</th>
              <th>{t('investorDashboard.role')}</th>
              <th>{t('shared.status')}</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    navigate(url, {
                      state: {
                        user: user,
                        investor: investor,
                        investee: investee,
                        portfolio: portfolio,
                      },
                    })
                  }
                >
                  <td>
                    <span className='nav-profile'>
                      <img src={defaultImage} className='user-img' />
                      <div className='name-block table-name-margin'>
                        <span>{user.first_name.charAt(0).toUpperCase()}</span>
                      </div>
                    </span>
                    <span className='main2'>
                      {user.first_name} {user.last_name}
                    </span>
                  </td>
                  <td className='primary vertical-content'>{user.email}</td>
                  <td className='vertical-content'>{user.role ? user.role : '-'}</td>
                  <td>
                    {user.status === 'Active' ? (
                      <button className='active-button'>{t('misc.active')}</button>
                    ) : (
                      <button className='pending-button'>{t('misc.pending')}</button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {sortParameters.search !== '' && currentItems?.length === 0 && (
          <div className='alert alert-warning'>{t('errors.noEnterprise')}</div>
        )}
      </>
    );
  };

  return (
    <div className='row padding-left padding-top'>
      <div className='d-sm-flex align-items-center justify-content-between page-content'>
        <h3>{t('shared.enterpriseMembers')}</h3>
        <div className='search-container'>
          <Form.Select className='mr-1' name='status' onChange={(e) => handleSeachAndFilters(e)} defaultValue=''>
            <option value=''>{t('shared.sortStatus')}</option>
            <option value='Active'>{t('misc.active')}</option>
            <option value='Pending'>{t('misc.pending')}</option>
            <option value=''>{t('shared.na')}</option>
          </Form.Select>
          <Form.Control
            name='search'
            type='text'
            placeholder={t('search.byNamePlaceholder')}
            value={sortParameters.search}
            onChange={(e) => {
              handleSeachAndFilters(e);
            }}
          />
          <span className='pl-1'>
            <InviteMember id={idInvestee} fetchUserDetails={fetchUserDetails} />
          </span>
        </div>
      </div>
      <div>
        {displayInvesteeUsers(currentItems)}
        <div className='d-sm-flex align-items-center justify-content-between padding-top padding-bottom'>
          {pageCount > 0 ? (
            <p className='main2'>
              {t('shared.pageInfo', {
                currentPage: currentPage,
                pageCount: pageCount,
              })}
            </p>
          ) : (
            ''
          )}
          <ReactPaginate
            breakLabel='...'
            nextLabel={t('shared.nextLabel')}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel={t('shared.previousLabel')}
            renderOnZeroPageCount={null}
            className='pagination'
            previousClassName='previous-pagination'
            nextClassName='next-pagination'
            activeClassName='active-pagination'
            disabledClassName='disabled-pagination'
          />
        </div>
      </div>
    </div>
  );
}

export default DisplayInvestees;
