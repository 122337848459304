import React from 'react';

class DisplayAnswers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { answerList } = this.props;

    return (
      <>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>question_human_id</th>
              <th>answerfield_human_id</th>
              <th>label_is_header</th>
              <th>data_type</th>
              <th>label_en</th>
              <th>text_information</th>
              <th>boolean_information</th>
              <th>ddl_information</th>
              <th>reference_question_id</th>
              <th>help_text_en</th>
              <th>min_value</th>
              <th>max_value</th>
              <th>hide_question_if_no</th>
              <th>hide_tag_if_no</th>
              <th>hide_section_if_no</th>
              <th>comments</th>
            </tr>
          </thead>
          <tbody>
            {answerList.answerField &&
              answerList.answerField.map((item, index) => (
                <tr key={index}>
                  <td>{answerList.queHumanId}</td>
                  <td>{item.ansFieldHumanID}</td>
                  <td>{answerList.labelIsHeader}</td>
                  <td>{item.dataType}</td>
                  <td>{item.labelEn}</td>
                  <td>{item.textInfo}</td>
                  <td>{item.booleanInfo}</td>
                  <td>{item.ddlInfo}</td>
                  <td>{item.refQueId}</td>
                  <td>{item.helpTextEn}</td>
                  <td>{item.minValue}</td>
                  <td>{item.maxValue}</td>
                  <td>{item.hideQuestionIfNo}</td>
                  <td>{item.hideTagIfNo}</td>
                  <td>{item.hideSectionIfNo}</td>
                  <td>{item.comments}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default DisplayAnswers;
