import React, { useEffect, useState } from 'react';

function ButtonScrollUp() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    document.querySelector('.main-panel').addEventListener('scroll', toggleVisible);
  }, []);

  const toggleVisible = () => {
    const scrolled = document.querySelector('.main-panel').scrollTop;
    if (scrolled > 3) {
      setVisible(true);
    } else if (scrolled <= 3) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    document.querySelector('.main-panel').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className='primary-button scroll-up-button'
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
    >
      <i className='fas fa-arrow-up'></i>
    </button>
  );
}

export default ButtonScrollUp;
